import PropsType from 'prop-types'
import { useState } from 'react'
import { Col, Label, Row, UncontrolledTooltip } from 'reactstrap'
import React from 'react'

//flatpickr
import { CommonVN } from 'constants/vn'

import { DatePicker } from 'antd'
import { FormInput } from 'components/Common/form-input/FormInput'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { MAX_LENGTH_SEARCH, TypeProgramOptions } from 'constants/constants'
import { FormDateRange } from 'components/Common/form-date-range/FormDateRange'

const { RangePicker } = DatePicker

RewardVerifyFilter.propTypes = {
  handleFilter: PropsType.func
}

function RewardVerifyFilter({ handleFilter }) {
  const [filter, setFilter] = useState({
    search: '',
    programType: '',
    timeApplied: []
  })

  const onFilterChange = (name, value) =>
    setFilter((prev) => ({
      ...prev,
      [name]: value
    }))

  const onResetFilter = () => setFilter({ search: '', programType: '', timeApplied: [] })

  return (
    <Row align='bottom'>
      <Col xxl={2} className='mt-3'>
        <Label className='form-label'>Nhập từ khóa tìm kiếm</Label>
        <FormInput
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập tên chương trình'
          value={filter?.search || ''}
          name='search'
          id={`input-filter`}
          onChange={(e) => onFilterChange('search', e.target.value)}
          onBlur={(e) => onFilterChange('search', e.target.value)}
        />
        <UncontrolledTooltip trigger='hover' placement='top' target={`input-filter`}>
          {''}
        </UncontrolledTooltip>
      </Col>
      <Col xxl={2} className='mt-3' lg={2}>
        <Label className='form-label'>Loại chương trình</Label>
        <FormSelect
          options={TypeProgramOptions}
          value={filter?.programType || ''}
          name='programType'
          onChange={(value) => onFilterChange('programType', value)}
          placeholder='Chọn loại chương trình'
        />
      </Col>
      <Col xxl={2} className='mt-3' lg={3}>
        <Label className='form-label'>Thời gian áp dụng</Label>
        <FormDateRange
          inputReadOnly
          className='w-100'
          size='large'
          name='timeApplied'
          format={CommonVN.datePlaceholder.toUpperCase()}
          placeholder={[CommonVN.datePlaceholder, CommonVN.datePlaceholder]}
          value={filter?.timeApplied || []}
          onChange={(value) => onFilterChange('timeApplied', value)}
        />
      </Col>

      <Col span={6}>
        <div className='d-flex align-items-end justify-content-end mt-3 '>
          <div className='mb-3 mb-xxl-0 me-2'>
            <button type='button' className='btn btn-soft-danger w-100' onClick={onResetFilter}>
              Xóa tìm kiếm
            </button>
          </div>
          <div className='mb-3 mb-xxl-0'>
            <button type='button' className='btn btn-primary w-100' onClick={() => handleFilter(filter)}>
              Tìm kiếm
            </button>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default RewardVerifyFilter
