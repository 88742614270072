import React, { useEffect, useMemo, useState } from 'react'
import { Col, FormGroup, Input, Label, Row } from 'reactstrap'

//flatpickr
import 'flatpickr/dist/themes/material_blue.css'
import { AccountVN, CommonVN, ProductVN } from 'constants/vn'

import {
  getPortfolioAllAPI,
  exportAccountActivityAPI
} from 'helpers/backend_helper'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { getStorage } from 'helpers/global_helper'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { Space } from 'antd'
import { optionsAccountLevel } from '../constants'
import { FormDateRange } from 'components/Common/form-date-range/FormDateRange'
import { FormInput } from 'components/Common/form-input/FormInput'
import { toast } from 'react-toastify'
import SpinnerVersion2 from 'components/Common/SpinnerVersion2'

const AccountLocalityFilter = ({ setGlobalFilter, search, setSearch, data }) => {
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    zone: app.zone,
    subzone: app.subzone,
    province: app.province,
    district: app.district,
    ward: app.ward,
    myLocationManagement: app.myLocationManagement
  }))
  const { zone, subzone, province, district, ward, myLocationManagement } = useSelector(LayoutProperties)

  const selectProfileState = (state) => state.Profile
  const ProfileProperties = createSelector(selectProfileState, (profile) => ({
    profile: profile.profile
  }))

  const { profile } = useSelector(ProfileProperties)

  const [filter, setFilter] = useState({})
  const [exportName, setExportName] = useState({})
  const lstDistrict = myLocationManagement
  const [isLoading, setLoading] = useState(false)

  const handleSelect = (value, key) => {
    setFilter((e) => ({ ...e, [key]: value }))
  }

  const formatExportFileNameDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const year = String(d.getFullYear()).slice(-2);
    return `${day}.${month}.${year}`;
  };

  const getExportName = (value) => {
    const { start_date_one, end_date_one, start_date_two, end_date_two } = value;
    let export_name = 'Hoạt động tài khoản ';
    if (value?.client === 'user') {
      export_name += `cửa hàng`;
    } else if (value?.client === 'technician') {
      export_name += `kỹ thuật viên`;
    } else {
      export_name += `cửa hàng và kỹ thuật viên`;
    }
    export_name =
      export_name +
      `_A${formatExportFileNameDate(start_date_one)}-${formatExportFileNameDate(end_date_one)}` +
      `_B${formatExportFileNameDate(start_date_two)}-${formatExportFileNameDate(end_date_two)}`;
    setExportName(export_name);
  }

  useEffect(() => {
    const filterManageLocality = getStorage('filterManageLocality')
    setFilter(filterManageLocality)
  }, [])

  const handleClickExport = async () => {
    console.log('check..')
    setLoading(true)
    try {
      const response = await exportAccountActivityAPI({ ...filter })
      const data = response.headers.get('Content-Type')
      if (data !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        toast('Hệ thống đang xử lý. Tệp của bạn đã được gửi tới email ' + profile?.email + ' trong vài phút', {
          type: 'success'
        })
      } else {
        try {
          const blob = await response.blob() // Fetch the blob
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `${exportName}.xlsx`
          ) // Tên file
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          toast('Export file thành công!', { type: 'success' })
        } catch (err) {
          console.log('err', err)
        }
      }
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  ////Lấy danh sách ward
  // Get all unique parentId values from lstDistrict
  const wardParentIds = myLocationManagement?.map((district) => district.id)

  //Từ wardParentIds, lấy ra những ward có id trong wardParentIds
  const lstWard = ward.filter((ward) => wardParentIds.includes(ward.parentId))

  ////Lấy danh sách province
  // Get all unique parentId values from lstDistrict
  const districtParentIds = lstDistrict?.map((district) => district.parentId)
  //Từ districtParentIds, lấy ra những district có id trong districtParentIds
  const lstProvionce = province.filter((province) => districtParentIds.includes(province.id))

  ////Lấy danh sách subZone
  // Get all unique subZoneId(parentId) values from lstProvionce
  const provinceParentIds = lstProvionce?.map((province) => province.subZoneId)
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstSubZone = subzone?.filter((subZone) => provinceParentIds.includes(subZone.id))

  ////Lấy danh sách zone
  // Get all unique zoneId(parentId) values from lstProvionce
  const subZoneParentIds = lstProvionce?.map((province) => province.zoneId) // trong lstProvionce có lưu zoneId
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstZone = zone.filter((zone) => subZoneParentIds.includes(zone.id))
  const [industrySectorOptions, setIndustrySectorOptions] = useState([])
  const [industrySector, setIndustrySector] = useState([])
  const subZoneMemo = useMemo(() => {
    const parentDatas = filter?.zone_ids?.length > 0 ? filter.zone_ids : lstZone.map((e) => e.id)
    return lstSubZone?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.zoneId) : true))?.length > 0
      ? [{ label: 'Tất cả', value: 'all' }].concat(
        lstSubZone
          ?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.zoneId) : true))
          ?.map((item) => ({
            value: item.id,
            label: item.name
          }))
      )
      : []
  }, [lstSubZone, lstZone, filter])

  const provinceMemo = useMemo(() => {
    const parentDatas = filter?.sub_zone_ids?.length > 0 ? filter.sub_zone_ids : subZoneMemo.map((e) => e.value)

    return lstProvionce?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.subZoneId) : true))?.length >
      0
      ? [{ label: 'Tất cả', value: 'all' }].concat(
        lstProvionce
          ?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.subZoneId) : true))
          ?.map((item) => ({
            value: item.id,
            label: item.name
          }))
      )
      : []
  }, [lstProvionce, filter])

  const districtMemo = useMemo(() => {
    const parentDatas = filter?.province_ids?.length > 0 ? filter.province_ids : provinceMemo.map((e) => e.value)
    return lstDistrict?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.parentId) : true))?.length > 0
      ? [{ label: 'Tất cả', value: 'all' }].concat(
        lstDistrict
          ?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.parentId) : true))
          ?.map((item) => ({
            value: item.id,
            label: item.name
          }))
      )
      : []
  }, [lstDistrict, filter])

  const getIndustryDataOptions = async () => {
    try {
      const resIndustrySector = await getPortfolioAllAPI({ type: 'industrySector' })

      setIndustrySector(resIndustrySector?.data)
      if (resIndustrySector?.data?.length > 0) {
        setIndustrySectorOptions(
          [{ label: 'Tất cả', value: 'all' }].concat(
            resIndustrySector?.data?.map((item) => ({
              value: item.id,
              label: item.name
            }))
          )
        )
      } else {
        setIndustrySectorOptions([])
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const filterManageLocality = getStorage('filterManageLocality')
    setFilter(filterManageLocality)
    getIndustryDataOptions()
  }, [])
  return (
    <>
      <Row>
        <Col xxl={2} lg={2} className='mt-3'>
          <Label className='form-label'>Nhập tên của hàng</Label>
          <FormInput
            className='w-100'
            placeholder='Nhập tên của hàng '
            value={filter?.search || ''}
            name='search'
            id={`input-filter`}
            onChange={(e) => handleSelect(e.target.value, 'search')}
            onBlur={(e) => handleSelect(e.target.value, 'search')}
          />
        </Col>
        <Col xxl={2} lg={2} className='mt-3'>
          <Label className='form-label'>Nhập số điện thoại</Label>
          <FormInput
            className='w-100'
            placeholder='Nhập số điện thoại '
            value={filter?.phone || ''}
            name='phone'
            id={`input-filter`}
            onChange={(e) => handleSelect(e.target.value, 'phone')}
            onBlur={(e) => handleSelect(e.target.value, 'phone')}
          />
        </Col>

        <Col xxl={2} lg={2} className='mt-3'>
          <Label className='form-label'>{AccountVN.filters.accountLevel.title}</Label>
          <FormSelect
            options={optionsAccountLevel}
            value={filter?.client}
            onChange={(value) => {
              handleSelect(value, 'client')
              handleSelect(undefined, 'zone_ids')
              handleSelect(undefined, 'sub_zone_ids')
            }}
            placeholder={AccountVN.filters.accountLevel.placeholder}
          />
        </Col>

        <Col xxl={2} lg={2} className='mt-3'>
          <Label className='form-label'>Miền </Label>
          <FormSelect
            options={[{ label: 'Tất cả', value: 'all' }].concat(
              lstZone?.map((item) => ({
                value: item.id,
                label: item.name
              }))
            )}
            value={filter?.zone_ids}
            onChange={(value) => {
              handleSelect(value, 'zone_ids')
              handleSelect([], 'sub_zone_ids')
              handleSelect([], 'province_ids')
              handleSelect([], 'district_ids')
              handleSelect([], 'ward_ids')
            }}
            onSelect={(value) => {
              const all = lstZone?.map((item) => item.id)
              if (value && value === 'all') {
                return handleSelect([...all], 'zone_ids')
              }
            }}
            mode='multiple'
            placeholder={ProductVN.filter?.zone.placeholder}
          />
        </Col>

        <Col xxl={2} lg={2} className='mt-3'>
          <Label className='form-label'>{'Vùng'}</Label>
          <FormSelect
            options={subZoneMemo}
            value={filter?.sub_zone_ids}
            onChange={(value) => {
              handleSelect(value, 'sub_zone_ids')
              handleSelect(undefined, 'province_ids')
              handleSelect(undefined, 'location_ids')
              handleSelect(undefined, 'ward_ids')
            }}
            onSelect={(value) => {
              const all = subZoneMemo.filter((item) => item.value !== 'all').map((item) => item.value)
              if (value && value === 'all') {
                return handleSelect([...all], 'sub_zone_ids')
              }
            }}
            placeholder={'Chọn vùng'}
            mode='multiple'
          />
        </Col>

        <Col xxl={2} lg={2} className='mt-3'>
          <Label className='form-label'>Tỉnh/ Thành phố</Label>
          <FormSelect
            options={provinceMemo}
            value={filter?.province_ids}
            onChange={(value, label) => {
              handleSelect(value, 'province_ids')
              handleSelect(label.map((e) => e.label, 'province_names'))
              handleSelect([], 'district_ids')
              handleSelect([], 'ward_ids')
              handleSelect([], 'district_names')
              handleSelect([], 'ward_names')
            }}
            onSelect={(value) => {
              const all = provinceMemo.map((item) => Number(item.value)).filter((num) => !isNaN(num))
              const all_names = lstProvionce
                ?.filter((e) => filter?.sub_zone_ids?.includes(e.subZoneId))
                ?.map((item) => item.name)

              if (value && value === 'all') {
                handleSelect([...all], 'province_ids')
                handleSelect([...all_names], 'province_names')
              }
            }}
            placeholder='Tất cả'
            mode='multiple'
          />
          <SpinnerVersion2 loading={isLoading} />
        </Col>
      </Row>
      <Row className='mt-5'>
        <Col xxl={2.5} lg={2} className='mt-3'>
          <Label className='form-label'>Ngành hàng</Label>
          <FormSelect
            options={industrySectorOptions}
            value={filter?.industry_sector_ids}
            onChange={(value, label) => {
              handleSelect(value, 'industry_sector_ids')
            }}
            onSelect={(value) => {
              if (value && value === 'all') {
                console.log(industrySector)
                handleSelect(
                  industrySector.map((e) => e.id),
                  'industry_sector_ids'
                )
              }
            }}
            // value={filter?.industry_ids}
            placeholder='Chọn Ngành'
            mode='multiple'
          />
        </Col>
        <Col xl={3} className='mt-3' lg={1}>
          <Label className='form-label'>{'Thời gian A'}</Label>
          <FormDateRange
            onKeyDown={(e) => e.preventDefault()}
            className='w-100'
            size='large'
            format={CommonVN.datePlaceholder.toUpperCase()}
            placeholder={[CommonVN.datePlaceholder, CommonVN.datePlaceholder]}
            value={[filter?.start_date_one, filter?.end_date_one]}
            onChange={(value) => {
              if (value) {
                handleSelect(value[0], 'start_date_one')
                handleSelect(value[1], 'end_date_one')
              } else {
                handleSelect(undefined, 'start_date_one')
                handleSelect(undefined, 'end_date_one')
              }
            }}
          />
        </Col>
        <Col xl={3} className='mt-3' lg={1}>
          <Label className='form-label'>{'Thời gian B'}</Label>
          <FormDateRange
            onKeyDown={(e) => e.preventDefault()}
            className='w-100'
            size='large'
            format={CommonVN.datePlaceholder.toUpperCase()}
            placeholder={[CommonVN.datePlaceholder, CommonVN.datePlaceholder]}
            value={[filter?.start_date_two, filter?.end_date_two]}
            onChange={(value) => {
              if (value) {
                handleSelect(value[0], 'start_date_two')
                handleSelect(value[1], 'end_date_two')
              } else {
                handleSelect(undefined, 'start_date_two')
                handleSelect(undefined, 'end_date_two')
              }
            }}
          />
        </Col>
        <Col className='mt-5'>
          <Space>
            <button
              type='button'
              className='btn btn-primary '
              style={{ width: '100px' }}
              disabled={
                !(
                  filter &&
                  filter.start_date_one &&
                  filter.end_date_one &&
                  filter.start_date_two &&
                  filter.end_date_two
                )
              }
              onClick={() => { setGlobalFilter(filter), getExportName(filter) }}
            >
              Tìm kiếm
            </button>
            <button
              type='button'
              className='btn btn-soft-danger'
              onClick={() => {
                setFilter({})
                setGlobalFilter({})
              }}
            >
              Xóa tìm kiếm
            </button>
            <button
              type='button'
              className='btn btn-primary'
              style={{ width: '100px' }}
              onClick={handleClickExport}
              disabled={data.length === 0}
            >
              Export
            </button>
          </Space>
        </Col>
      </Row>
    </>
  )
}
export default AccountLocalityFilter
