import { formateDate } from 'helpers/global_helper'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Badge, Card, CardBody } from 'reactstrap'
import { createSelector } from 'reselect'
import {
  getAccountRankListAPI,
  getCustomerCareProductListAPI} from 'helpers/backend_helper'
import Spinners from 'components/Common/Spinner'
import TableContainer from 'components/Common/TableContainer'
import { TableSimple } from 'components/Common/TableCustom'
import { StatusActivation } from 'pages/ManageActivation/ManageActivationCol'
import { ActivatedDate, CustomerCareProductListStatus, RelatedSerials, TruncateCell } from 'pages/ManageAccumulation/ManageAccumulationCol'

const ProductTab = ({
  form,
  type}) => {
  // const type = "create"

  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    province: app.province,
    district: app.district,
    ward: app.ward,
    warehouseZone: app.zone,
    subzone: app.subzone
  }))
  const { ward } = useSelector(LayoutProperties)

  const [listTable, setListTable] = useState([])
  const [industrySectorSelected, setIndustrysectorSelected] = useState([])
  const [applyClientTypeSelected, setApplyClientTypeSelected] = useState([])
  const [applyWardSelected, setApplyWardSelected] = useState([])
  const [accountRank, setAccountRank] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [filter, setFilter] = useState()
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState()
  const [pagination, setPagination] = useState({
    pageIndex: page,
    pageSize: 10,
    totalRows: total
  })

  useEffect(() => {
    form.setFieldValue('category', undefined)
    form.setFieldValue('model', undefined)
    getDataOptions()
  }, [industrySectorSelected])

  useEffect(() => {
    form.setFieldValue('rank', undefined)
    getDataOptions()
  }, [applyClientTypeSelected])

  useEffect(() => {
    getDataOptions()
  }, [applyWardSelected])

  useEffect(() => {
    handleGetListTable()
  }, [filter])

  const getDataOptions = async () => {
    try {
      const ward_selected = applyWardSelected.map((id) => ward.find((ward) => ward.id === id))
      const applyDistrictSelected = ward_selected?.map((e) => e.parentId)

      const resAccountRank = await getAccountRankListAPI({
        industry_sector_ids: industrySectorSelected,
        clients: applyClientTypeSelected,
        district_ids: Array.from(new Set(applyDistrictSelected))
      })
      setAccountRank(resAccountRank?.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetListTable = async (data) => {
    try {
      const res = await getCustomerCareProductListAPI({ ...data, serial: data?.search, code: data?.search })
      if (res.data) {
        setListTable(res.data)
      }

      if (res.meta) {
        setTotal(res.meta.totalItems)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const goToPage = (page) => {
    setFilter({ ...filter, page })
    setPage(page)
  }

  const setGlobalFilterUser = async (data) => {
    handleGetListTable(data)
    setPage(1)
  }

  const columns = useMemo(
    () => [
      // {
      //   Header: 'STT',
      //   accessor: 'stt',
      //   filterable: true,
      //   disableSortBy: true,
      //   hideSort: true,
      //   Cell: (cellProps) => {
      //     return (page - 1) * 10 + cellProps.row.index + 1
      //   }
      // },
      {
        Header: 'Serial sản phẩm',
        accessor: 'serial',
        filterable: true,
        disableSortBy: true,
        hideSort: true
        // Cell: (cellProps) => {
        //   return <Name {...cellProps} />
        // }
      },
      {
        Header: 'Ngành',
        accessor: 'industry.name',
        filterable: true,
        disableSortBy: true,
        hideSort: true
        // Cell: (cellProps) => {
        //   return <Abbreviation {...cellProps} />
        // }
      },
      {
        Header: 'Ngành hàng',
        accessor: 'industrySector.name',
        filterable: true,
        disableSortBy: true,
        hideSort: true
        // Cell: (cellProps) => {
        //   return <Abbreviation {...cellProps} />
        // }
      },
      {
        Header: 'Nhóm sản phẩm',
        accessor: 'category.name',
        filterable: true,
        disableSortBy: true,
        hideSort: true
        // Cell: (cellProps) => {
        //   return <Abbreviation {...cellProps} />
        // }
      },
      {
        Header: 'Model',
        accessor: 'model.name',
        filterable: true,
        disableSortBy: true,
        hideSort: true
        // Cell: (cellProps) => {
        //   return <Abbreviation {...cellProps} />
        // }
      },
      {
        Header: 'Miền',
        accessor: 'zone.name',
        filterable: true,
        disableSortBy: true,
        hideSort: true
        // Cell: (cellProps) => {
        //   return <Abbreviation {...cellProps} />
        // }
      },
      {
        Header: 'Kênh kinh doanh',
        accessor: 'channel.name',
        filterable: true,
        disableSortBy: true,
        hideSort: true
        // Cell: (cellProps) => {
        //   return <Abbreviation {...cellProps} />
        // }
      },
      {
        Header: 'Ngày xuất kho',
        // accessor: 'abbreviation',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return cellProps.row.original ? formateDate(cellProps.row.original?.exportDate) : '--'
        }
      },
      {
        Header: 'Mã bảo hành',
        accessor: 'userRewards[0].productActivationCode',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          const activation_code = cellProps?.row?.original?.userRewards?.[0]?.productActivationCode;
          return activation_code ? activation_code : '--'
        }
      },
      {
        Header: 'Serial liên quan',
        accessor: 'userRewards[0].serials',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <RelatedSerials {...cellProps}/>
        }
      },
      {
        Header: 'Trạng thái',
        // accessor: 'abbreviation',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <CustomerCareProductListStatus {...cellProps} />
        }
      }
    ],
    [filter, page]
  )
    
  const Shop = (cell) => {
    return (
      <div>
        <p className='m-0'>{cell.value ? cell.value : cell.row.original?.uName}</p>
        <span>{cell.row.original?.user.phone}</span>
        <p>
          <span>
            {cell.row.original?.user.province.name} - {cell.row.original?.user.district.name} - {cell.row.original?.user.ward.name}
          </span>
          <br />
          <span>{cell.row.original?.user.address}</span>
        </p>
      </div>
    )
  }

    
  // Hàm chuyển đổi từ số thập phân sang độ, phút, giây (DMS)
  const convertToDMS = (decimal, isLat) => {
    const absolute = Math.abs(decimal)
    const degrees = Math.floor(absolute)
    const minutesNotTruncated = (absolute - degrees) * 60
    const minutes = Math.floor(minutesNotTruncated)
    const seconds = ((minutesNotTruncated - minutes) * 60).toFixed(1)

    const direction = decimal >= 0 ? (isLat ? 'N' : 'E') : isLat ? 'S' : 'W'

    return `${degrees}°${minutes}'${seconds}"${direction} `
  }

  // Hàm để chuyển latitude và longitude thành chuỗi tọa độ
  const LongLatToDMS = (cell) => {
    const lat = parseFloat(cell.row.original?.latitude ? cell.row.original?.latitude : 0).toFixed(6) // Làm tròn thành 6 chữ số sau dấu phẩy
    const long = parseFloat(cell.row.original?.longitude ? cell.row.original?.longitude : 0).toFixed(6) // Làm tròn thành 6 chữ số sau dấu phẩy
    // Nếu tọa độ là 0, không hiển thị gì
    if (lat === '0.000000' && long === '0.000000') {
      return null
    } else {
      const dmsLatitude = convertToDMS(lat, true) // true => là latitude
      const dmsLongitude = convertToDMS(long, false) // false => là longitude

      return (
        <>
          <a href={`https://www.google.com/maps?q=${lat},${long}`} target='_blank' rel='noopener noreferrer'>
            {dmsLatitude} {dmsLongitude}
          </a>
        </>
      )
    }
  }

  const Customer = (cell) => {
    return (
      <div>
        <p className='m-0'>{cell.value ? cell.value : cell.row.original?.uName}</p>
        <span>{cell.row.original?.customer.phone}</span>
        <p>
          <span>
            {cell.row.original?.customer.ward.parent.parent.name} - {cell.row.original?.customer.ward.parent.name} - {cell.row.original?.customer.ward.name}
          </span>
          <br />
          <span>{cell.row.original?.customer.address}</span>
        </p>
      </div>
    )
  }

  const columnUserReward = useMemo(
    () => [
      {
        Header: 'Cửa hàng',
        accessor: 'user.shopName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => <Shop {...cellProps} />,
        // Cell: (cellProps) => {
        //   return <Name {...cellProps} />
        // }
      },
      {
        Header: 'Ngày tích lũy',
        accessor: 'createdAt',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ActivatedDate  {...cellProps} />
        }
      },
      {
        Header: 'Vị trí',
        accessor: 'longitude',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <LongLatToDMS {...cellProps} />
        }
      },
      {
        Header: 'Trạng thái',
        accessor: 'status',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <StatusActivation {...cellProps} />
        }
      }
    ],
    [filter, page]
  )
  const columnActivation = useMemo(
    () => [
      {
        Header: 'Cửa hàng',
        accessor: 'user.shopName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => <Shop {...cellProps} />,
        // Cell: (cellProps) => {
        //   return <Name {...cellProps} />
        // }
      },
      {
        Header: 'khách hàng',
        accessor: 'customer.name',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => <Customer {...cellProps} />,
        // Cell: (cellProps) => {
        //   return <Name {...cellProps} />
        // }
      },

      {
        Header: 'Ngày kích hoạt',
        accessor: 'createdAt',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ActivatedDate  {...cellProps} />
        }
      },
      {
        Header: 'Vị trí',
        accessor: 'longitude',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <LongLatToDMS {...cellProps} />
        }
        // Cell: (cellProps) => {
        //   return <Abbreviation {...cellProps} />
        // }
      },
      {
        Header: 'Trạng thái',
        accessor: 'status',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <StatusActivation {...cellProps} />
        }
      }
    ],
    [filter, page]
  )

  return (
    <React.Fragment>
      {isLoading ? (
        <Spinners setLoading={setLoading} />
      ) : (
        <Card>
          <CardBody>
            <TableContainer
              columns={columns}
              data={listTable}
              isShowTotal={false}
              isGlobalFilter={true}
              setGlobalFilterUser={setGlobalFilterUser}
              isNoneGlobalFilter={true}
              isPagination={false}
              iscustomPageSizeOptions={false}
              isShowingPageLength={false}
              customPageSize={10}
              placeholderSearch={'Nhập từ khoá Serial'}
              tableClass='table-bordered align-middle nowrap mt-2'
              paginationDiv='col-sm-12 col-md-7'
              pagination='pagination justify-content-end pagination-rounded'
              useAPIChangePaging
              currentPage={page}
              gotoPageCustom={goToPage}
              total={total}
            />
          </CardBody>
          {
            !!listTable[0]?.userRewards?.filter(e => e.type === 'accumulation').length && (
            <CardBody>
            <h5>Thông tin tích lũy</h5>
            <TableSimple
              columns={columnUserReward}
              isShowPagination={false}
              handleSortBy={() => {}}
              data={listTable[0]?.userRewards?.filter(e => e.type === 'accumulation')}
              isGlobalFilter={true}
              setGlobalFilterUser={setGlobalFilterUser}
              isManageBankGlobalFilter={true}
              tableClass='table-bordered align-middle nowrap mt-2'
              paginationDiv='col-sm-12 col-md-7'
              pagination='pagination justify-content-end pagination-rounded'
            />
          </CardBody>
          )
          }
          {
            !!listTable[0]?.userRewards?.filter(e => e.type === 'activation').length && (
            <CardBody>
            <h5>Thông tin kích hoạt</h5>
            <TableSimple
              columns={columnActivation}
              isShowPagination={false}
              handleSortBy={() => {}}
              data={listTable[0]?.userRewards?.filter(e => e.type === 'activation')}
              isGlobalFilter={true}
              setGlobalFilterUser={setGlobalFilterUser}
              isManageBankGlobalFilter={true}
              tableClass='table-bordered align-middle nowrap mt-2'
              paginationDiv='col-sm-12 col-md-7'
              pagination='pagination justify-content-end pagination-rounded'
            />
          </CardBody>
          )
          }
        </Card>
      )}
    </React.Fragment>
  )
}
export default ProductTab;
