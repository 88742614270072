import PropsType from 'prop-types'
import { useMemo, useState } from 'react'
import { Label } from 'reactstrap'
import React from 'react'
import { Row, Col, Space, Flex } from 'antd'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { ProductVN } from 'constants/vn'

ReportUserStatisticFilter.propTypes = {
  handleFilter: PropsType.func,
  handleExport: PropsType.func,
  handleResetFilter: PropsType.func
}

function ReportUserStatisticFilter({ handleFilter, handleExport, handleResetFilter }) {
  const [filter, setFilter] = useState({})
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    zone: app.zone,
    subzone: app.subzone,
    province: app.province,
    district: app.district,
    ward: app.ward,
    myLocationManagement: app.myLocationManagement
  }))
  const { zone, subzone, province, district, myLocationManagement } = useSelector(LayoutProperties)

  const onFilterChange = (name, value) =>
    setFilter((prev) => ({
      ...prev,
      [name]: value
    }))

  const ColProps = useMemo(() => ({
    xs: { span: 24 },
    xxl: { span: 8 }
  }))

  ////Lấy danh sách district
  // Get all unique parentId values from myLocationManagement
  // const myLocationManagementParentIds = myLocationManagement?.map((ward) => ward.parentId)
  // Từ myLocationManagementParentIds, lấy ra những district có id trong myLocationManagementParentIds
  const lstDistrict = myLocationManagement

  ////Lấy danh sách province
  // Get all unique parentId values from lstDistrict
  const districtParentIds = lstDistrict?.map((district) => district.parentId)
  //Từ districtParentIds, lấy ra những district có id trong districtParentIds
  const lstProvionce = province.filter((province) => districtParentIds.includes(province.id))

  ////Lấy danh sách subZone
  // Get all unique subZoneId(parentId) values from lstProvionce
  const provinceParentIds = lstProvionce?.map((province) => province.subZoneId)
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstSubZone = subzone?.filter((subZone) => provinceParentIds.includes(subZone.id))

  ////Lấy danh sách zone
  // Get all unique zoneId(parentId) values from lstProvionce
  const subZoneParentIds = lstProvionce?.map((province) => province.zoneId) // trong lstProvionce có lưu zoneId
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstZone = zone.filter((zone) => subZoneParentIds.includes(zone.id))
  const subZoneMemo = useMemo(() => {
    const parentDatas = filter?.zone_ids?.length > 0 ? filter.zone_ids : lstZone.map((e) => e.id)
    return lstSubZone?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.zoneId) : true))?.length > 0
      ? [{ label: 'Tất cả', value: 'all' }].concat(
          lstSubZone
            ?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.zoneId) : true))
            ?.map((item) => ({
              value: item.id,
              label: item.name
            }))
        )
      : []
  }, [lstSubZone, lstZone, filter])

  const provinceMemo = useMemo(() => {
    const parentDatas = filter?.sub_zone_ids?.length > 0 ? filter.sub_zone_ids : subZoneMemo.map((e) => e.value)
    console.log('provinces', parentDatas)
    return lstProvionce?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.subZoneId) : true))?.length >
      0
      ? [{ label: 'Tất cả', value: 'all' }].concat(
          lstProvionce
            ?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.subZoneId) : true))
            ?.map((item) => ({
              value: item.id,
              label: item.name
            }))
        )
      : []
  }, [lstProvionce, filter])

  const districtMemo = useMemo(() => {
    const parentDatas = filter?.province_ids?.length > 0 ? filter.province_ids : provinceMemo.map((e) => e.value)
    return lstDistrict?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.parentId) : true))?.length > 0
      ? [{ label: 'Tất cả', value: 'all' }].concat(
          lstDistrict
            ?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.parentId) : true))
            ?.map((item) => ({
              value: item.id,
              label: item.name
            }))
        )
      : []
  }, [lstDistrict, filter])

  const onResetFilter = () =>
    setFilter({
      zone_ids: undefined,
      sub_zone_ids: undefined,
      province_ids: undefined,
      district_ids: undefined,
      ward_ids: undefined,
      client: undefined,
      month: new Date().getMonth() + 1,
      zone_names: undefined,
      sub_zone_names: undefined,
      province_names: undefined,
      district_names: undefined,
      ward_names: undefined,
      client_name: undefined
    })

  return (
    <Row gutter={[10, 10]} align='bottom'>
      <Col {...ColProps}>
        <Label className='form-label'>Miền</Label>
        <FormSelect
          options={[{ label: 'Tất cả', value: 'all' }].concat(
            lstZone?.map((item) => ({
              value: item.id,
              label: item.name
            }))
          )}
          value={filter.zone_ids}
          onChange={(value, label) => {
            onFilterChange('zone_ids', value)
            onFilterChange(
              'zone_names',
              label.map((e) => e.label)
            )

            onFilterChange('sub_zone_ids', [])
            onFilterChange('province_ids', [])
            onFilterChange('district_ids', [])
            onFilterChange('ward_ids', [])
            onFilterChange('sub_zone_names', [])
            onFilterChange('province_names', [])
            onFilterChange('district_names', [])
            onFilterChange('ward_names', [])
          }}
          onSelect={(value) => {
            const all = lstZone?.map((item) => item.id)
            const all_names = lstZone?.map((item) => item.name)
            if (value && value === 'all') {
              onFilterChange('zone_ids', [...all])
              onFilterChange('zone_names', [...all_names])
            }
          }}
          placeholder={ProductVN.filter.zone.placeholder}
          mode='multiple'
        />
      </Col>
      <Col {...ColProps}>
        <Label className='form-label'>Vùng</Label>
        <FormSelect
          options={subZoneMemo}
          value={filter.sub_zone_ids}
          onChange={(value, label) => {
            onFilterChange('sub_zone_ids', value)
            onFilterChange(
              'sub_zone_names',
              label.map((e) => e.label)
            )

            onFilterChange('province_ids', [])
            onFilterChange('district_ids', [])
            onFilterChange('ward_ids', [])
            onFilterChange('province_names', [])
            onFilterChange('district_names', [])
            onFilterChange('ward_names', [])
          }}
          onSelect={(value) => {
            const all = subZoneMemo.map((item) => item.value)
            const all_names = lstSubZone?.filter((e) => filter.zone_ids?.includes(e.zoneId))?.map((item) => item.name)

            if (value && value === 'all') {
              onFilterChange('sub_zone_ids', [...all])
              onFilterChange('sub_zone_names', [...all_names])
            }
          }}
          placeholder={ProductVN.filter.zone.placeholder}
          mode='multiple'
        />
      </Col>
      <Col {...ColProps}>
        <Label className='form-label'>Tỉnh/thành phố</Label>
        <FormSelect
          options={provinceMemo}
          value={filter.province_ids}
          onChange={(value, label) => {
            onFilterChange('province_ids', value)
            onFilterChange(
              'province_names',
              label.map((e) => e.label)
            )
            onFilterChange('district_ids', [])
            onFilterChange('ward_ids', [])
            onFilterChange('district_names', [])
            onFilterChange('ward_names', [])
          }}
          onSelect={(value) => {
            const all = provinceMemo.map((item) => Number(item.value)).filter((num) => !isNaN(num))
            const all_names = lstProvionce
              ?.filter((e) => filter.sub_zone_ids?.includes(e.subZoneId))
              ?.map((item) => item.name)
            if (value && value === 'all') {
              onFilterChange('province_ids', [...all])
              onFilterChange('province_names', [...all_names])
            }
          }}
          placeholder='Tất cả'
          mode='multiple'
        />
      </Col>
      <Col {...ColProps}>
        <Label className='form-label'>Quận/huyện</Label>
        <FormSelect
          options={districtMemo}
          value={filter.district_ids}
          onChange={(value, label) => {
            onFilterChange('district_ids', value)
            onFilterChange(
              'district_names',
              label.map((e) => e.label)
            )

            onFilterChange('ward_ids', [])
            onFilterChange('ward_names', [])
          }}
          onSelect={(value) => {
            const all = districtMemo.map((item) => Number(item.value)).filter((num) => !isNaN(num))
            const all_names = lstDistrict
              ?.filter((e) => filter.province_ids?.includes(e.parentId))
              ?.map((item) => item.name)
            if (value && value === 'all') {
              onFilterChange('district_ids', [...all])
              onFilterChange('district_names', [...all_names])
            }
          }}
          placeholder='Tất cả'
          mode='multiple'
        />
      </Col>

      <Col {...ColProps}>
        <Label className='form-label'>Loại tài khoản</Label>
        <FormSelect
          options={[
            {
              label: 'Cửa hàng',
              value: 'user'
            },
            {
              label: 'Kĩ thuật viên',
              value: 'technician'
            }
          ]}
          value={filter.client}
          onChange={(value, label) => {
            onFilterChange('client', value)
            onFilterChange('client_name', label.label)
          }}
          placeholder='Tất cả'
        />
      </Col>

      <Col {...ColProps}>
        <Label className='form-label'>Thời gian</Label>
        <FormSelect
          options={[
            {
              label: 'Tháng 1',
              value: 1
            },
            {
              label: 'Tháng 2',
              value: 2
            },
            {
              label: 'Tháng 3',
              value: 3
            },
            {
              label: 'Tháng 4',
              value: 4
            },
            {
              label: 'Tháng 5',
              value: 5
            },
            {
              label: 'Tháng 6',
              value: 6
            },
            {
              label: 'Tháng 7',
              value: 7
            },
            {
              label: 'Tháng 8',
              value: 8
            },
            {
              label: 'Tháng 9',
              value: 9
            },
            {
              label: 'Tháng 10',
              value: 10
            },
            {
              label: 'Tháng 11',
              value: 11
            },
            {
              label: 'Tháng 12',
              value: 12
            }
          ]}
          defaultValue={new Date().getMonth() + 1}
          value={filter.month}
          onChange={(value, label) => {
            onFilterChange('month', value)
            onFilterChange('month_name', label?.label)
          }}
        />
      </Col>

      <Col xxl={6} lg={2} className='mt-3 w-100'>
        <Space>
          <button
            type='button'
            className='btn btn-soft-danger'
            style={{ width: 'max-content' }}
            onClick={() => {
              onResetFilter()
              handleResetFilter()
            }}
          >
            Xóa tìm kiếm
          </button>
          <button
            type='button'
            className='btn btn-primary'
            style={{ width: 'max-content' }}
            onClick={() => handleFilter(filter)}
          >
            Tìm kiếm
          </button>
          <button className='btn btn-primary' style={{ width: 'max-content' }} onClick={() => handleExport(filter)}>
            <i className='mdi mdi-database-export me-1 ms-2' />
            Export
          </button>
        </Space>
      </Col>
    </Row>
  )
}

export default ReportUserStatisticFilter
