import React, { useEffect, useMemo, useState } from 'react'
import { Col, Label, Row } from 'reactstrap'

//flatpickr
import 'flatpickr/dist/themes/material_blue.css'
import { ActivationVN, ProductVN } from 'constants/vn'

import { getZoneAPI, getAreaManagementOptionsAPI, getPortfolioAllAPI } from 'helpers/backend_helper'
import { exportAccountJoinProgrammingStatisticAPI } from 'helpers/backend_helper'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { getStorage } from 'helpers/global_helper'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { Space } from 'antd'

import SpinnerVersion2 from 'components/Common/SpinnerVersion2'
import moment from 'moment'

const AccountStatisticsAccumulationFilter = ({
  setGlobalFilter,
  search,
  setSearch,
  optionPermissionGroup,
  data,
  type
}) => {
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    zone: app.zone,
    subzone: app.subzone,
    province: app.province,
    district: app.district,
    ward: app.ward,
    myLocationManagement: app.myLocationManagement
  }))
  const { zone, subzone, province, district, ward, myLocationManagement } = useSelector(LayoutProperties)

  const selectProfileState = (state) => state.Profile
  const ProfileProperties = createSelector(selectProfileState, (profile) => ({
    profile: profile.profile
  }))

  const { profile } = useSelector(ProfileProperties)
  const [filter, setFilter] = useState({})
  const lstDistrict = myLocationManagement
  const onResetFilter = () => {
    setGlobalFilter({})
    setFilter({
      industry_ids: null
    })
  }

  const [isLoading, setLoading] = useState(false)

  const handleSelect = (value, key) => {
    setFilter((e) => ({ ...e, [key]: value }))
  }

  useEffect(() => {
    const filterManageLocality = getStorage('filterManageLocality')
    setFilter(filterManageLocality)
    getIndustryDataOptions()
  }, [])

  useEffect(() => {
    setFilter({})
  }, [window.location.pathname])

  const handleClickExport = async () => {
    setLoading(true)
    try {
      const response = await exportAccountJoinProgrammingStatisticAPI({ ...filter })

      const data = response.headers.get('Content-Type')
      if (data !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        toast('Hệ thống đang xử lý. Tệp của bạn đã được gửi tới email ' + profile?.email + ' trong vài phút', {
          type: 'success'
        })
      } else {
        try {
          const blob = await response.blob() // Fetch the blob
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Thống kê tài khoản tham gia và thực hiện ${moment().format('yyMMDD')}.xlsx`) // Tên file
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          toast('Export file thành công!', { type: 'success' })
        } catch (err) {
          console.log('err', err)
        }
      }
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  ////Lấy danh sách ward
  // Get all unique parentId values from lstDistrict
  const wardParentIds = myLocationManagement?.map((district) => district.id)

  //Từ wardParentIds, lấy ra những ward có id trong wardParentIds
  const lstWard = ward.filter((ward) => wardParentIds.includes(ward.parentId))

  ////Lấy danh sách province
  // Get all unique parentId values from lstDistrict
  const districtParentIds = lstDistrict?.map((district) => district.parentId)
  //Từ districtParentIds, lấy ra những district có id trong districtParentIds
  const lstProvionce = province.filter((province) => districtParentIds.includes(province.id))

  ////Lấy danh sách subZone
  // Get all unique subZoneId(parentId) values from lstProvionce
  const provinceParentIds = lstProvionce?.map((province) => province.subZoneId)
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstSubZone = subzone?.filter((subZone) => provinceParentIds.includes(subZone.id))

  ////Lấy danh sách zone
  // Get all unique zoneId(parentId) values from lstProvionce
  const subZoneParentIds = lstProvionce?.map((province) => province.zoneId) // trong lstProvionce có lưu zoneId
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstZone = zone.filter((zone) => subZoneParentIds.includes(zone.id))
  const currentYear = new Date().getFullYear() // Lấy năm hiện tại
  const [industrySectorOptions, setIndustrySectorOptions] = useState([])
  const [industrySector, setIndustrySector] = useState([])
  const subZoneMemo = useMemo(() => {
    const parentDatas = filter?.zone_ids?.length > 0 ? filter.zone_ids : lstZone.map((e) => e.id)
    return lstSubZone?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.zoneId) : true))?.length > 0
      ? [{ label: 'Tất cả', value: 'all' }].concat(
          lstSubZone
            ?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.zoneId) : true))
            ?.map((item) => ({
              value: item.id,
              label: item.name
            }))
        )
      : []
  }, [lstSubZone, lstZone, filter])

  const provinceMemo = useMemo(() => {
    const parentDatas = filter?.sub_zone_ids?.length > 0 ? filter.sub_zone_ids : subZoneMemo.map((e) => e.value)

    return lstProvionce?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.subZoneId) : true))?.length >
      0
      ? [{ label: 'Tất cả', value: 'all' }].concat(
          lstProvionce
            ?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.subZoneId) : true))
            ?.map((item) => ({
              value: item.id,
              label: item.name
            }))
        )
      : []
  }, [lstProvionce, filter])

  const districtMemo = useMemo(() => {
    const parentDatas = filter?.province_ids?.length > 0 ? filter.province_ids : provinceMemo.map((e) => e.value)
    return lstDistrict?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.parentId) : true))?.length > 0
      ? [{ label: 'Tất cả', value: 'all' }].concat(
          lstDistrict
            ?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.parentId) : true))
            ?.map((item) => ({
              value: item.id,
              label: item.name
            }))
        )
      : []
  }, [lstDistrict, filter])

  const getIndustryDataOptions = async () => {
    try {
      const resIndustrySector = await getPortfolioAllAPI({ type: 'industrySector' })
      console.log(resIndustrySector)
      setIndustrySector(resIndustrySector?.data)
      if (resIndustrySector?.data?.length > 0) {
        setIndustrySectorOptions(
          [{ label: 'Tất cả', value: 'all' }].concat(
            resIndustrySector?.data?.map((item) => ({
              value: item.id,
              label: item.name
            }))
          )
        )
      } else {
        setIndustrySectorOptions([])
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      <Row>
        {type === 'activation' && (
          <Col xxl={2.5} lg={2} className='mt-3'>
            <Label className='form-label'>{ActivationVN.filter.type.label}</Label>
            <FormSelect
              options={[
                {
                  label: 'Cửa hàng',
                  value: 'user'
                },
                {
                  label: 'Kĩ thuật viên',
                  value: 'technician'
                }
              ]}
              value={filter.client}
              onChange={(value, label) => {
                handleSelect(value, 'client')
                handleSelect(label?.label, 'client_namme')
              }}
              placeholder={ActivationVN.filter.type.placeholder}
            />
          </Col>
        )}

        <Col xxl={2.5} lg={2} className='mt-3'>
          <Label className='form-label'>Miền </Label>
          <FormSelect
            options={[{ label: 'Tất cả', value: 'all' }].concat(
              lstZone?.map((item) => ({
                value: item.id,
                label: item.name
              }))
            )}
            value={filter?.zone_ids}
            onChange={(value) => {
              handleSelect(value, 'zone_ids')
              handleSelect([], 'sub_zone_ids')
              handleSelect([], 'province_ids')
              handleSelect([], 'district_ids')
              handleSelect([], 'ward_ids')
            }}
            onSelect={(value) => {
              const all = lstZone?.map((item) => item.id)
              if (value && value === 'all') {
                return handleSelect([...all], 'zone_ids')
              }
            }}
            mode='multiple'
            placeholder={ProductVN.filter?.zone.placeholder}
          />
          <SpinnerVersion2 loading={isLoading} />
        </Col>
        <Col xxl={2.5} lg={2} className='mt-3'>
          <Label className='form-label'>{'Vùng'}</Label>
          <FormSelect
            options={subZoneMemo}
            value={filter?.sub_zone_ids}
            onChange={(value) => {
              handleSelect(value, 'sub_zone_ids')
              handleSelect(undefined, 'province_ids')
              handleSelect(undefined, 'location_ids')
              handleSelect(undefined, 'ward_ids')
            }}
            onSelect={(value) => {
              const all = subZoneMemo.map((item) => item.value).filter((num) => !isNaN(num))
              if (value && value === 'all') {
                return handleSelect([...all], 'sub_zone_ids')
              }
            }}
            placeholder={'Chọn vùng'}
            mode='multiple'
          />
        </Col>
        <Col xxl={2.5} lg={2} className='mt-3'>
          <Label className='form-label'>Tỉnh/ Thành phố</Label>
          <FormSelect
            options={provinceMemo}
            value={filter?.province_ids}
            onChange={(value, label) => {
              handleSelect(value, 'province_ids')
              handleSelect(label.map((e) => e.label, 'province_names'))

              handleSelect([], 'district_ids')
              handleSelect([], 'ward_ids')
              handleSelect([], 'district_names')
              handleSelect([], 'ward_names')
            }}
            onSelect={(value) => {
              const all = provinceMemo.map((item) => Number(item.value)).filter((num) => !isNaN(num))
              const all_names = lstProvionce
                ?.filter((e) => filter?.sub_zone_ids?.includes(e.subZoneId))
                ?.map((item) => item.name)

              if (value && value === 'all') {
                handleSelect([...all], 'province_ids')
                handleSelect([...all_names], 'province_names')
              }
            }}
            placeholder='Tất cả'
            mode='multiple'
          />
        </Col>
        <Col xxl={2.5} lg={2} className='mt-3'>
          <Label className='form-label'>Ngành hàng</Label>
          <FormSelect
            options={industrySectorOptions}
            value={filter?.industry_sector_ids}
            onChange={(value, label) => {
              handleSelect(value, 'industry_sector_ids')
            }}
            onSelect={(value) => {
              if (value && value === 'all') {
                console.log(industrySector)
                handleSelect(
                  industrySector.map((e) => e.id),
                  'industry_sector_ids'
                )
              }
            }}
            // value={filter?.industry_ids}
            placeholder='Chọn Ngành'
            mode='multiple'
          />
        </Col>

        <Col xxl={2.5} lg={2} className='mt-3'>
          <Label className='form-label'>Tháng</Label>
          <FormSelect
            options={Array.from({ length: 12 }, (_, i) => {
              const date = new Date()
              date.setMonth(date.getMonth() - i) // Lùi dần về trước 12 tháng
              const year = date.getFullYear()
              const month = `0${date.getMonth() + 1}`.slice(-2) // Định dạng hai chữ số
              return {
                value: `${month}-${year}`,
                label: `${month}-${year}`
              }
            })}
            value={filter?.month}
            onChange={(value) => {
              handleSelect(value, 'month')
            }}
            onSelect={(value) => {
              if (value === 'all') {
                const allMonths = Array.from({ length: 12 }, (_, i) => {
                  const date = new Date()
                  date.setMonth(date.getMonth() - i)
                  const year = date.getFullYear()
                  const month = `0${date.getMonth() + 1}`.slice(-2)
                  return `${month}-${year}`
                })
                return handleSelect(allMonths, 'month')
              }
            }}
            placeholder='Chọn tháng'
          />
        </Col>
      </Row>
      <Row className='mt-5'>
        <Col className='mt-5'>
          <Space>
            <button
              type='button'
              className='btn btn-primary '
              style={{ width: '100px' }}
              disabled={!(filter && filter.month)}
              onClick={() => setGlobalFilter(filter)}
            >
              Tìm kiếm
            </button>
            <button
              type='button'
              className='btn btn-soft-danger'
              onClick={() => {
                onResetFilter()
                // handleResetFilter()
              }}
            >
              Xóa tìm kiếm
            </button>
            <button
              type='button'
              className='btn btn-primary'
              style={{ width: '100px' }}
              onClick={handleClickExport}
              disabled={data.length === 0}
            >
              Export
            </button>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col className='mt-5'></Col>
      </Row>
    </>
  )
}
export default AccountStatisticsAccumulationFilter
