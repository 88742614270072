import React from "react"
import { Badge, UncontrolledTooltip } from "reactstrap"
import { Link } from "react-router-dom"
import { UserVN } from "constants/vn"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

const UserNo = cell => {
    return (
        <Link to="#" className="text-body fw-bold">
            {cell?.row ? cell.row.index + 1 : ""}
        </Link>
    )
}

const UserID = cell => {
    return cell.value ? cell.value : "--"
}

const AccountName = cell => {
    return cell.value ? (
        <div>
            <span style={{ maxWidth: 300 }} className="d-inline-block text-truncate" id={`nameshop-tooltip-${cell.row.original.id}`}>
                {cell.value}
            </span>
            <UncontrolledTooltip trigger="hover" placement="top" target={`nameshop-tooltip-${cell.row.original.id}`}>
                {cell.value}
            </UncontrolledTooltip>
        </div>
    ) : (
        "--"
    )
}

const Email = cell => {
    return cell.value ? cell.value : "--"
}

const Part = cell => {
    return cell.value ? cell.value : "--"
}

const PermissionZone = cell => {
    return (
        <>
            {cell?.row.original?.zones.length ? (
                <>
                    {cell.row.original.zones.map((zone, index) => (
                        <span key={index}>
                            <p className='m-0 text-truncate'>{zone}</p>
                        </span>
                    ))}
                </>
            ): "--" }
        </>
    )
}

const PermissionGroup = cell => {
    return cell.value ? cell.value.name : "--"
}

const Status = cell => {
    switch (cell.value) {
        case "active":
            return <Badge className="badge-soft-success">{UserVN.filters.status.statusOptions[0].label}</Badge>
        case "inactive":
            return <Badge className="badge-soft-warning">{UserVN.filters.status.statusOptions[1].label}</Badge>
    }
}

export { UserNo, UserID, AccountName, Email, Status, Part, PermissionGroup, PermissionZone }
