import { CommonVN, ProductActivationVN } from 'constants/vn'
import React, { useEffect, useMemo, useState } from 'react'
import { Col, Label, Modal, ModalBody, Row } from 'reactstrap'

// Formik validation
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { filterDataHaveValue } from 'helpers/global_helper'
import { getProductActivationList } from 'store/actions'
import { getPortfolioAllAPI, postProductActivationAPI, updateProductActivationAPI } from 'helpers/backend_helper'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { Form } from 'antd'
import { FormInput } from 'components/Common/form-input/FormInput'
import { debounce, isEqual } from 'lodash'
import dayjs from 'dayjs'
import { MAX_LENGTH_30, StatusActivationOptions } from 'constants/constants'
import { FormDate } from 'components/Common/form-date/FormDate'
import { FormDateRange } from 'components/Common/form-date-range/FormDateRange'
import ConfirmModal from 'components/Common/ConfirmModal'

const { RangePicker } = FormDate

const AddEditProductActivation = ({ show, onCloseClick, selected, setPage }) => {
  const [form] = Form.useForm()
  const watchForm = Form.useWatch([], form)
  const [submittable, setSubmittable] = useState()
  const [industry, setIndustry] = useState()
  const [industrySector, setIndustrySector] = useState()
  const [initData, setInitData] = useState({})
  const [onCancel, setOnCancel] = useState(false)

  const dispatch = useDispatch()

  const selectProductActivationsState = (state) => state.ProductActivationReducer
  const ProductActivationsProperties = createSelector(selectProductActivationsState, (reducer) => ({
    type: reducer.type,
    productActivationDetail: reducer.productActivationDetail
  }))
  const { type, productActivationDetail } = useSelector(ProductActivationsProperties)

  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (layout) => ({
    filter: layout.filter
  }))
  const { filter } = useSelector(LayoutProperties)

  const data = useMemo(() => {
    if (type !== 'create') {
      return productActivationDetail
    } else {
      return undefined
    }
  }, [type, productActivationDetail])

  const mapsData = (data) => {
    return filterDataHaveValue({
      code: data.code,
      industry: data?.industrySector?.parent?.id ?? undefined,
      industrySector: data?.industrySectorId ?? undefined,
      applyDate: [dayjs(data?.startApplyDate), dayjs(data?.endApplyDate)],
      status: data?.status ?? 'inactivate'
    })
  }

  const getDataOptions = async () => {
    try {
      const resIndustry = await getPortfolioAllAPI({ type: 'industry' })
      const resIndustrySector = await getPortfolioAllAPI({ type: 'industrySector' })
      setIndustry(resIndustry?.data)
      setIndustrySector(resIndustrySector?.data)
    } catch (error) {
      console.log(error)
    }
  }

  const onFinish = async (values) => {
    try {
      console.log('submit', values)
      if (type === 'edit') {
        const res = await updateProductActivationAPI({
          id: productActivationDetail.id,
          code: values.code,
          industrySectorId: values?.industrySector ?? undefined,
          startApplyDate: values?.applyDate[0],
          endApplyDate: values?.applyDate[1],
          status: values?.status ?? 'inactivate'
        })
        if (res) {
          toast(res.message, { type: 'success' })
          dispatch(getProductActivationList(filter))
        }
      }
      if (type === 'create') {
        const res = await postProductActivationAPI({
          code: values.code,
          industrySectorId: values?.industrySector ?? undefined,
          startApplyDate: values?.applyDate[0],
          endApplyDate: values?.applyDate[1]
        })
        if (res) {
          toast(res.message, { type: 'success' })
          dispatch(getProductActivationList())
          setPage(1)
        }
      }
      onCloseClick()
    } catch (error) {
      console.log(error)
      toast(error.response.data.message, { type: 'error' })
    }
  }

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true)
      },
      () => {
        setSubmittable(false)
      }
    )
  }, [watchForm])

  useEffect(() => {
    if (show) {
      getDataOptions()
    }
    form.resetFields()
  }, [show])

  useEffect(() => {
    if (data) {
      form.setFieldsValue(mapsData(data))
      setInitData(mapsData(data))
    } else {
      form.resetFields()
    }
  }, [data])

  const onClickCancel = () => {
    setOnCancel(false)
    onCloseClick()
  }

  const checkClickCancel = () => {
    if (type != 'view') {
      if (type == 'create') {
        if (form.isFieldsTouched()) {
          setOnCancel(true)
        } else {
          onCloseClick()
        }
      } else {
        if (!isEqual(initData, watchForm)) {
          setOnCancel(true)
        } else {
          onCloseClick()
        }
      }
    } else {
      onCloseClick()
    }
  }

  return (
    <Modal
      size='lg'
      isOpen={show}
      toggle={() => {
        checkClickCancel()
      }}
      centered={true}
    >
      <div className='modal-content'>
        <ModalBody className='p-4 text-start'>
          <button type='button' onClick={checkClickCancel} className='btn-close position-absolute end-0 top-0 m-3' />
          <h2>
            {type === 'create'
              ? ProductActivationVN.addProductActivation
              : type === 'edit'
              ? ProductActivationVN.editProductActivation
              : ProductActivationVN.viewProductActivation}
          </h2>
          <Form onFinish={onFinish} autoComplete='off' form={form} name='addEditProductActivationForm'>
            <div className='form-group pt-4'>
              <Row>
                <Col sm='6'>
                  <Label className='form-label'>
                    {ProductActivationVN.form.code.label}
                    <a className='text-danger'>*</a>
                  </Label>
                  <Form.Item name='code' rules={[{ required: true, message: 'Vui lòng nhập mã bảo hành' }]}>
                    <FormInput
                      onBlur={(value) => form.setFieldValue('code', value?.target?.value?.trim())}
                      placeholder={ProductActivationVN.form.code.placeholder}
                      maxLength={MAX_LENGTH_30}
                    />
                  </Form.Item>
                </Col>
                <Col sm='6'>
                  <Label className='form-label'>
                    {ProductActivationVN.form.applyDate.label}
                    <a className='text-danger'>*</a>
                  </Label>
                  <Form.Item name='applyDate' rules={[{ required: true, message: 'Vui lòng chọn thời gian áp dụng' }]}>
                    <FormDateRange
                      className='w-100'
                      size='large'
                      format={CommonVN.datePlaceholder.toUpperCase()}
                      // disabledDate={(current) => dayjs().isAfter(current, 'day')}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col sm='6'>
                  <Label className='form-label'>
                    {ProductActivationVN.form.industry.label}

                    <a className='text-danger'>*</a>
                  </Label>
                  <Form.Item name='industry'>
                    <FormSelect
                      options={industry?.map((item) => ({
                        value: item.id,
                        label: item.name
                      }))}
                      onChange={() => {
                        form.setFieldValue('industrySector', undefined)
                      }}
                      placeholder={ProductActivationVN.form.industry.placeholder}
                    />
                  </Form.Item>
                </Col>
                <Col sm='6'>
                  <Label className='form-label'>
                    {ProductActivationVN.form.industrySector.label}
                    <a className='text-danger'>*</a>
                  </Label>
                  <Form.Item name='industrySector'>
                    <FormSelect
                      options={industrySector
                        ?.filter((e) => e.parentId == watchForm.industry)
                        ?.map((item) => ({
                          value: item.id,
                          label: item.name
                        }))}
                      placeholder={ProductActivationVN.form.industrySector.placeholder}
                      disabled={!watchForm?.industry}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {type === 'edit' && (
                <Row>
                  <Col sm='6'>
                    <Label className='form-label'>{ProductActivationVN.form.status.label}</Label>
                    <Form.Item name='status'>
                      <FormSelect options={StatusActivationOptions} disabled />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </div>
            <div className='hstack gap-2 justify-content-center mt-4'>
              <button type='button' className='btn btn-soft-secondary w-25 ' onClick={checkClickCancel}>
                Hủy
              </button>
              <button
                disabled={!submittable || isEqual(initData, watchForm)}
                type={'button'}
                className='btn btn-primary w-25'
                onClick={debounce(() => {
                  form.submit()
                }, 500)}
              >
                {'Lưu'}
              </button>
            </div>
          </Form>
        </ModalBody>
        <ConfirmModal
          show={onCancel}
          onConfirmClick={onClickCancel}
          onCloseClick={() => setOnCancel(false)}
          title={`Xác nhận`}
          description={`Thay đổi sẽ không được lưu. Bạn có chắc chắn muốn thoát không?`}
        />
      </div>
    </Modal>
  )
}
export default AddEditProductActivation
