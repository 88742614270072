import { getPortfolioAllAPI } from 'helpers/backend_helper'
import { useEffect, useState, useMemo } from 'react'

export const useCategoriesFilter = ({ selectedIndustrySector, selectedCategory, selectedModel }) => {
  const [industrySector, setIndustrysector] = useState([])
  const [category, setCategory] = useState([])
  const [model, setModel] = useState([])

  const getIndustryDataOptions = async () => {
    try {
      const resIndustrysector = await getPortfolioAllAPI({ type: 'industrySector' })
      const resCategory = await getPortfolioAllAPI({ type: 'category' })
      const resModel = await getPortfolioAllAPI({ type: 'model' })
      setIndustrysector(resIndustrysector?.data)
      setCategory(resCategory?.data)
      setModel(resModel?.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getIndustryDataOptions()
  }, [])

  const allOption = { label: 'Tất cả', value: 'all' }

  const industrySectorMemo = useMemo(() => {
    const allIndustrySectorIds = [];
    const allIndustrySectorNames = []
    const  industrySectorOptions = industrySector?.length > 0 ? [allOption].concat(
      industrySector
        ?.map((item) => {
          allIndustrySectorIds.push(item.id);
          allIndustrySectorNames.push(item.name);
          return ({
          value: item.id,
          label: item.name
        })
      })
    )
    : [];

    return { options: industrySectorOptions, ids: allIndustrySectorIds, names: allIndustrySectorNames }
  }, [industrySector])

  const categoryMemo = useMemo(() => {
    const allCategoryIds = [];
    const allCategoryNames = []
    const industry_sector_ids = selectedIndustrySector.length > 0 ? selectedIndustrySector : industrySectorMemo.ids;
    const categoryOptions = category?.length > 0 ? [allOption].concat(
      category.filter((c) => industry_sector_ids.includes(c.parentId))
        ?.map((item) => {
          allCategoryIds.push(item.id);
          allCategoryNames.push(item.name);
          return ({
            value: item.id,
            label: item.name
          })
      })
    )
    : [];
    return { options: categoryOptions, ids: allCategoryIds, names: allCategoryNames }
  }, [industrySectorMemo.ids, category, selectedIndustrySector])
  
  const modelMemo = useMemo(() => {
    const allModelIds = [];
    const allModelNames = []
    const category_ids = selectedCategory.length > 0 ? selectedCategory : categoryMemo.ids
    const modelOptions = model?.length > 0 ? [allOption].concat(
      model.filter((c) => category_ids.includes(c.parentId))
        ?.map((item) => {
          allModelIds.push(item.id);
          allModelNames.push(item.name);
          return ({
            value: item.id,
            label: item.name
          })
      })
    )
    : [];
    return { options: modelOptions, ids: allModelIds, names: allModelNames }
  }, [categoryMemo.ids, category, selectedCategory])

  return {
    industrySectorOptions: industrySectorMemo,
    categoryOptions: categoryMemo,
    modelOptions: modelMemo,
  }
}

