import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

//import components

import { getKTVList } from 'store/actions'

import { KTVNo, Location, Status, KTVID, AccountName, CreatedDate, Phone } from './ManageKTVCol'

//redux
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

import { Col, Row, UncontrolledTooltip, Card, CardBody } from 'reactstrap'
import Spinners from 'components/Common/Spinner'
import { toast } from 'react-toastify'
import TableContainer from 'components/Common/TableContainer'
import { ManageKTVVN } from 'constants/vn'
import ConfirmModal from 'components/Common/ConfirmModal'
import {
  checkExportFileAPI,
  exportListAPI,
  exportTechnicianListAPI,
  exportTechnicianListFetcherAPI,
  lockAccountAPI,
  lockTechnicianAccountAPI
} from 'helpers/backend_helper'
import { adjustEndDate, checkIsLocked, getStorage, setStorage } from 'helpers/global_helper'
import fileDownload from 'js-file-download'
import moment from 'moment'
import usePermission from 'hooks/usePermission'
import { SCREEN_CODE, TECHNICIAN_ACCOUNT_MANAGEMENT_CODE } from 'constants/permission-code'
import PermissionWrapper from 'components/Common/PermissionWrapper'

function ManageKTV() {
  //meta title
  document.title = 'KTV List | HPG'
  const screenCode = useMemo(() => SCREEN_CODE.TECHNICIAN_ACCOUNT_MANAGEMENT, [])

  const dispatch = useDispatch()
  const hasPermission = usePermission({
    screenCode,
    code: TECHNICIAN_ACCOUNT_MANAGEMENT_CODE.TECHNICIAN_GET_USER_PROFILE
  })

  const selectKTVsState = (state) => state.ShopReducer
  const KTVsProperties = createSelector(selectKTVsState, (shopReducer) => ({
    ktvs: shopReducer.shops,
    loading: shopReducer.loading,
    total: shopReducer.total
  }))

  const { ktvs, loading, total } = useSelector(KTVsProperties)

  const selectProfileState = (state) => state.Profile
  const ProfileProperties = createSelector(selectProfileState, (profile) => ({
    profile: profile.profile
  }))

  const { profile } = useSelector(ProfileProperties)

  const [isLoading, setLoading] = useState(loading)
  const [showLockAccount, setShowLockAccount] = useState(false)
  const [selected, setSelected] = useState()
  const [filter, setFilter] = useState()

  useEffect(() => {
    const filterMangeKTV = getStorage('filterManageKTV')
    dispatch(getKTVList(filterMangeKTV))
  }, [dispatch])

  const handleClickExport = async () => {
    // setLoading(true)
    // try {
    //   const res = await exportTechnicianListAPI({ ...filter, client: 'technician' })
    //   if (res?.data?.success === false) {
    //     toast(res?.message, { type: 'error' })
    //   } else {
    //     toast('Hệ thống đang xử lý. Tệp của bạn đã được gửi tới email ' + profile?.email + ' trong vài phút', {
    //       type: 'success'
    //     })
    //   }
    // } catch (error) {
    //   toast(error.response.data.message, { type: 'error' })
    // }
    // setLoading(false)

    setLoading(true)
    try {
      const response = await exportTechnicianListFetcherAPI({ ...filter, client: 'technician' })
      const data = response.headers.get('Content-Type')
      if (data !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        toast('Hệ thống đang xử lý. Tệp của bạn đã được gửi tới email ' + profile?.email + ' trong vài phút', {
          type: 'success'
        })
      } else {
        try {
          const blob = await response.blob() // Fetch the blob
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${moment().format('yyMMDD')}_Danh sach tai khoan ky thuat vien.xlsx`) // Tên file
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          toast('Export file thành công!', { type: 'success' })
        } catch (err) {
          console.log('err', err)
        }
      }
    } catch (error) {}
    setLoading(false)
  }

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'employeeCode',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <KTVID {...cellProps} />
        }
      },
      {
        Header: 'Số điện thoại',
        accessor: 'phone',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Phone {...cellProps} />
        }
      },
      {
        Header: 'Họ và tên',
        accessor: 'name',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <AccountName {...cellProps} />
        }
      },
      {
        Header: 'Miền',
        accessor: 'zoneName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Location {...cellProps} />
        }
      },
      {
        Header: 'Tỉnh/Thành phố',
        accessor: 'provinceName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Location {...cellProps} />
        }
      },
      {
        Header: 'Quận/ Huyện',
        accessor: 'districtName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Location {...cellProps} />
        }
      },
      {
        Header: 'Phường xã',
        accessor: 'wardName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Location {...cellProps} />
        }
      },
      {
        Header: 'Ngày tạo tài khoản',
        accessor: 'createdAt',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <CreatedDate {...cellProps} />
        }
      },
      {
        Header: 'Trạng thái hoạt động',
        accessor: 'status',
        disableSortBy: true,
        hideSort: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Status {...cellProps} />
        }
      },
      {
        Header: 'Thao tác',
        accessor: 'action',
        disableSortBy: true,
        hideSort: true,
        show: hasPermission,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <ul className='list-unstyled hstack gap-1 mb-0'>
              <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                <Link
                  to={`/manage-ktv/view/${cellProps.row.original.id}`}
                  className='btn btn-sm btn-soft-primary'
                  id={`viewtooltip-${cellProps.row.original.id}`}
                >
                  <i className='mdi mdi-eye-outline' />
                  <UncontrolledTooltip
                    trigger='hover'
                    placement='top'
                    target={`viewtooltip-${cellProps.row.original.id}`}
                  >
                    Xem
                  </UncontrolledTooltip>
                </Link>
              </li>
              <PermissionWrapper
                screenCode={screenCode}
                code={TECHNICIAN_ACCOUNT_MANAGEMENT_CODE.TECHNICIAN_UPDATE_USER_PROFILE}
              >
                {cellProps.row.original.status !== 'terminated' &&
                  cellProps.row.original.status !== 'pending-removal' && (
                    <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                      <Link
                        to={`/manage-ktv/edit/${cellProps.row.original.id}`}
                        className='btn btn-sm btn-soft-info'
                        id={`edittooltip-${cellProps.row.original.id}`}
                      >
                        <i className='mdi mdi-pencil-outline' />
                        <UncontrolledTooltip
                          trigger='hover'
                          placement='top'
                          target={`edittooltip-${cellProps.row.original.id}`}
                        >
                          Sửa
                        </UncontrolledTooltip>
                      </Link>
                    </li>
                  )}
              </PermissionWrapper>
            </ul>
          )
        }
      }
    ],
    [hasPermission]
  )

  const setGlobalFilterUser = async (data) => {
    const newData = adjustEndDate(data)
    setStorage('filterManageKTV', newData)
    setFilter(newData)
    dispatch(getKTVList(newData))
  }

  const goToPage = (page) => {
    setFilter({ ...filter, page: page })
    dispatch(getKTVList({ ...filter, page: page }))
  }

  const onLockAccount = async () => {
    try {
      const res = await lockTechnicianAccountAPI({
        id: selected.id,
        status: checkIsLocked(selected.status) ? 'active' : 'inactive'
      })
      if (res) {
        toast(res.message, { type: 'success' })
        setShowLockAccount(false)
      }
    } catch (error) {
      console.log(error)
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const permissionColumns = useMemo(() => columns.filter((column) => column.show !== false), [columns])

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <h4 className=' mb-sm-0 font-size-18 pb-4'>{ManageKTVVN.title}</h4>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody className='border-bottom'>
                    <div className='d-flex align-items-center'>
                      <h5 className='mb-0 card-title flex-grow-1'>{ManageKTVVN.shopList}</h5>
                      <div className='flex-shrink-0'>
                        <PermissionWrapper
                          screenCode={screenCode}
                          code={TECHNICIAN_ACCOUNT_MANAGEMENT_CODE.TECHNICIAN_EXPORT_LIST_USER}
                        >
                          <button onClick={handleClickExport} className='btn btn-primary me-1'>
                            {ManageKTVVN.export}
                          </button>
                        </PermissionWrapper>
                      </div>
                    </div>
                  </CardBody>

                  <CardBody>
                    <TableContainer
                      isProductFilter={false}
                      columns={permissionColumns}
                      data={ktvs}
                      isGlobalFilter={true}
                      setGlobalFilterUser={setGlobalFilterUser}
                      isManageShopGlobalFilter={true}
                      isPagination={true}
                      placeholderSearch={ManageKTVVN.filters.search.placeholder}
                      iscustomPageSizeOptions={false}
                      isShowingPageLength={false}
                      customPageSize={10}
                      isKTV
                      tableClass='table-bordered align-middle nowrap mt-2'
                      paginationDiv='col-sm-12 col-md-7'
                      pagination='pagination justify-content-end pagination-rounded'
                      useAPIChangePaging
                      gotoPageCustom={goToPage}
                      total={total}
                      isSearchInput={false}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <ConfirmModal
        show={showLockAccount}
        onConfirmClick={onLockAccount}
        onCloseClick={() => setShowLockAccount(false)}
        description={`Xác nhận khóa tài khoản bởi admin ${profile?.name ?? ''}`}
      />
    </React.Fragment>
  )
}

export default ManageKTV
