import PropsType from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { Label, UncontrolledTooltip } from 'reactstrap'
import React from 'react'
import { Row, Col, Space, Flex, Form } from 'antd'

//flatpickr
import { ActivationVN, CommonVN, ProductVN } from 'constants/vn'

import { DatePicker } from 'antd'
import { FormInput } from 'components/Common/form-input/FormInput'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { MAX_LENGTH_SEARCH, TypeProgramOptions } from 'constants/constants'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { FormDateRange } from 'components/Common/form-date-range/FormDateRange'
import { getPortfolioAllAPI } from 'helpers/backend_helper'

const { RangePicker } = DatePicker

ManageImportHistoryFilter.propTypes = {
  handleFilter: PropsType.func,
  handleConfirm: PropsType.func,
  handleResetFilter: PropsType.func,
  handleExport: PropsType.func
}

function ManageImportHistoryFilter({ handleFilter, handleConfirm, handleResetFilter, handleExport }) {
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    zone: app.zone,
    subzone: app.subzone,
    province: app.province,
    district: app.district,
    ward: app.ward,
    myLocationManagement: app.myLocationManagement
  }))
  const { zone, subzone, province, district, myLocationManagement, ward } = useSelector(LayoutProperties)

  const [filter, setFilter] = useState({})
  const [industry, setIndustry] = useState()
  const [industrySector, setIndustrysector] = useState()
  const [category, setCategory] = useState()
  const [form] = Form.useForm()
  const watchType = Form.useWatch('type', form)
  const watchForm = Form.useWatch([], form)
  const [model, setModel] = useState()

  const onFilterChange = (name, value) =>
    setFilter((prev) => ({
      ...prev,
      [name]: value
    }))

  const ColProps = useMemo(() => ({
    xs: { span: 24 },
    xxl: { span: 4 }
  }))

  const onResetFilter = () =>
    setFilter({
      serial: '',
      model_name: '',
      shop_name: '',
      phone: '',
      zone_ids: undefined,
      sub_zone_ids: undefined,
      province_ids: undefined,
      district_ids: undefined,
      ward_ids: undefined,
      client: undefined,
      start_date1: undefined,
      end_date1: undefined,
      industry_ids: undefined,
      industry_sector_ids: undefined,
      category_ids: undefined,
      email: '',
      phone: '',
      search: '',
      label: '',
      type: 'product'
    })

  return (
    <Row gutter={[10, 10]} align='bottom'>
      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Nhập từ khoá tìm kiếm</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập tên thư mục'
          value={filter?.search || ''}
          name='search'
          id={`input-serial-filter`}
          onChange={(e) => onFilterChange('search', e.target.value)}
          onBlur={(e) => onFilterChange('search', e.target.value)}
        />
      </Col>

      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>{'Loại'}</Label>
        <FormSelect
          options={[
            {
              label: 'Sản phẩm',
              value: 'product'
            },
            {
              label: 'Cập nhật sản phẩm',
              value: 'update_product'
            },
            {
              label: 'Danh mục sản phẩm',
              value: 'category'
            },
            {
              label: 'Mã bảo hành',
              value: 'product_activation'
            },
            {
              label: 'Cập nhật mã bảo hành',
              value: 'update_product_activation'
            },
            // {
            //   label: 'Đối soát cơ cấu sản phẩm',
            //   value: 'reconcile_by_product'
            // },
            // {
            //   label: 'Đối soát cơ cấu gói sản phẩm',
            //   value: 'reconcile_by_package'
            // },
            // {
            //   label: 'Đối soát cơ cấu doanh thu',
            //   value: 'reconcile_by_revenue'
            // },
            // {
            //   label: 'Đối soát trưng bày',
            //   value: 'reconcile_by_display'
            // },
            // {
            //   label: 'Đối soát thưởng cơ cấu sản phẩm',
            //   value: 'reconcile_accounting_by_product'
            // },
            // {
            //   label: 'Đối soát thưởng cơ cấu gói sản phẩm',
            //   value: 'reconcile_accounting_by_package'
            // },
            // {
            //   label: 'Đối soát thưởng cơ cấu doanh thu',
            //   value: 'reconcile_accounting_by_revenue'
            // },
            // {
            //   label: 'Đối soát thưởng trưng bày',
            //   value: 'reconcile_accounting_by_display'
            // }
            {
              label: 'Đối soát chương trình',
              value: 'reconcile_by_programming'
            },
            {
              label: 'Trả thưởng chương trình',
              value: 'confirm_reconcile_by_programming'
            }
          ]}
          value={filter.type}
          onChange={(value) => {
            onFilterChange('type', value)
          }}
          defaultValue={'product'}
        />
      </Col>

      <Col {...ColProps} className='w-100'>
        <Space>
          <button
            type='button'
            className='btn btn-soft-danger'
            onClick={() => {
              onResetFilter()
              handleResetFilter()
            }}
          >
            Xóa tìm kiếm
          </button>

          <button type='button' className='btn btn-primary' onClick={() => handleFilter(filter)}>
            Tìm kiếm
          </button>
        </Space>
      </Col>
    </Row>
  )
}

export default ManageImportHistoryFilter
