import React, { useEffect } from 'react'
import { Button, Spin } from 'antd'
import { Spinner } from 'reactstrap'
const SpinnerVersion2 = ({ loading, setLoading }) => {
  const [percent, setPercent] = React.useState(0)
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (setLoading) setLoading(false)
    }, 2000)
    return () => timeout &&  clearInterval(timeout) 
  }, [setLoading])

  return (
    <>
      <Spin indicator spinning={loading} percent={percent} fullscreen style={{ zIndex: 100000 }} size='large' />
    </>
  )
}
export default SpinnerVersion2
