import React, { useEffect, useState } from 'react'
import { Card, Container, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'

//redux
import { useDispatch } from 'react-redux'
import withRouter from 'components/Common/withRouter'

//Import Breadcrumb
import Breadcrumb from '../../components/Common/Breadcrumb'
import { ManageProgramVN } from 'constants/vn'

// styles
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { getProgramDetail } from 'store/actions'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import ConfirmModal from 'components/Common/ConfirmModal'
import {
  getChannelAPI,
  getParallelProgramListAPI,
  getPortfolioAllAPI,
  getProgrammingVersion,
  getRankAllAPI,
  patchStatusProgramAPI,
  postProgramAPI,
  updateProgramAPI
} from 'helpers/backend_helper'
import { toast } from 'react-toastify'
import { debounce, isEqual } from 'lodash'
import { Form, Spin } from 'antd'
import classNames from 'classnames'
import ConfigTypeTab from './components/ConfigTypeTab'
import ConditionTab from './components/ConditionTab'
import { convertData, mapsData } from '../ManageProgram/ProgramDTO'
import SpinnerVersion2 from 'components/Common/SpinnerVersion2'

const ProgramDetailCom = ({ isClose, programmingVersionId }) => {
  const [form] = Form.useForm()
  const watchForm = Form.useWatch([], form)

  const selectProgramDetailState = (state) => state.ProgramReducer
  const ProgramDetailProperties = createSelector(selectProgramDetailState, (program) => ({
    programDetail: program.programDetail
  }))
  const { programDetail } = useSelector(ProgramDetailProperties)
  // const [isLoading, setLoading] = useState(false)

  const [showReject, setShowReject] = useState(false)
  const [submittable, setSubmittable] = useState(true)
  const [initData, setInitData] = useState({})
  const [industry, setIndustry] = useState([])
  const [industrySector, setIndustrysector] = useState([])
  const [category, setCategory] = useState([])
  const [model, setModel] = useState([])
  const [channel, setChannel] = useState([])
  const [rank, setRank] = useState([])
  const [activeTab, toggleTab] = useState('1')
  const [loading, setLoading] = useState(false)
  const [showSubmitCancel, setShowSubmitCancel] = useState(false)
  const [parallelProgram, setParallelProgram] = useState([])

  const closePopup = () => {
    setShowChangePassword(false)
    setShowLockAccount(false)
    setShowReject(false)
  }

  const type = 'view'

  const changeStatus = async (status) => {
    try {
      if (type !== 'view') {
        if (type === 'create') {
          const res = await postProgramAPI(convertData(watchForm))
          const resStatus = await patchStatusProgramAPI({ id: res?.data?.id, status })
          if (resStatus) {
            toast(
              `${
                status == 'pending'
                  ? 'Chương trình được gửi phê duyệt thành công'
                  : status == 'accepted'
                  ? 'Chương trình đã được duyệt thành công'
                  : 'Chương trình đã được từ chối thành công'
              }`,
              { type: 'success' }
            )
            navigate('/manage-program')
          }
        } else {
          // await updateProgramAPI({
          //  id: params?.id,
          //  ...convertData(watchForm)
          // })
          // const resStatus = await patchStatusProgramAPI({ id: params?.id, status })
          if (resStatus) {
            toast(
              `${
                status == 'pending'
                  ? 'Chương trình được gửi phê duyệt thành công'
                  : status == 'accepted'
                  ? 'Chương trình đã được duyệt thành công'
                  : 'Chương trình đã được từ chối thành công'
              }`,
              { type: 'success' }
            )
            navigate('/manage-program')
          }
        }
      } else {
        if (resStatus) {
          toast(
            `${
              status == 'pending'
                ? 'Chương trình được gửi phê duyệt thành công'
                : status == 'accepted'
                ? 'Chương trình đã được duyệt thành công'
                : 'Chương trình đã được từ chối thành công'
            }`,
            { type: 'success' }
          )
          navigate('/manage-program')
        }
      }
    } catch (error) {
      toast(error?.response?.data?.message ?? 'Có lỗi xảy ra!', { type: 'error' })
    }
  }

  const getDataOptions = async () => {
    try {
      setLoading(true)
      const resIndustry = await getPortfolioAllAPI({ type: 'industry' })
      setIndustry(resIndustry?.data)
      const resIndustrysector = await getPortfolioAllAPI({ type: 'industrySector' })
      setIndustrysector(resIndustrysector?.data)
      const resCategory = await getPortfolioAllAPI({ type: 'category' })
      setCategory(resCategory?.data)
      const resModel = await getPortfolioAllAPI({ type: 'model' })
      setModel(resModel?.data)
      const resChannel = await getChannelAPI()
      setChannel(resChannel?.data)
      const resRank = await getRankAllAPI()
      setRank(resRank?.data)
      const resParallelProgram = await getParallelProgramListAPI({ status: 'active' })
      setParallelProgram(resParallelProgram?.data)

      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getDataOptions()
  }, [])
  useEffect(() => {
    getProgrammingVersionAPI()
  }, [programmingVersionId])

  const getProgrammingVersionAPI = async () => {
    setLoading(true)

    if (programmingVersionId) {
      const program = await getProgrammingVersion({ id: programmingVersionId })
      console.log(program)
      // setProgrammingVersion(program.data.programmingData)
      setInitData(mapsData(program.data.programmingData))
      form.setFieldsValue(mapsData(program.data.programmingData))
      setLoading(false)
    }
  }

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true)
      },
      (errors) => {
        if (errors?.errorFields?.length > 0) {
          setSubmittable(false)
        }
      }
    )
  }, [watchForm])

  return (
    <React.Fragment>
      <SpinnerVersion2 loading={loading} />
      <Container fluid>
        <Card>
          <ul className='nav nav-tabs nav-tabs-custom justify-content-center pt-2' role='tablist'>
            <NavItem>
              <NavLink
                to='#'
                className={classNames([
                  {
                    active: activeTab === '1'
                  }
                ])}
                onClick={() => {
                  toggleTab('1')
                }}
              >
                {'Điều kiện'}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to='#'
                className={classNames([
                  {
                    active: activeTab === '2'
                  }
                ])}
                onClick={() => {
                  toggleTab('2')
                }}
              >
                {'Cơ cấu thưởng'}
              </NavLink>
            </NavItem>
          </ul>
          <Form
            initialValues={{
              applyParallel: false,
              applyTime: 'range_time',
              revenues: [{}]
            }}
            autoComplete='off'
            form={form}
            name='programForm'
          >
            <TabContent className='p-4' activeTab={activeTab}>
              <TabPane tabId='1'>
                <ConditionTab
                  watchForm={watchForm}
                  form={form}
                  industry={industry}
                  industrySector={industrySector}
                  category={category}
                  model={model}
                  channel={channel}
                  rank={rank}
                  type={type}
                  setLoading={setLoading}
                  parallelProgram={parallelProgram}
                />
              </TabPane>
              <TabPane tabId='2'>
                <ConfigTypeTab
                  watchForm={watchForm}
                  form={form}
                  industry={industry}
                  industrySector={industrySector}
                  category={category}
                  model={model}
                  type={type}
                  programDetail={programDetail}
                  parallelProgram={parallelProgram}
                />
              </TabPane>
            </TabContent>
          </Form>
        </Card>
        <div className='d-flex justify-content-end mb-4'>
          <button style={{ width: 120 }} onClick={isClose} className='btn btn-outline-primary me-2'>
            {type === 'view' ? ManageProgramVN.close : ManageProgramVN.cancel}
          </button>
          {type === 'view' && programDetail?.status === 'draft' && (
            <button
              style={{ width: 120 }}
              onClick={debounce(() => {
                // navigate(`/manage-program/edit/${params?.id}`)
              }, 500)}
              className='btn btn-primary '
            >
              {ManageProgramVN.edit}
            </button>
          )}

          {(((programDetail?.status === 'draft' ||
            programDetail?.status === 'active' ||
            programDetail?.status === 'rejected') &&
            type === 'edit') ||
            type === 'create') && (
            <>
              <button
                style={{ width: 120 }}
                type='button'
                disabled={
                  watchForm?.type == undefined &&
                  watchForm?.name == undefined &&
                  watchForm?.applyDates == undefined &&
                  watchForm?.industry == undefined &&
                  watchForm?.channel == undefined &&
                  watchForm?.warehouseZone == undefined &&
                  watchForm?.warehouseDate == undefined &&
                  watchForm?.applyClientType == undefined &&
                  watchForm?.zone == undefined &&
                  watchForm?.parallelProgram == undefined
                }
                className='btn btn-primary me-2'
                onClick={debounce(() => {}, 500)}
              >
                {ManageProgramVN.save}
              </button>
              {programDetail?.status !== 'active' && (
                <button
                  style={{ width: 120 }}
                  disabled={!submittable}
                  type='button'
                  className='btn btn-primary '
                  onClick={debounce(() => {
                    changeStatus('pending')
                  }, 500)}
                >
                  {ManageProgramVN.send}
                </button>
              )}
            </>
          )}
          {programDetail?.status === 'pending' && (
            <>
              <button
                style={{ width: 120 }}
                type='button'
                className='btn btn-danger me-2'
                onClick={debounce(() => {
                  changeStatus('rejected')
                }, 500)}
              >
                {ManageProgramVN.reject}
              </button>
              <button
                style={{ width: 120 }}
                type='button'
                className='btn btn-primary '
                onClick={debounce(() => {
                  changeStatus('accepted')
                }, 500)}
              >
                {ManageProgramVN.accept}
              </button>
            </>
          )}
        </div>
      </Container>
    </React.Fragment>
  )
}

export default ProgramDetailCom
