import {
  ACCUMULATED_MANAGEMENT_CODE,
  ACTIVATED_MANAGEMENT_CODE,
  ADMIN_ACCOUNT_MANAGEMENT_CODE,
  ANNOUNCE_MANAGEMENT_CODE,
  AREA_MANAGEMENT_CODE,
  BANK_MANAGEMENT_CODE,
  BANNER_MANAGEMENT_CODE,
  CATEGORY_MANAGEMENT_CODE,
  CONTACT_MANAGEMENT_CODE,
  CUSTOMER_CARE,
  CUSTOMER_MANAGEMENT_CODE,
  DEPARTMENT_MANAGEMENT_CODE,
  HISTORY_USER_REWARD_MANAGEMENT_CODE,
  POINT_HISTORY_MANAGEMENT_CODE,
  PRODUCT_ACTIVATION_MANAGEMENT_CODE,
  PRODUCT_MANAGEMENT_CODE,
  PROGRAMING_MANAGEMENT_CODE,
  RANK_CONFIG_MANAGEMENT_CODE,
  RANK_HISTORY_MANAGEMENT_CODE,
  RANK_MANAGEMENT_CODE,
  REPORT_BY_PRODUCT_CODE,
  REPORT_RANK_ACCOUNT_MANAGEMENT_CODE,
  REPORT_RECONCILE_ACCOUNTING_CODE,
  REPORT_RECONCILE_CODE,
  REPORT_SMS_MANAGEMENT_CODE,
  REPORT_STATISTIC_MANAGEMENT_CODE,
  REPORT_V2,
  ROLE_MANAGEMENT_CODE,
  SYSTEM_NOTIFICATION_MANAGEMENT_CODE,
  TECHNICIAN_ACCOUNT_MANAGEMENT_CODE,
  USER_ACCOUNT_MANAGEMENT_CODE
} from 'constants/permission-code'
import { SidebarVN } from 'constants/vn'

const menuList = [
  {
    name: SidebarVN.manageShop,
    link: '/manage-shop',
    icon: 'bx bx-store',
    permissionCode: USER_ACCOUNT_MANAGEMENT_CODE.ADMIN_SEARCH_LIST_USER
  },
  {
    name: SidebarVN.manageKTV,
    link: '/manage-ktv',
    icon: 'bx bxs-user-rectangle',
    permissionCode: TECHNICIAN_ACCOUNT_MANAGEMENT_CODE.TECHNICIAN_SEARCH_LIST_USER
  },
  {
    name: SidebarVN.manageProduct,
    icon: 'mdi mdi-package-variant-closed',
    permissionCode: [
      CATEGORY_MANAGEMENT_CODE.PRODUCT_SEARCH_CATEGORY,
      PRODUCT_MANAGEMENT_CODE.PRODUCT_SEARCH_PRODUCT,
      PRODUCT_MANAGEMENT_CODE.PRODUCT_AUDIT_LOG
    ],
    subMenu: [
      {
        name: SidebarVN.managePortfolio,
        link: '/manage-portfolio',
        permissionCode: CATEGORY_MANAGEMENT_CODE.PRODUCT_SEARCH_CATEGORY
      },
      {
        name: SidebarVN.manageProduct,
        link: '/manage-product',
        permissionCode: PRODUCT_MANAGEMENT_CODE.PRODUCT_SEARCH_PRODUCT
      },
      {
        name: SidebarVN.manageProductActivation,
        link: '/manage-product-activation',
        permissionCode: PRODUCT_MANAGEMENT_CODE.PRODUCT_SEARCH_PRODUCT
      },
      {
        name: SidebarVN.auditlog,
        link: '/audit-log',
        permissionCode: PRODUCT_MANAGEMENT_CODE.PRODUCT_AUDIT_LOG
      }
    ]
  },
  {
    name: SidebarVN.manageUserCMS,
    icon: 'bx bx-user-circle',
    permissionCode: [
      DEPARTMENT_MANAGEMENT_CODE.ADMIN_SEARCH_DEPARTMENT,
      ROLE_MANAGEMENT_CODE.ADMIN_SEARCH_ROLE,
      ADMIN_ACCOUNT_MANAGEMENT_CODE.ADMIN_SEARCH_LIST_ADMIN_ACCOUNT
    ],
    subMenu: [
      {
        name: SidebarVN.manageUnit,
        link: '/manage-unit',
        permissionCode: DEPARTMENT_MANAGEMENT_CODE.ADMIN_SEARCH_DEPARTMENT
      },
      {
        name: SidebarVN.managePermission,
        link: '/manage-permission',
        permissionCode: ROLE_MANAGEMENT_CODE.ADMIN_SEARCH_ROLE
      },
      {
        name: SidebarVN.manageUser,
        link: '/manage-user',
        permissionCode: ADMIN_ACCOUNT_MANAGEMENT_CODE.ADMIN_SEARCH_LIST_ADMIN_ACCOUNT
      }
    ]
  },
  {
    name: SidebarVN.manageProgram,
    icon: 'bx bx-aperture',
    link: '/manage-program',
    permissionCode: PROGRAMING_MANAGEMENT_CODE.ADMIN_SEARCH_PROGRAMMING
  },
  {
    name: SidebarVN.manageLocality,
    icon: 'mdi mdi-google-maps',
    link: '/manage-locality',
    permissionCode: AREA_MANAGEMENT_CODE.ADMIN_GET_LIST_AREA
  },
  {
    name: SidebarVN.manageClass,
    icon: 'mdi mdi-medal',
    permissionCode: [
      RANK_MANAGEMENT_CODE.RANKS_SEARCH_RANK,
      RANK_CONFIG_MANAGEMENT_CODE.RANKS_SEARCH_RANK_CONFIG,
      POINT_HISTORY_MANAGEMENT_CODE.POINT_HISTORY_MANAGEMENT
    ],
    subMenu: [
      {
        name: SidebarVN.manageAccountClass,
        link: '/manage-account-class',
        permissionCode: RANK_MANAGEMENT_CODE.RANKS_SEARCH_RANK
      },
      {
        name: SidebarVN.manageClassStructure,
        link: '/manage-class-structure',
        permissionCode: RANK_CONFIG_MANAGEMENT_CODE.RANKS_SEARCH_RANK_CONFIG
      },
      {
        name: SidebarVN.managePointConfig,
        link: '/manage-point-config',
        permissionCode: RANK_MANAGEMENT_CODE.RANKS_SEARCH_RANK
      },
      {
        name: SidebarVN.managePointHistory,
        link: '/manage-point-history',
        permissionCode: POINT_HISTORY_MANAGEMENT_CODE.POINT_HISTORY_MANAGEMENT
      },
      {
        name: 'Lịch sử thăng hạng',
        link: '/manage-rank-history',
        permissionCode: RANK_HISTORY_MANAGEMENT_CODE.RANK_HISTORY
      }
    ]
  },
  {
    link: '/manage-accumulation',
    icon: 'mdi mdi-elevation-rise',
    name: SidebarVN.manageAccumulation,
    permissionCode: ACCUMULATED_MANAGEMENT_CODE.ADMIN_SEARCH_ACCUMULATED
  },
  {
    link: '/manage-activation',
    icon: 'mdi mdi-lock-check-outline',
    name: SidebarVN.manageActivated,
    permissionCode: ACTIVATED_MANAGEMENT_CODE.ADMIN_SEARCH_ACTIVATED
  },
  {
    link: '/manage-history',
    icon: 'mdi mdi-history',
    name: SidebarVN.manageHistory,
    permissionCode: HISTORY_USER_REWARD_MANAGEMENT_CODE.ACTIVATION_SEARCH_HISTORY_CHANGE
  },

  {
    link: '/manage-guest',
    icon: 'mdi mdi-cart-check',
    name: SidebarVN.manageGuest,
    permissionCode: CUSTOMER_MANAGEMENT_CODE.ADMIN_SEARCH_CUSTOMER
  },
  {
    link: '/manage-content',
    icon: 'mdi mdi-bookshelf',
    name: SidebarVN.manageContent,
    permissionCode: ANNOUNCE_MANAGEMENT_CODE.ADMIN_SEARCH_ANNOUNCE
  },
  {
    link: '/manage-banner',
    icon: 'mdi mdi-bullhorn',
    name: SidebarVN.manageBaner,
    permissionCode: BANNER_MANAGEMENT_CODE.ADMIN_SEARCH_BANNER
  },
  {
    link: '/manage-bank',
    icon: 'mdi mdi-bank',
    name: SidebarVN.manageBank,
    permissionCode: BANK_MANAGEMENT_CODE.BANK_SEARCH_BANK
  },
  {
    link: '/manage-contacts',
    icon: 'mdi mdi-contacts-outline',
    name: SidebarVN.manageContact,
    permissionCode: CONTACT_MANAGEMENT_CODE.CONTACT_SEARCH_CONTACT
  },
  {
    link: '/manage-sms-brandname',
    icon: 'mdi mdi-message-processing',
    name: SidebarVN.manageSMSBrandname,
    permissionCode: REPORT_SMS_MANAGEMENT_CODE.REPORT_SMS_STATISTIC_SEARCH
  },
  {
    link: '/manage-notification',
    icon: 'mdi mdi-bell',
    name: SidebarVN.manageNotification,
    permissionCode: SYSTEM_NOTIFICATION_MANAGEMENT_CODE.SYSTEM_NOTIFICATION_SEARCH
  },
  // {
  //   name: SidebarVN.reportTemporaryReconciliation,
  //   icon: 'mdi mdi-book-open-page-variant',
  //   permissionCode: [
  //     REPORT_BY_PRODUCT_CODE.REPORT_SEARCH_BY_PRODUCT,
  //     REPORT_RECONCILE_CODE.REPORT_SEACH_BY_PROGRAMMING
  //   ],
  //   subMenu: [
  //     {
  //       name: SidebarVN.remportProductBonusTemp,
  //       link: '/product-bonus-temp-list',
  //       permissionCode: REPORT_BY_PRODUCT_CODE.REPORT_SEARCH_BY_PRODUCT
  //     },
  //     {
  //       name: SidebarVN.remportProgramBonusTemp,
  //       link: '/program-bonus-temp-list',
  //       permissionCode: REPORT_RECONCILE_CODE.REPORT_SEACH_BY_PROGRAMMING
  //     }
  //   ]
  // },
  {
    link: '/reward-verify-v2',
    icon: 'mdi mdi-cash-multiple',
    name: 'Log kế toán V2',
    permissionCode: REPORT_RECONCILE_ACCOUNTING_CODE.REPORT_ACCOUNTING_SEARCH_BY_PROGRAMMING,
    subMenu: [
      {
        name: 'Log kế toán tích lũy',
        link: '/reward-verify-accumulation',
        permissionCode: REPORT_BY_PRODUCT_CODE.REPORT_SEARCH_BY_PRODUCT
      },
      {
        name: 'Log kế toán kích hoạt',
        link: '/reward-verify-activation',
        permissionCode: REPORT_RECONCILE_CODE.REPORT_SEACH_BY_PROGRAMMING
      },
      {
        name: 'Log kế toán trưng baỳ',
        link: '/reward-verify-display',
        permissionCode: REPORT_RECONCILE_CODE.REPORT_SEACH_BY_PROGRAMMING
      }
    ]
  },
  // {
  //   link: '/reward-verify',
  //   icon: 'mdi mdi-cash-multiple',
  //   name: 'Log kế toán',
  //   permissionCode: REPORT_RECONCILE_ACCOUNTING_CODE.REPORT_ACCOUNTING_SEARCH_BY_PROGRAMMING
  // },
  // {
  //   link: '/user-statistic',
  //   icon: 'mdi mdi-account-search',
  //   name: SidebarVN.userStatistic,
  //   permissionCode: REPORT_STATISTIC_MANAGEMENT_CODE.REPORT_STATISTIC_USER_ACTIVE_BY_AREAR
  // },
  {
    link: '/account-rank',
    icon: 'mdi mdi-account-star',
    name: SidebarVN.accountRank,
    permissionCode: REPORT_RANK_ACCOUNT_MANAGEMENT_CODE.REPORT_ACCOUNT_RANK_TYPE_ACCUMULATION
  },
  {
    link: '/customer-care',
    icon: 'mdi mdi-heart-box',
    name: 'Chăm sóc khách hàng',
    permissionCode: CUSTOMER_CARE.CUSTOMER_CARE
  },
  {
    link: '/programming-accumulation-report',
    icon: 'mdi mdi-warehouse',
    name: 'Báo cáo tích lũy',
    permissionCode: REPORT_V2.PROGRAMMING_ACCUMULATION_REPORT
  },
  {
    link: '/programming-activation-report',
    icon: 'mdi mdi-warehouse',
    name: 'Báo cáo kích hoạt',
    permissionCode: REPORT_V2.PROGRAMMING_ACTIVATION_REPORT
  },
  {
    link: '/programming-display-report',
    icon: 'mdi mdi-warehouse',
    name: 'Báo cáo trưng bày',
    permissionCode: REPORT_V2.PROGRAMMING_DISPLAY_REPORT
  },
  {
    link: '/account-activity',
    icon: 'mdi mdi-account-circle',
    name: 'Hoạt động tài khoản',
    permissionCode: REPORT_V2.ACCOUNT_ACTIVITY
  },
  {
    link: '/track-account',
    icon: 'mdi mdi-chart-bar',
    name: 'Theo dõi số lượng tài khoản',
    permissionCode: REPORT_V2.TRACK_ACCOUNT
  },
  {
    link: '/account-statistics',
    icon: 'mdi mdi-chart-line',
    name: 'Thống kê tài khoản',
    subMenu: [
      {
        name: 'Thống kê tài khoản tích lũy',
        link: '/account-statistics-accumulation',
        permissionCode: REPORT_V2.ACCOUNT_STATISTICS_ACCUMULATION
      },
      {
        name: 'Thống kê tài khoản kích hoạt',
        link: '/account-statistics-activation',
        permissionCode: REPORT_V2.ACCOUNT_STATISTICS_ACTIVATION
      }
    ]
  }

  // {
  //   link: '/history-activation-accumulation',
  //   icon: 'mdi mdi-book-edit',
  //   name: SidebarVN.historyActivationAccumulation
  //   // permissionCode: BANNER_MANAGEMENT_CODE.ADMIN_SEARCH_BANNER
  // },
  // {
  //   link: '/manage-contacts',
  //   icon: 'mdi mdi-book-edit',
  //   name: SidebarVN.manageContact
  //   // permissionCode: BANNER_MANAGEMENT_CODE.ADMIN_SEARCH_BANNER
  // }
]

export default menuList
