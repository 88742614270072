import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

//import components

import { getLocalityList, setTypePopupLocality, getLocalityDetail, getLocalityDetailSuccess } from 'store/actions'

import { Code, Name, GeographicalLevel, Domain, Zone, City, District } from './ManageLocalityCol'

//redux
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

import { Col, Row, UncontrolledTooltip, Card, CardBody } from 'reactstrap'
import Spinners from 'components/Common/Spinner'
import { toast } from 'react-toastify'
import TableContainer from 'components/Common/TableContainer'
import { LocalityVN } from 'constants/vn'
import ConfirmModal from 'components/Common/ConfirmModal'
import { deleteLocalityAPI } from 'helpers/backend_helper'
import AddEditLocality from './components/AddEditLocality'
import { AREA_MANAGEMENT_CODE, SCREEN_CODE } from 'constants/permission-code'
import PermissionWrapper from 'components/Common/PermissionWrapper'
import { getStorage, setStorage } from 'helpers/global_helper'

function ManageLocality() {
  //meta title
  document.title = 'Manage Locality CMS List | Skote - React Admin & Dashboard Template'
  const screenCode = useMemo(() => SCREEN_CODE.AREA_MANAGEMENT, [])

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const selectLocalitysState = (state) => state.LocalityReducer
  const LocalitysProperties = createSelector(selectLocalitysState, (localityReducer) => ({
    localitys: localityReducer.localitys,
    loading: localityReducer.loading,
    total: localityReducer.total
  }))

  const { localitys, loading, total } = useSelector(LocalitysProperties)

  const [isLoading, setLoading] = useState(loading)
  const [onDelete, setOnDelete] = useState(false)
  const [selected, setSelected] = useState()
  const [filter, setFilter] = useState()
  const [openModal, setOpenModal] = useState(false)
  const [page, setPage] = useState(1)

  useEffect(() => {
    const filterManageLocality = getStorage('filterManageLocality')
    dispatch(getLocalityList({ ...filterManageLocality }))
  }, [dispatch])

  const columns = useMemo(
    () => [
      {
        Header: LocalityVN.table.code,
        accessor: 'code',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Code {...cellProps} />
        }
      },
      {
        Header: LocalityVN.table.name,
        accessor: 'name',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />
        }
      },
      {
        Header: LocalityVN.table.domain,
        accessor: 'zone',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Domain {...cellProps} />
        }
      },
      {
        Header: LocalityVN.table.zone,
        accessor: 'subZone',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Zone {...cellProps} />
        }
      },
      {
        Header: LocalityVN.table.city,
        accessor: 'province',
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <City {...cellProps} />
        }
      },
      {
        Header: LocalityVN.table.district,
        accessor: 'district',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <District {...cellProps} />
        }
      },
      {
        Header: LocalityVN.table.action,
        accessor: 'action',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return (
            <ul className='list-unstyled hstack gap-1 mb-0'>
              <PermissionWrapper screenCode={screenCode} code={AREA_MANAGEMENT_CODE.ADMIN_DETAIL_AREA}>
                <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                  <Link
                    to={`#`}
                    onClick={() => {
                      setOpenModal(true)
                      dispatch(setTypePopupLocality('view'))
                      dispatch(getLocalityDetail({ id: cellProps.row.original.id, type: cellProps.row.original.type }))
                    }}
                    className='btn btn-sm btn-soft-primary'
                    id={`viewtooltip-${cellProps.row.original.id}`}
                  >
                    <i className='mdi mdi-eye-outline' />
                    <UncontrolledTooltip
                      trigger='hover'
                      placement='top'
                      target={`viewtooltip-${cellProps.row.original.id}`}
                    >
                      Xem
                    </UncontrolledTooltip>
                  </Link>
                </li>
              </PermissionWrapper>
              <PermissionWrapper
                screenCode={screenCode}
                code={[AREA_MANAGEMENT_CODE.ADMIN_DETAIL_AREA, AREA_MANAGEMENT_CODE.ADMIN_UPDATE_AREA]}
              >
                <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                  <Link
                    to={`#`}
                    onClick={() => {
                      setOpenModal(true)
                      dispatch(setTypePopupLocality('edit'))
                      dispatch(getLocalityDetail({ id: cellProps.row.original.id, type: cellProps.row.original.type }))
                    }}
                    className='btn btn-sm btn-soft-info'
                    id={`edittooltip-${cellProps.row.original.id}`}
                  >
                    <i className='mdi mdi-pencil-outline' />
                    <UncontrolledTooltip
                      trigger='hover'
                      placement='top'
                      target={`edittooltip-${cellProps.row.original.id}`}
                    >
                      Sửa
                    </UncontrolledTooltip>
                  </Link>
                </li>
              </PermissionWrapper>
              <PermissionWrapper screenCode={screenCode} code={AREA_MANAGEMENT_CODE.ADMIN_DELETE_AREA}>
                <li>
                  <button
                    className={`btn btn-sm btn-soft-danger ${!!cellProps.row.original?.extTransId && 'opacity-50'}`}
                    onClick={() => {
                      setOnDelete(true)
                      setSelected(cellProps.row.original)
                    }}
                    id={`deletetooltip-${cellProps.row.original.code}`}
                  >
                    <i className='mdi mdi-delete-outline' />
                    <UncontrolledTooltip
                      trigger='hover'
                      placement='top'
                      target={`deletetooltip-${cellProps.row.original.code}`}
                    >
                      Xóa
                    </UncontrolledTooltip>
                  </button>
                </li>
              </PermissionWrapper>
            </ul>
          )
        }
      }
    ],
    []
  )

  const setGlobalFilterUser = async (data) => {
    setStorage('filterManageLocality', data)
    setFilter(data)
    dispatch(getLocalityList(data))
    setPage(1)
  }

  const onClickDelete = async () => {
    try {
      const res = await deleteLocalityAPI({ id: selected.id, type: selected.type })
      if (res) {
        toast('Xoá địa bàn thành công', { type: 'success' })
        dispatch(getLocalityList(filter))
        setOnDelete(false)
      }
    } catch (error) {
      toast.error(error.response.data.message, { type: 'error' })
    }
  }

  const goToPage = (page) => {
    dispatch(getLocalityList({ ...filter, page }))
    setFilter({ ...filter, page })
    setPage(page)
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <h4 className=' mb-sm-0 font-size-18 pb-4'>{LocalityVN.title}</h4>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody className='border-bottom'>
                    <div className='d-flex align-items-center'>
                      <h5 className='mb-0 card-title flex-grow-1'>{LocalityVN.list}</h5>
                      <div className='flex-shrink-0'>
                        <PermissionWrapper screenCode={screenCode} code={AREA_MANAGEMENT_CODE.ADMIN_CREATE_AREA}>
                          <button
                            onClick={() => {
                              setOpenModal(true)
                              dispatch(setTypePopupLocality('create'))
                            }}
                            className='btn btn-primary'
                          >
                            <i className='mdi mdi-plus me-1' />
                            {LocalityVN.add}
                          </button>
                        </PermissionWrapper>
                      </div>
                    </div>
                  </CardBody>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={localitys}
                      isGlobalFilter={true}
                      setGlobalFilterUser={setGlobalFilterUser}
                      isManageLocalityGlobalFilter={true}
                      isPagination={true}
                      iscustomPageSizeOptions={false}
                      isShowingPageLength={false}
                      customPageSize={10}
                      placeholderSearch={LocalityVN.filters.search.placeholder}
                      tableClass='table-bordered align-middle nowrap mt-2'
                      paginationDiv='col-sm-12 col-md-7'
                      pagination='pagination justify-content-end pagination-rounded'
                      useAPIChangePaging
                      currentPage={page}
                      gotoPageCustom={goToPage}
                      total={total}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      {openModal && (
        <AddEditLocality
          show={openModal}
          filter={filter}
          onCloseClick={() => {
            setOpenModal(false)
            dispatch(getLocalityDetailSuccess(null))
          }}
          setPage={setPage}
        />
      )}
      <ConfirmModal
        show={onDelete}
        icon={
          <div className='avatar-title rounded-circle bg-danger text-light bg-opacity-60 font-size-20 rounded-3'>
            <i className='mdi mdi-delete-outline'></i>
          </div>
        }
        onConfirmClick={onClickDelete}
        onCloseClick={() => setOnDelete(false)}
        title={`Xác nhận xóa địa bàn`}
        description={`Bạn có chắc chắn muốn xóa địa bàn?`}
      />
    </React.Fragment>
  )
}

export default ManageLocality
