import React, { useEffect, useMemo, useState } from 'react'
import { getProgramBonusTempList as onGetManageContent } from 'store/actions'

import {
  ContentName,
  ReportCode,
  TotalUser} from '../ProgramBonusTemp/ManageContentCol'
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'
import { Col, Row, Card, CardBody } from 'reactstrap'
import { toast } from 'react-toastify'
import TableContainer from 'components/Common/TableContainer'
import ConfirmModal from 'components/Common/ConfirmModal'
import {
  deleteContentAPI,
  exportByProgrammingFetcherAPI,
  exportTrackAccountAPI,
  getTrackAccountActivityAPI,
  patchStatusContentAPI
} from 'helpers/backend_helper'
import moment from 'moment'
import dayjs from 'dayjs'
import SpinnerVersion2 from 'components/Common/SpinnerVersion2'

function TrackAccount({ programmingType, title }) {
  //meta title
  document.title = 'Content List | HPG'

  const selectContentsState = (state) => state.ProgramBonusTempReducer
  const ContentsProperties = createSelector(selectContentsState, (contentReducer) => ({
    contents: contentReducer.contents,
    loading: contentReducer.loading,
    total: contentReducer.total
  }))

  const { contents, loading, total } = useSelector(ContentsProperties)

  const selectProfileState = (state) => state.Profile
  const ProfileProperties = createSelector(selectProfileState, (profile) => ({
    profile: profile.profile
  }))
  const { profile } = useSelector(ProfileProperties)
  const now = useMemo(() => dayjs())
  const [isLoading, setLoading] = useState(loading)
  const [filter, setFilter] = useState({ start_date: now.subtract(1, 'year'), end_date: now })
  const [page, setPage] = useState(1)
  const [pagination, setPagination] = useState({
    currentPage: page,
    pageSize: 10,
    totalItems: 0
  })

  const [datasource, setDatasource] = useState([])

  const onInit = async (payload) => {
    setLoading(true)
    payload = payload ? payload : {}
    if (!payload.start_date || !payload.end_date) {
      const now = dayjs()
      payload = { ...payload, start_date: now.subtract(1, 'year'), end_date: now }
      setFilter(payload)
    }
    const x = await getTrackAccountActivityAPI(payload)
    setDatasource(x.data)
    setPagination(x.meta)
    setLoading(false)
  }

  useEffect(() => {
    onInit(filter)
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: 'Thời gian ghi nhận',
        accessor: 'month',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: ({ value }) => {
          if (!value) return null
          const lastDay = dayjs(value, 'YYYY-MM').endOf('month')
          const today = dayjs()
          if (lastDay.isAfter(today)) return today.format('DD/MM/YYYY')
          return lastDay.format('DD/MM/YYYY')
        }
      },
      {
        Header: 'Miền',
        accessor: 'zoneName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ContentName {...cellProps} />
        }
      },
      {
        Header: 'Số lượng tài khoản cửa hàng ',
        accessor: 'cuahang',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ContentName {...cellProps} />
        }
      },
      {
        Header: 'Số lượng tài khoản kĩ thuật viên',
        accessor: 'kythuatvien',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ContentName {...cellProps} />
        }
      },
      {
        Header: 'Tổng các tài khoản',
        accessor: 'total',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <TotalUser {...cellProps} />
        }
      }
    ],
    [filter, page, contents]
  )

  const handleSort = (sortBy) => {
  }

  const setGlobalFilterUser = async (data) => {
    data.start_date = data.start_date?.startOf('day')?.toDate()
    data.end_date = data.end_date?.endOf('day')?.toDate()

    // setStorage('filterProgramBonusTempList', data)

    setFilter({
      ...data,
    })
    setPagination({ ...pagination, currentPage: 1 })
    onInit({ ...data, page: 1 })
    setPage(1)
  }

  const goToPage = (page) => {
    setPage(page)
    onInit({ ...filter, page: page })
    // dispatch(onGetManageContent({ ...filter, page: page }))
  }

  const handleClickExport = async () => {
    setLoading(true)
    try {
      const response = await exportTrackAccountAPI({ ...filter })
      const data = response.headers.get('Content-Type')
      if (data !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        toast('Hệ thống đang xử lý. Tệp của bạn đã được gửi tới email ' + profile?.email + ' trong vài phút', {
          type: 'success'
        })
      } else {
        try {
          const blob = await response.blob() // Fetch the blob
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Theo dõi số lượng tài khoản app theo tháng ${moment().format('yyMMDD')}.xlsx`) // Tên file
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          toast('Export file thành công!', { type: 'success' })
        } catch (err) {
          console.log('err', err)
        }
      }
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <h4 className=' mb-sm-0 font-size-18 pb-4'>{title || 'Đối soát thưởng'}</h4>
          <SpinnerVersion2 loading={isLoading} />
          <Row>
            <Col lg='12'>
              <Card>
                <CardBody className='border-bottom'>
                  <div className='d-flex align-items-center'>
                    <h5 className='mb-0 card-title flex-grow-1'>{''}</h5>
                    <div className='flex-shrink-0'>
                      <button onClick={() => handleClickExport()} className='btn btn-primary me-2 pe-4'>
                        <i className='mdi mdi-database-export me-1 ms-2' />
                        {'Export'}
                      </button>
                    </div>
                  </div>
                </CardBody>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={datasource}
                    isGlobalFilter={true}
                    isSearchInput={false}
                    setGlobalFilterUser={setGlobalFilterUser}
                    isTrackAccountTempFilter={true}
                    isPagination={true}
                    iscustomPageSizeOptions={false}
                    isShowingPageLength={false}
                    customPageSize={10}
                    tableClass='table-bordered align-middle nowrap mt-2'
                    paginationDiv='col-sm-12 col-md-7'
                    pagination='pagination justify-content-end pagination-rounded'
                    setSortBy={handleSort}
                    useAPIChangePaging
                    currentPage={pagination.currentPage}
                    gotoPageCustom={goToPage}
                    total={pagination.totalItems}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TrackAccount
