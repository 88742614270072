import React, { useEffect, useMemo, useState } from 'react'
import { Col, Label, Row } from 'reactstrap'

//flatpickr
import 'flatpickr/dist/themes/material_blue.css'
import { CommonVN, ManageContentVN, ProductVN } from 'constants/vn'
import { FormSelect } from './form-select/FormSelect'
import { AREA_TYPE } from 'pages/ManageLocality/constants'
import { getZoneAPI, getAreaManagementOptionsAPI } from 'helpers/backend_helper'
import { DatePicker } from 'antd'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { TypeContentOptions } from 'constants/constants'
import { FormDateRange } from './form-date-range/FormDateRange'
const { RangePicker } = DatePicker
const ContentGlobalFilter = ({ setGlobalFilter, search, setSearch }) => {
  const [filter, setFilter] = useState({})
  const [selectedZone, setSelectedZone] = useState()
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    zone: app.zone,
    subzone: app.subzone,
    province: app.province,
    district: app.district,
    ward: app.ward,
    myLocationManagement: app.myLocationManagement
  }))
  const { zone, subzone, province, district, ward, myLocationManagement } = useSelector(LayoutProperties)

  // useEffect(() => {
  //   handleGetAllZone()
  // }, [])

  // useEffect(() => {
  //   if (selectedZone) {
  //     handleGetSubZone()
  //   }
  // }, [selectedZone])

  const handleSelect = (value, key) => {
    setFilter((e) => ({ ...e, [key]: value }))
  }

  const lstDistrict = myLocationManagement

  ////Lấy danh sách ward
  // Get all unique parentId values from lstDistrict
  const wardParentIds = myLocationManagement?.map((district) => district.id)

  //Từ wardParentIds, lấy ra những ward có id trong wardParentIds
  const lstWard = ward.filter((ward) => wardParentIds.includes(ward.parentId))

  ////Lấy danh sách province
  // Get all unique parentId values from lstDistrict
  const districtParentIds = lstDistrict?.map((district) => district.parentId)
  //Từ districtParentIds, lấy ra những district có id trong districtParentIds
  const lstProvionce = province.filter((province) => districtParentIds.includes(province.id))

  ////Lấy danh sách subZone
  // Get all unique subZoneId(parentId) values from lstProvionce
  const provinceParentIds = lstProvionce?.map((province) => province.subZoneId)
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstSubZone = subzone?.filter((subZone) => provinceParentIds.includes(subZone.id))

  ////Lấy danh sách zone
  // Get all unique zoneId(parentId) values from lstProvionce
  const subZoneParentIds = lstProvionce?.map((province) => province.zoneId) // trong lstProvionce có lưu zoneId
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstZone = zone.filter((zone) => subZoneParentIds.includes(zone.id))

  const subZoneMemo = useMemo(() => {
    const parentDatas = filter?.zone_ids?.length > 0 ? filter.zone_ids : lstZone.map((e) => e.id)
    return lstSubZone?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.zoneId) : true))?.length > 0
      ? [{ label: 'Tất cả', value: 'all' }].concat(
          lstSubZone
            ?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.zoneId) : true))
            ?.map((item) => ({
              value: item.id,
              label: item.name
            }))
        )
      : []
  }, [lstSubZone, lstZone, filter])

  const provinceMemo = useMemo(() => {
    const parentDatas = filter?.sub_zone_ids?.length > 0 ? filter.sub_zone_ids : subZoneMemo.map((e) => e.value)
    console.log('provinces', parentDatas)
    return lstProvionce?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.subZoneId) : true))?.length >
      0
      ? [{ label: 'Tất cả', value: 'all' }].concat(
          lstProvionce
            ?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.subZoneId) : true))
            ?.map((item) => ({
              value: item.id,
              label: item.name
            }))
        )
      : []
  }, [lstProvionce, filter])

  const districtMemo = useMemo(() => {
    const parentDatas = filter?.province_ids?.length > 0 ? filter.province_ids : provinceMemo.map((e) => e.value)
    return lstDistrict?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.parentId) : true))?.length > 0
      ? [{ label: 'Tất cả', value: 'all' }].concat(
          lstDistrict
            ?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.parentId) : true))
            ?.map((item) => ({
              value: item.id,
              label: item.name
            }))
        )
      : []
  }, [lstDistrict, filter])

  return (
    <React.Fragment>
      <Col xxl={4} lg={4} className='mt-3'>
        <Label className='form-label'>{ManageContentVN.filters.zone.title}</Label>
        <FormSelect
          options={[{ label: 'Tất cả', value: 'all' }].concat(
            lstZone?.map((item) => ({
              value: item.id,
              label: item.name
            }))
          )}
          value={filter.zone_ids}
          onChange={(value) => {
            handleSelect(value, 'zone_ids')
            handleSelect([], 'sub_zone_ids')
            handleSelect([], 'province_ids')
            handleSelect([], 'district_ids')
            handleSelect([], 'ward_ids')
          }}
          onSelect={(value) => {
            const all = lstZone?.map((item) => item.id)
            if (value && value === 'all') {
              return handleSelect([...all], 'zone_ids')
            }
          }}
          placeholder={ProductVN.filter.zone.placeholder}
          mode='multiple'
        />
      </Col>
      <Col xxl={4} lg={4} className='mt-3'>
        <Label className='form-label'>{ManageContentVN.filters.subzone.title}</Label>
        <FormSelect
          options={subZoneMemo}
          value={filter.sub_zone_ids}
          onChange={(value) => {
            handleSelect(value, 'sub_zone_ids')
            handleSelect([], 'province_ids')
            handleSelect([], 'district_ids')
            handleSelect([], 'ward_ids')
          }}
          onSelect={(value) => {
            const all = subZoneMemo.map((item) => item.value)
            if (value && value === 'all') {
              return handleSelect([...all], 'sub_zone_ids')
            }
          }}
          placeholder={ProductVN.filter.zone.placeholder}
          mode='multiple'
        />
      </Col>

      <Col xxl={4} lg={4} className='mt-3'>
        <Label className='form-label'>{ManageContentVN.filters.city.title}</Label>
        <FormSelect
          options={provinceMemo}
          value={filter.province_ids}
          onChange={(value) => {
            handleSelect(value, 'province_ids')
            handleSelect([], 'district_ids')
            handleSelect([], 'ward_ids')
          }}
          onSelect={(value) => {
            const all = provinceMemo.map((item) => Number(item.value)).filter((num) => !isNaN(num))
            if (value && value === 'all') {
              return handleSelect([...all], 'province_ids')
            }
          }}
          placeholder='Tất cả'
          mode='multiple'
        />
      </Col>
      <Col xxl={4} lg={4} className='mt-3'>
        <Label className='form-label'>{ManageContentVN.filters.type.title}</Label>
        <FormSelect
          options={[
            {
              label: 'Tích lũy',
              value: 'accumulation'
            },
            {
              label: 'Kích hoạt',
              value: 'activation'
            },
            {
              label: 'Trưng bày',
              value: 'display'
            },
            {
              label: 'Tin tức',
              value: 'news'
            },
            {
              label: 'Khác',
              value: 'other'
            }
          ]}
          value={filter.type}
          onChange={(value) => {
            handleSelect(value, 'type')
          }}
          placeholder='Tất cả'
        />
      </Col>

      <Col xxl={4} lg={4} className='mt-3'>
        <Label className='form-label'>{ManageContentVN.filters.status.title}</Label>
        <FormSelect
          options={TypeContentOptions}
          value={filter.status}
          onChange={(value) => {
            handleSelect(value, 'status')
          }}
          placeholder='Tất cả'
        />
      </Col>

      <Col xxl={4} className='mt-3' lg={4}>
        <Label className='form-label'>{'Thời gian hiển thị'}</Label>
        <FormDateRange
          inputReadOnly
          className='w-100'
          size='large'
          format={CommonVN.datePlaceholder.toUpperCase()}
          placeholder={[CommonVN.datePlaceholder, CommonVN.datePlaceholder]}
          value={[filter.start_date, filter.end_date]}
          onChange={(value) => {
            if (value) {
              handleSelect(value[0], 'start_date')
              handleSelect(value[1], 'end_date')
            } else {
              handleSelect(undefined, 'start_date')
              handleSelect(undefined, 'end_date')
            }
          }}
        />
      </Col>
      <Col className='d-flex align-items-end mt-3 '>
        <div className='mb-xxl-0 me-2'>
          <button
            type='button'
            className='btn btn-soft-danger w-100'
            onClick={() => {
              setFilter({
                status: undefined,
                province_id: undefined,
                zone_id: undefined,
                sub_zone_id: undefined,
                start_date: undefined,
                end_date: undefined,
                type: undefined
              })
              setSearch('')
              setGlobalFilter({})
            }}
          >
            Xóa tìm kiếm
          </button>
        </div>
        <div className='mb-xxl-0'>
          <button
            type='button'
            className='btn btn-primary w-100'
            onClick={() => {
              const _filter = !filter
                ? filter
                : Object.fromEntries(Object.entries(filter).filter(([key, value]) => value !== undefined))
              setGlobalFilter({ ..._filter, search })
            }}
          >
            Tìm kiếm
          </button>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default ContentGlobalFilter
