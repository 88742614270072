import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { Modal, ModalHeader } from 'reactstrap'
import Breadcrumb from 'components/Common/Breadcrumb'
// redux
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import { Col, Row, UncontrolledTooltip, Card, CardBody, ModalBody } from 'reactstrap'

import { toast } from 'react-toastify'
import { AccumulationVN } from 'constants/vn'
import ConfirmModal from 'components/Common/ConfirmModal'
import {
  checkUploadFileAPI,
  confirmReconcileAPI,
  deleteAccumulationAPI,
  exportReconcileFetcherAPI,
  getActivationListReportAPI,
  importFileAPI
} from 'helpers/backend_helper'
import { ACCUMULATED_MANAGEMENT_CODE, SCREEN_CODE } from 'constants/permission-code'
import PermissionWrapper from 'components/Common/PermissionWrapper'
import { Space } from 'antd'
import { TableRowSpan } from 'components/Common/TableCustom'

import ManageAccumulationFilter from './components/AccumulationReportFilter'
import AddEditAccumulation from '../ManageAccumulation/components/AddEditAccumulation'
import {
  AccumulationModel,
  Serial,
  Zone,
  Shop,
  Customer,
  ActivatedDate,
  ReconcileStatus
} from '../ManageAccumulation/ManageAccumulationCol'
import { FormInput } from 'components/Common/form-input/FormInput'
import withRouter from 'components/Common/withRouter'
import moment from 'moment'

import * as url from 'helpers/url_helper'
import ProgramDetailCom from './ProgramDetail'
import SpinnerVersion2 from 'components/Common/SpinnerVersion2'
import ImportModal from 'components/Common/ImportModal'
import Gallery from 'react-photo-gallery'
import Carousel, { ModalGateway, Modal as ModalLightBox } from 'react-images'

function DetailAccumulationReport(props) {
  // meta title
  document.title = 'Detailed ' + props?.programmingType + ' report'

  const params = props?.router?.params;
  const programmingName = decodeURIComponent(params.name);
  const stringUrl = params?.id
  const screenCode = useMemo(() => SCREEN_CODE?.ACCUMULATED_MANAGEMENT, [])

  const selectAccumulationsState = (state) => state.AccumulationReducer
  const AccumulationsProperties = createSelector(selectAccumulationsState, (reducer) => ({
    accumulations: reducer.accumulations,
    loading: reducer.loading,
    total: reducer.total
  }))

  const selectProfileState = (state) => state.Profile
  const ProfileProperties = createSelector(selectProfileState, (profile) => ({
    profile: profile.profile
  }))
  const { profile } = useSelector(ProfileProperties)

  const [accumulations, setAccumulations] = useState([])

  const [programmingVersion, setProgrammingVersion] = useState(null)

  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (layout) => ({
    filter: layout.filter
  }))
  const { filter } = useSelector(LayoutProperties)

  const [isReloadAPI, setIsReloadAPI] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [showDeleteAccount, setShowDeleteAccount] = useState(false)
  const [selected, setSelected] = useState()
  const [showForm, setShowForm] = useState(false)
  const [page, setPage] = useState(1)
  const [pagination, setPagination] = useState({
    pageIndex: page,
    pageSize: 10,
    totalItems: 0
  })
  const [dataFilter, setDataFilter] = useState({})
  const [showImport, setShowImport] = useState(false)
  const [isLoadingImport, setLoadingImport] = useState(false)

  const [countData, setCountdata] = useState({})
  const onReloadAPI = () => setIsReloadAPI(!isReloadAPI)

  const setReason = (value, index) => {
    if (accumulations.length > 0) {
      setAccumulations([...accumulations.map((item, i) => (i === index ? { ...item, reason: value } : item))])
    }
  }

  const handleClickDownloadTemplate = async () => {}

  const uploadFile = async (files) => {
    setLoadingImport(true)
    let formData = new FormData()
    formData.append('file', files[0])
    formData.append('type', 'reconcile_by_programming')

    try {
      const res = await importFileAPI(url.IMPORT_RECONCILE_BY_PROGRAMMING, formData)
      if (res) {
        handleCheckUpload(res.data.data)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
      setLoadingImport(false)
    }
  }

  const handleCheckUpload = async (data) => {
    try {
      const res = await checkUploadFileAPI(data)

      if (!res.data.queueFinish) {
        setTimeout(() => {
          handleCheckUpload(data)
        }, 2000)
      } else {
        if (res.data.countFailed > 0) {
          // handleExportFileError(data)
          setShowImport(false)
          setLoadingImport(false)
        }
        toast('Hệ thống đang xử lý . Vui lòng kiểm tra thông tin tại màn hình Lịch sử', { type: 'success' })
        setPage(1)
        setLoadingImport(false)
        setShowImport(false)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
      setLoadingImport(false)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: AccumulationVN.table.shop,
        accessor: 'usersShopName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => <Shop {...cellProps} />
      },
      ...(props.programmingType === 'activation'
        ? [
            {
              Header: AccumulationVN.table.customer,
              accessor: 'customerName',
              filterable: true,
              disableSortBy: true,
              hideSort: true,
              Cell: (cellProps) => <Customer {...cellProps} />
            }
          ]
        : []),
      {
        Header: 'Ngành',
        accessor: 'industryName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => <Zone {...cellProps} />
      },
      {
        Header: 'Ngành hàng',
        accessor: 'industrySectorName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => <Zone {...cellProps} />
      },
      {
        Header: 'Nhóm sản phẩm',
        accessor: 'categoryName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => <Zone {...cellProps} />
      },
      {
        Header: 'Gói',
        accessor: 'packageIndex',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => <Zone {...cellProps} />
      },

      {
        Header: AccumulationVN.table.model,
        accessor: 'modelName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => <AccumulationModel {...cellProps} />
      },
      {
        Header: AccumulationVN.table.serial,
        accessor: 'serial',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => <Serial {...cellProps} />
      },
      {
        Header: 'Ngày thực hiện',
        accessor: 'userRewardCreatedAt',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => <ActivatedDate {...cellProps} />
      },
      {
        Header: 'Miền xuất kho',
        accessor: 'productZoneName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => <span>{cellProps.value}</span>
      },
      {
        Header: 'Kênh bán hàng',
        accessor: 'channelName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => <span>{cellProps.value}</span>
      },
      {
        Header: AccumulationVN.table.status,
        accessor: 'status',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => <ReconcileStatus data={cellProps.value} />
      },
      {
        Header: 'Hiện vật',
        accessor: 'artifactName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => <Zone {...cellProps} />
      },
      {
        Header: 'Tổng tiền',
        accessor: 'reward',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => <div>{Number(cellProps.value)}</div>
      },
      ...(props.programmingType == 'display'
        ? [
            {
              Header: 'Hình ảnh trương bày Biên bản đăng kí',
              accessor: 'images',
              filterable: true,
              disableSortBy: true,
              hideSort: true,
              Cell: (cellProps) => {
                return <ViewImages photos={cellProps.value} />
              }
            }
          ]
        : []),
      {
        Header: 'Lý do từ chối',
        accessor: 'reason',
        hideSort: true,

        Cell: (cellProps) => {
          return (
            <FormInput
              maxLength={50}
              defaultValue={cellProps.value}
              // value={valueFilter || ''}
              // value={descriptionState[cellProps.row.id] || ''}
              disabled={cellProps.row.original.status !== 0}
              // onChange={(e) => {
              //   console.log(e)
              // }}
              onPressEnter={(e) => {
                if (e) setReason(e.target.value, cellProps.row.index)
              }}
              onBlur={(e) => {
                console.log(e)
                if (e) setReason(e.target.value, cellProps.row.index)
                // setAccumulations([accumulations])
              }}
            />
          )
        }
      },
      {
        Header: AccumulationVN.table.action,
        accessor: 'action',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return (
            <ul className='list-unstyled hstack gap-1 mb-0'>
              <li data-bs-toggle='tooltip' data-bs-placement='top' title='reconcile'>
                <Link
                  to='#'
                  style={{
                    opacity:
                      cellProps.row.original.reason?.length > 0 || [1, 2, 3].includes(cellProps.row.original.status)
                        ? 0.5
                        : 1
                  }}
                  className='btn btn-sm btn-soft-primary'
                  id={`viewtooltip-${cellProps.row.original.id}`}
                  onClick={() => {
                    const { id } = cellProps.row.original
                    if (
                      !(cellProps.row.original.reason?.length > 0 || [1, 2].includes(cellProps.row.original.status))
                    ) {
                      setLoading()
                      onReconcileReward(id, { type: 1 })
                    }
                  }}
                >
                  <i className='mdi mdi-file-check' />
                  <UncontrolledTooltip
                    trigger='hover'
                    placement='top'
                    target={`viewtooltip-${cellProps.row.original.id}`}
                  >
                    Đối soát
                  </UncontrolledTooltip>
                </Link>
              </li>

              <PermissionWrapper
                screenCode={screenCode}
                code={ACCUMULATED_MANAGEMENT_CODE.ADMIN_DELETE_USER_REWARD_ACCUMULATED}
              >
                <li>
                  <Link
                    to='#'
                    style={{
                      opacity:
                        cellProps.row.original.reason?.length > 0 && cellProps.row.original.status === 0 ? 1 : 0.5
                    }}
                    className='btn btn-sm btn-soft-danger'
                    onClick={() => {
                      const { id, reason, status } = cellProps.row.original
                      if (reason?.length > 0 && status === 0)
                        onReconcileReward(id, { type: 2, reason: cellProps.row.original.reason })
                    }}
                    id={`deletetooltip-${cellProps.row.original.id}`}
                  >
                    <i className='mdi mdi-close-outline' />
                    <UncontrolledTooltip
                      trigger='hover'
                      placement='top'
                      target={`deletetooltip-${cellProps.row.original.id}`}
                    >
                      Từ chối
                    </UncontrolledTooltip>
                  </Link>
                </li>
              </PermissionWrapper>
              <Link
                to='#'
                className='btn btn-sm btn-soft-primary'
                id={`viewdetailtip-${cellProps.row.original.id}`}
                onClick={() => {
                  setProgrammingVersion(cellProps.row.original.programmingVersionId)
                  showModal()
                }}
              >
                <i className='mdi mdi-information-variant ' style={{ fontSize: 14 }} />

                <UncontrolledTooltip
                  trigger='hover'
                  placement='top'
                  target={`viewdetailtip-${cellProps.row.original.id}`}
                >
                  Chi tiết
                </UncontrolledTooltip>
              </Link>
            </ul>
          )
        }
      }
    ],
    [accumulations]
  )

  const onInit = async (payload) => {
    setLoading(true)
    payload = payload ? { ...payload, programming_id: stringUrl } : { programming_id: stringUrl }
    const x = await getActivationListReportAPI(payload)
    setAccumulations(x.data.map((i) => ({ ...i, reason: i.description || '' })))
    setCountdata(x.meta)
    setPagination(x.meta)
    setLoading(false)
  }

  useEffect(() => {
    onInit()
  }, [isReloadAPI])

  const onReconcileReward = async (id, payload) => {
    setLoading(true)
    await confirmReconcileAPI(id, payload)
    onReloadAPI()
    setLoading(false)
  }

  const setGlobalFilterUser = async (data) => {
    if (data.start_date1 !== undefined && data.end_date1 !== undefined) {
      data.start_date = data.start_date1.startOf('day').toDate()
      data.end_date = data.end_date1.endOf('day').toDate()
    } else {
      data.start_date = undefined
      data.end_date = undefined
    }
    setPage(1)
    setDataFilter(data)
    onInit(data)
  }

  const goToPage = (page) => {
    setPage(page)
    onInit({ ...dataFilter, page: page })
  }

  const onDeleteAccount = async () => {
    try {
      const res = await deleteAccumulationAPI({
        id: selected.userRewardId
      })
      if (res) {
        toast('Xóa thông tin tích lũy thành công', { type: 'success' })
        setShowDeleteAccount(false)
        setPage(page)
      }
    } catch (error) {
      console.log(error)
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const handleClickExport = async () => {
    setLoading(true)
    try {
      const response = await exportReconcileFetcherAPI({ ...dataFilter, programming_id: stringUrl })
      const data = response?.headers?.get('Content-Type')
      if (data !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        toast(`Hệ thống đang xử lý. Tệp của bạn đã được gửi tới email ${profile?.email} trong vài phút`, {
          type: 'success'
        })
      } else {
        try {
          const blob = await response.blob() // Fetch the blob
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${moment().format('yyMMDD')}_${props.title}.xlsx`) // Tên file
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          toast('Export file thành công!', { type: 'success' })
        } catch (err) {
          console.log('err', err)
        }
      }
    } catch (error) {}
    setLoading(false)
  }

  const handleResetFilter = () => {
    setDataFilter({})
    onInit({ page: 1 })
  }

  const [isModalOpen, setIsModalOpen] = useState(false)
  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
  }
  const onClickCancel = () => {
    setIsModalOpen(false)
  }
  const navigate = useNavigate()

  return (
    <>
      <SpinnerVersion2 loading={isLoading} />
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <Breadcrumb title='Lịch sử' breadcrumbItem={props.title} />
          <Row>
            <Col lg='12'>
              <Card>
                <CardBody className='border-bottom'>
                  <div className='d-flex align-items-center'>
                    <h1 className='mb-0 card-title flex-grow-1'></h1>
                    <div className='flex-shrink-0'>
                      <PermissionWrapper
                        screenCode={screenCode}
                        code={ACCUMULATED_MANAGEMENT_CODE.ADMIN_EXPORT_ACCUMULATED}
                      >
                        {showImport && (
                          <ImportModal
                            onCloseClick={() => {
                              setShowImport(false)
                            }}
                            show={showImport}
                            onConfirmClick={uploadFile}
                            loading={isLoadingImport}
                            title={'Import đối soát'}
                            // onDownloadClick={handleClickDownloadTemplate}
                          />
                        )}
                        <button className='btn btn-primary me-2 pe-4' onClick={() => setShowImport(true)}>
                          <i className='mdi mdi-import me-1 ms-2' />
                          Import
                        </button>
                        <button onClick={() => handleClickExport()} className='btn btn-primary me-2 pe-4'>
                          <i className='mdi mdi-database-export me-1 ms-2' />
                          {AccumulationVN.export}
                        </button>
                        <button
                          onClick={() => {
                            navigate('/manage-import-history')
                          }}
                          className='btn btn-primary me-2 pe-4 '
                        >
                          <i className='mdi mdi-history me-1 ms-2' />
                          Lịch sử import
                        </button>
                      </PermissionWrapper>
                    </div>
                  </div>
                </CardBody>
                <CardBody>
                  <Space size='middle' direction='vertical' className='w-100'>
                    <ManageAccumulationFilter
                      programmingType={props.programmingType}
                      handleFilter={setGlobalFilterUser}
                      handleResetFilter={handleResetFilter}
                    />
                    <span style={{ fontSize: 16, fontWeight: 'bold' }}>
                      {`${props.title} - ${programmingName}`}
                      <button className='btn btn-warning btn-rounded me-2' style={{ marginLeft: 10 }}>
                        Chờ đối soát: {countData.countPending || 0}
                      </button>
                      <button className='btn btn-info btn-rounded me-2'>
                        Đã đối soát: {countData.countAccept || 0}
                      </button>
                      <button className='btn btn-danger btn-rounded me-2'>
                        Đã từ chối: {countData.countCancel || 0}
                      </button>
                    </span>
                    <TableRowSpan
                      columns={columns}
                      data={accumulations}
                      pagination={pagination}
                      setPagination={setPagination}
                      gotoPageCustom={goToPage}
                      currentPage={page}
                      total={pagination.totalItems}
                      isShowTotal={true}
                    />
                  </Space>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onOk={handleOk}
        size='xl'
        toggle={() => {
          onClickCancel()
        }}
        centered={true}
      >
        <div className='modal-content'>
          <ModalBody>
            <ProgramDetailCom isClose={onClickCancel} programmingVersionId={programmingVersion} />
          </ModalBody>
        </div>
      </Modal>
      <ConfirmModal
        show={showDeleteAccount}
        icon={
          <div className='avatar-title rounded-circle bg-danger text-light bg-opacity-60 font-size-20 rounded-3'>
            <i className='mdi mdi-delete-alert' />
          </div>
        }
        onConfirmClick={onDeleteAccount}
        onCloseClick={() => setShowDeleteAccount(false)}
        title='Xóa bản ghi'
        description={'Các kết quả thưởng của bản ghi sẽ bị hủy.\n Bạn có chắc chắn muốn xoá bản ghi tích lũy?'}
      />
      <AddEditAccumulation
        onCloseClick={() => {
          setShowForm(false)
        }}
        show={showForm}
        page={page}
      />
    </>
  )
}

const ViewImages = ({ photos }) => {
  const [isOpen, setIsOpen] = useState(false)
  const onClose = () => setIsOpen(false)
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)
  const photoTransformed = useMemo(
    () =>
      photos.map((photo) => ({
        src: photo,
        width: 1,
        height: 1
      })),
    [, [photos]]
  )

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <>
      <a onClick={() => setIsOpen(!isOpen)} style={{ color: '#1E90FF' }}>
        Ảnh trưng bày
      </a>
      <Modal
        size='lg'
        isOpen={isOpen}
        toggle={() => {
          onCloseClick()
        }}
        centered={true}
      >
        <div className='modal-content'>
          <ModalHeader className='p-4 font-weight-normal' toggle={onClose}>
            Danh sách ảnh trưng bày
          </ModalHeader>
          <div className='modal-content'>
            <ModalBody className='p-4 text-start'>
              <div>
                <Gallery photos={photoTransformed} onClick={openLightbox} />
                <ModalGateway>
                  {viewerIsOpen ? (
                    <ModalLightBox onClose={closeLightbox}>
                      <Carousel
                        currentIndex={currentImage}
                        views={photoTransformed.map((x) => ({
                          ...x,
                          srcset: x.srcSet,
                          caption: x.title
                        }))}
                      />
                    </ModalLightBox>
                  ) : null}
                </ModalGateway>
              </div>
            </ModalBody>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default withRouter(DetailAccumulationReport)
