import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { getProgramBonusTempList as onGetManageContent } from 'store/actions'

import {
  Status,
  Type,
  ContentName,
  DisplayDate,
  ReportCode,
  TotalUser,
  AvgTotal
} from '../ProgramBonusTemp/ManageContentCol'
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'
import { Col, Row, UncontrolledTooltip, Card, CardBody } from 'reactstrap'
import Spinners from 'components/Common/Spinner'
import { toast } from 'react-toastify'
import TableContainer from 'components/Common/TableContainer'
import ConfirmModal from 'components/Common/ConfirmModal'
import { deleteContentAPI, exportByProgrammingFetcherAPI, patchStatusContentAPI } from 'helpers/backend_helper'
import moment from 'moment'
import { getStorage, setStorage } from 'helpers/global_helper'
import SpinnerVersion2 from 'components/Common/SpinnerVersion2'

function ProgrammingRewardReportList({ programmingType, title }) {
  //meta title
  document.title = 'Report ' + programmingType

  const dispatch = useDispatch()
  const selectContentsState = (state) => state.ProgramBonusTempReducer
  const ContentsProperties = createSelector(selectContentsState, (contentReducer) => ({
    contents: contentReducer.contents,
    loading: contentReducer.loading,
    total: contentReducer.total
  }))

  const { contents, loading, total } = useSelector(ContentsProperties)

  const selectProfileState = (state) => state.Profile
  const ProfileProperties = createSelector(selectProfileState, (profile) => ({
    profile: profile.profile
  }))
  const { profile } = useSelector(ProfileProperties)

  const [isLoading, setLoading] = useState(loading)
  const [showDelete, setShowDelete] = useState(false)
  const [showPause, setShowPause] = useState(false)
  const [showPlay, setShowPlay] = useState(false)
  const [selected, setSelected] = useState()
  const [filter, setFilter] = useState()
  const [page, setPage] = useState(1)

  useEffect(() => {
    const filterProgramBonusTempList = getStorage('filterProgramBonusTempList')

    dispatch(onGetManageContent({ ...filterProgramBonusTempList, type: programmingType, page: 1 }))
  }, [dispatch, programmingType])

  const columns = useMemo(
    () => [
      {
        Header: 'Mã CT',
        accessor: 'code',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ReportCode {...cellProps} />
        }
      },
      {
        Header: 'Loại chương trình ',
        accessor: 'type',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Type {...cellProps} />
        }
      },
      {
        Header: 'Tên chương trình',
        accessor: 'name',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ContentName {...cellProps} />
        }
      },
      {
        Header: 'Thời gian áp dụng',
        accessor: 'startDate',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <DisplayDate {...cellProps} />
        }
      },
      {
        Header: 'Tài khoản tham gia',
        accessor: 'totalUser',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <TotalUser {...cellProps} />
        }
      },
      {
        Header: 'Mã đối soát/ Tổng số mã',
        accessor: 'totalCompleted',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <AvgTotal {...cellProps} />
        }
      },
      {
        Header: 'Trạng thái',
        accessor: 'status',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Status {...cellProps} />
        }
      },

      {
        Header: 'Thao tác',
        accessor: 'action',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          // console.log(cellProps)
          return (
            <ul className='list-unstyled hstack gap-1 mb-0'>
              <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                <Link
                  // to={`/reconcile-programming-by-area/${cellProps.row.original.id}`}
                  to={`/detail-${programmingType}-report/${cellProps.row.original.id}/${encodeURIComponent(cellProps.row.original.name)}`}
                  className='btn btn-sm btn-soft-primary'
                  id={`viewtooltip-${cellProps.row.original.id}`}
                >
                  <i className='mdi mdi-eye-outline' />
                  <UncontrolledTooltip placement='top' target={`viewtooltip-${cellProps.row.original.id}`}>
                    Xem
                  </UncontrolledTooltip>
                </Link>
              </li>

              <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                <Link to={`#`} className='btn btn-sm btn-soft-info' id={`edittooltip-${cellProps.row.original.id}`}>
                  <i className='mdi mdi-download' />
                  <UncontrolledTooltip placement='top' target={`edittooltip-${cellProps.row.original.id}`}>
                    Download
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          )
        }
      }
    ],
    [filter, page, contents]
  )

  const handleSort = (sortBy) => {
    if (sortBy.length > 0) {
      setFilter({ ...filter, sort_field: sortBy[0].id, sort_order: sortBy[0].desc ? 'desc' : 'asc' })
      dispatch(
        onGetManageContent({
          ...filter,
          type: programmingType,
          sort_field: sortBy[0].id,
          sort_order: sortBy[0].desc ? 'desc' : 'asc'
        })
      )
    } else {
      setFilter({ ...filter, sort_field: '', sort_order: '' })
      dispatch(onGetManageContent({ ...filter, type: programmingType, sort_field: '', sort_order: '' }))
    }
  }

  const setGlobalFilterUser = async (data) => {
    data.start_date = data.start_date?.startOf('day')?.toDate()
    data.end_date = data.end_date?.endOf('day')?.toDate()

    setStorage('filterProgramBonusTempList', data)

    setFilter({
      ...data,
      meta: JSON.stringify({
        'Nhập từ khóa tìm kiếm': data?.search,
        'Loại chương trình': data?.type_name,
        'Thời gian áp dụng': data?.start_date + ' - ' + data?.end_date
      }),
      page: 1
    })

    dispatch(
      onGetManageContent({
        ...data,
        type: programmingType,
        meta: JSON.stringify({
          'Nhập từ khóa tìm kiếm': data?.search,
          'Loại chương trình': data?.type_name,
          'Thời gian áp dụng': data?.start_date + ' - ' + data?.end_date
        })
      })
    )
    setPage(1)
  }

  const goToPage = (page) => {
    setFilter({ ...filter, page: page })
    setPage(page)
    dispatch(onGetManageContent({ ...filter, type: programmingType, page: page }))
  }

  const handleClickExport = async () => {
    setLoading(true)
    try {
      const response = await exportByProgrammingFetcherAPI({ ...filter, type: programmingType })
      const data = response.headers.get('Content-Type')
      if (data !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        toast('Hệ thống đang xử lý. Tệp của bạn đã được gửi tới email ' + profile?.email + ' trong vài phút', {
          type: 'success'
        })
      } else {
        try {
          const blob = await response.blob() // Fetch the blob
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${moment().format('yyMMDD')}_Danh sach doi soat thuong.xlsx`) // Tên file
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          toast('Export file thành công!', { type: 'success' })
        } catch (err) {
          console.log('err', err)
        }
      }
    } catch (error) {}
    setLoading(false)
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <h4 className=' mb-sm-0 font-size-18 pb-4'>{title || 'Đối soát thưởng'}</h4>
          <SpinnerVersion2 loading={isLoading} setLoading={setLoading} />
          <Row>
            <Col lg='12'>
              <Card>
                <CardBody className='border-bottom'>
                  <div className='d-flex align-items-center'>
                    <h5 className='mb-0 card-title flex-grow-1'>{''}</h5>
                    <div className='flex-shrink-0'>
                      <button onClick={() => handleClickExport()} className='btn btn-primary me-2 pe-4'>
                        <i className='mdi mdi-database-export me-1 ms-2' />
                        {'Export'}
                      </button>
                    </div>
                  </div>
                </CardBody>
                <CardBody>
                  <TableContainer
                    isReconcile={true}
                    columns={columns}
                    data={contents}
                    isGlobalFilter={true}
                    isUpdateFilter={programmingType + title}
                    setGlobalFilterUser={setGlobalFilterUser}
                    isProgramBonusTempFilter={true}
                    placeholderSearch={'Tìm kiếm'}
                    isPagination={true}
                    iscustomPageSizeOptions={false}
                    isShowingPageLength={false}
                    customPageSize={10}
                    tableClass='table-bordered align-middle nowrap mt-2'
                    paginationDiv='col-sm-12 col-md-7'
                    pagination='pagination justify-content-end pagination-rounded'
                    setSortBy={handleSort}
                    useAPIChangePaging
                    currentPage={page}
                    gotoPageCustom={goToPage}
                    total={total}
                    label={'Tên chương trình/ mã chương trình'}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProgrammingRewardReportList
