import React, { useState } from 'react'
import { Col, Label } from 'reactstrap'

//flatpickr
import 'flatpickr/dist/themes/material_blue.css'
import { CommonVN, LocalityVN } from 'constants/vn'
import { FormSelect } from './form-select/FormSelect'
import { FormDateRange } from './form-date-range/FormDateRange'

const SelectGlobalFilter = ({
  setGlobalFilter,
  search,
  setSearch,
  option,
  titleSearchSelect,
  placeholderSearchSelect
}) => {
  const [filter, setFilter] = useState({})

  const handleSelect = (value, key) => {
    setFilter((e) => ({ ...e, [key]: value }))
  }

  return (
    <React.Fragment>
      <Col xxl={2} lg={2} className='mt-3'>
        <Label className='form-label'>{titleSearchSelect}</Label>
        <FormSelect
          options={option}
          value={filter.select}
          onChange={(value) => {
            handleSelect(value, 'select')
          }}
          placeholder={placeholderSearchSelect}
        />
      </Col>
      <Col xxl={3} lg={3} className='mt-3'>
        <Label className='form-label'>Thời gian cập nhật</Label>
        <FormDateRange
          inputReadOnly
          className='w-100'
          size='large'
          format={CommonVN.datePlaceholder.toUpperCase()}
          placeholder={[CommonVN.datePlaceholder, CommonVN.datePlaceholder]}
          value={[filter?.start_date1, filter?.end_date1]}
          onChange={(value) => {
            if (value) {
              handleSelect(value[0], 'start_date1')
              handleSelect(value[1], 'end_date1')
              handleSelect(value[0], 'start_date')
              handleSelect(value[1], 'end_date')
            } else {
              handleSelect(undefined, 'start_date1')
              handleSelect(undefined, 'end_date1')
              handleSelect(undefined, 'start_date')
              handleSelect(undefined, 'end_date')
            }
          }}
        />
      </Col>
      <Col xxl={4} lg={4} className='d-flex align-items-end justify-content-end mt-3'>
        <div className='mb-xxl-0 me-2'>
          <button
            type='button'
            className='btn btn-soft-danger w-100'
            onClick={() => {
              setFilter({
                status: undefined,
                province_id: undefined,
                start_date1: undefined,
                end_date1: undefined,
                start_date: undefined,
                end_date: undefined
              })
              setSearch('')
              setGlobalFilter({})
            }}
          >
            Xóa tìm kiếm
          </button>
        </div>
        <div className='mb-xxl-0'>
          <button
            type='button'
            className='btn btn-primary w-100'
            onClick={() => {
              const _filter = !filter
                ? filter
                : Object.fromEntries(Object.entries(filter).filter(([key, value]) => value !== undefined))
              setGlobalFilter({ ..._filter, search })
            }}
          >
            Tìm kiếm
          </button>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default SelectGlobalFilter
