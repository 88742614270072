import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTable, useGlobalFilter, useSortBy, useExpanded, usePagination } from 'react-table'
import { Table, Row, Col, Button, Label, UncontrolledTooltip } from 'reactstrap'
import { Link } from 'react-router-dom'
import { CommonVN, ManageShopVN } from 'constants/vn'
import ShopListGlobalFilter from '../../components/Common/GlobalSearchFilter'
import PortfolioGlobalFilter from './PortfolioSearchFilter'
import { ReactComponent as DefaultEmptySvg } from 'assets/images/base-table-empty.svg'
import { MAX_LENGTH_SEARCH } from 'constants/constants'
import { FormInput } from './form-input/FormInput'
import ProductFilter from './ProductSearchFilter'
import ProductActivationFilter from './ProductActivationSearchFilter'
import ProgramFilter from './ProgramSearchFilter'
import UserListGlobalFilter from './UserListGlobalFilter'
import LocalityListGlobalFilter from './LocalityListGlobalFilter'
import SelectGlobalFilter from './SelectFilter'
import ClassConfigGlobalFilter from './ClassConfigGlobalFilter'
import PointHistoryGlobalFilter from './PointHistoryGlobalFilter'
import AccumulationFilter from './ActivationSearchFilter'
import ClassStructureGlobalFilter from './ClassStructureGlobalFilter'
import { Pagination as PaginationAntd } from 'antd'
import GuestListGlobalFilter from './GuestListGlobalFilter'
import ContentGlobalFilter from './ContentGlobalFilter'
import BanerGlobalFilter from './BanerGlobalFilter'
import ProductBonusTempFilter from './ProductBonusTempSearchFilter'
import ProgramBonusTempFilter from './ProgramBonusTempSearchFilter'
import ReconcileProgramByAreaFilter from './ReconcileProgramByAreaSearchFilter'
import BankListGlobalFilter from './BankListGlobalFilter'
import ContactGlobalFilter from './ContactGlobalFilter'
import NotificationGlobalFilter from './NotificationGlobalFilter'
import ReportUserStatisticGlobalFilter from './ReportUserStatisticGlobalFilter'
import SMSBrandnameFilter from './SMSBrandnameFilter'
import { FormDateRange } from './form-date-range/FormDateRange'
import PointConfigByTimeGlobalFilter from './PointConfigByTimeGlobalFilter'
import NoneGlobalFilter from './NoneGlobalFilter'
import AccountLocalityFilter from 'pages/AccountActivity/components/AccountLocalityFilter'
import TrackAccountTempFilter from 'pages/TrackAccount/TrackAccountFilter'
import AccountStatisticsAccumulationFilter from 'pages/AccountStatistics/components/AccountStatisticsAccumulationFilter'
import AccountStatisticsActivationFilter from 'pages/AccountStatistics/components/AccountStatisticsActivationFilter'

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  placeholderSearch = ManageShopVN.filters.search.placeholder,
  isManageShopGlobalFilter,
  isPortfolioGlobalFilter,
  isProductFilter,
  isProductActivationFilter,
  isKTV,
  isUpdateFilter,
  isReconcile,
  isManageProgramGlobalFilter,
  isManageUserGlobalFilter,
  isManageLocalityGlobalFilter,
  isManageClassConfigGlobalFilter,
  isManageClassStructureGlobalFilter,
  isManagePointHistoryGlobalFilter,
  isManagePointConfigByTimeGlobalFilter,
  isManageGuestGlobalFilter,
  isManageContentGlobalFilter,
  optionPermissionGroup,
  isSearchInput = true,
  isSearchSelect = false,
  isShowPagination = false,
  isNoneGlobalFilter,
  titleSearchSelect,
  placeholderSearchSelect,
  isAccumulationFilter,
  isActivationFilter,
  isManageBanerGlobalFilter,
  isProductBonusTempFilter,
  isProgramBonusTempFilter,
  isTrackAccountTempFilter,
  isReconcileProgramByAreaFilter,
  programingCode,
  isManageBankGlobalFilter,
  isContactGlobalFilter,
  isManageNotificationGlobalFilter,
  isReportUserStatisticGlobalFilter,
  isSMSBrandnameFilter,
  isAccountLocalityFilter,
  isAccountStatisticsAccumulationFilter,
  isAccountStatisticsActivationFilter,
  label,
  data,
  type
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  // const onChange = useAsyncDebounce(value => {
  //   setGlobalFilter(value || undefined)
  // }, 200)

  const textPlaceholder = label ? label : placeholderSearch

  return (
    <React.Fragment>
      {isSearchInput && (
        <Col xxl={3} lg={3} className='mt-3'>
          <Label className='form-label text-nowrap'>{ManageShopVN.filters.search.title}</Label>

          <FormInput
            maxLength={MAX_LENGTH_SEARCH}
            value={value || ''}
            id={`input-filter`}
            placeholder={textPlaceholder}
            onChange={(e) => {
              setValue(e.target.value)
            }}
            onBlur={(e) => {
              setValue(e.target.value.trim())
            }}
          />
          <UncontrolledTooltip trigger='hover' placement='top' target={`input-filter`}>
            {textPlaceholder}
          </UncontrolledTooltip>
        </Col>
      )}

      {isSearchSelect && (
        <SelectGlobalFilter
          setGlobalFilter={setGlobalFilter}
          search={value}
          setSearch={setValue}
          option={optionPermissionGroup}
          titleSearchSelect={titleSearchSelect}
          placeholderSearchSelect={placeholderSearchSelect}
        />
      )}
      {isManageShopGlobalFilter && <ShopListGlobalFilter setGlobalFilter={setGlobalFilter} isKTV={isKTV} />}
      {isPortfolioGlobalFilter && (
        <PortfolioGlobalFilter search={value} setSearch={setValue} setGlobalFilter={setGlobalFilter} />
      )}
      {isProductFilter && <ProductFilter search={value} setSearch={setValue} setGlobalFilter={setGlobalFilter} />}
      {isProductActivationFilter && (
        <ProductActivationFilter search={value} setSearch={setValue} setGlobalFilter={setGlobalFilter} />
      )}
      {isManageProgramGlobalFilter && (
        <ProgramFilter search={value} setSearch={setValue} setGlobalFilter={setGlobalFilter} />
      )}
      {isManageBankGlobalFilter && (
        <BankListGlobalFilter
          search={value}
          setSearch={setValue}
          setGlobalFilter={setGlobalFilter}
          optionPermissionGroup={optionPermissionGroup}
        />
      )}
      {isManageLocalityGlobalFilter && (
        <LocalityListGlobalFilter
          search={value}
          setSearch={setValue}
          setGlobalFilter={setGlobalFilter}
          optionPermissionGroup={optionPermissionGroup}
        />
      )}
      {isAccountLocalityFilter && (
        <AccountLocalityFilter
          search={value}
          setSearch={setValue}
          setGlobalFilter={setGlobalFilter}
          optionPermissionGroup={optionPermissionGroup}
          data={data}
        />
      )}
      {isManageClassConfigGlobalFilter && (
        <ClassConfigGlobalFilter
          search={value}
          setSearch={setValue}
          setGlobalFilter={setGlobalFilter}
          optionPermissionGroup={optionPermissionGroup}
        />
      )}
      {isManagePointHistoryGlobalFilter && (
        <PointHistoryGlobalFilter
          search={value}
          setSearch={setValue}
          setGlobalFilter={setGlobalFilter}
          optionPermissionGroup={optionPermissionGroup}
        />
      )}
      {isManagePointConfigByTimeGlobalFilter && (
        <PointConfigByTimeGlobalFilter
          search={value}
          setSearch={setValue}
          setGlobalFilter={setGlobalFilter}
          optionPermissionGroup={optionPermissionGroup}
        />
      )}
      {isManageGuestGlobalFilter && (
        <GuestListGlobalFilter
          search={value}
          setSearch={setValue}
          setGlobalFilter={setGlobalFilter}
          optionPermissionGroup={optionPermissionGroup}
        />
      )}
      {isManageUserGlobalFilter && (
        <UserListGlobalFilter
          search={value}
          setSearch={setValue}
          setGlobalFilter={setGlobalFilter}
          optionPermissionGroup={optionPermissionGroup}
        />
      )}
      {isAccumulationFilter && (
        <AccumulationFilter search={value} setSearch={setValue} setGlobalFilter={setGlobalFilter} />
      )}
      {isActivationFilter && (
        <AccumulationFilter
          search={value}
          setSearch={setValue}
          setGlobalFilter={setGlobalFilter}
          isActivationFilter={isActivationFilter}
        />
      )}
      {isManageClassStructureGlobalFilter && (
        <ClassStructureGlobalFilter
          search={value}
          setSearch={setValue}
          setGlobalFilter={setGlobalFilter}
          optionPermissionGroup={optionPermissionGroup}
        />
      )}
      {isManageContentGlobalFilter && (
        <ContentGlobalFilter
          search={value}
          setSearch={setValue}
          setGlobalFilter={setGlobalFilter}
          optionPermissionGroup={optionPermissionGroup}
        />
      )}
      {isManageBanerGlobalFilter && (
        <BanerGlobalFilter
          search={value}
          setSearch={setValue}
          setGlobalFilter={setGlobalFilter}
          optionPermissionGroup={optionPermissionGroup}
        />
      )}
      {isProductBonusTempFilter && (
        <ProductBonusTempFilter
          search={value}
          setSearch={setValue}
          setGlobalFilter={setGlobalFilter}
          optionPermissionGroup={optionPermissionGroup}
        />
      )}
      {isProgramBonusTempFilter && (
        <ProgramBonusTempFilter
          search={value}
          isReconcile={isReconcile}
          isUpdateFilter={isUpdateFilter}
          setSearch={setValue}
          setGlobalFilter={setGlobalFilter}
          optionPermissionGroup={optionPermissionGroup}
        />
      )}
      {isReconcileProgramByAreaFilter && (
        <ReconcileProgramByAreaFilter
          search={value}
          setSearch={setValue}
          setGlobalFilter={setGlobalFilter}
          optionPermissionGroup={optionPermissionGroup}
          programingCode={programingCode}
        />
      )}
      {isContactGlobalFilter && (
        <ContactGlobalFilter
          search={value}
          setSearch={setValue}
          setGlobalFilter={setGlobalFilter}
          optionPermissionGroup={optionPermissionGroup}
        />
      )}
      {isManageNotificationGlobalFilter && (
        <NotificationGlobalFilter
          search={value}
          setSearch={setValue}
          setGlobalFilter={setGlobalFilter}
          optionPermissionGroup={optionPermissionGroup}
        />
      )}
      {isReportUserStatisticGlobalFilter && (
        <ReportUserStatisticGlobalFilter
          search={value}
          setSearch={setValue}
          setGlobalFilter={setGlobalFilter}
          optionPermissionGroup={optionPermissionGroup}
        />
      )}
      {isNoneGlobalFilter && (
        <NoneGlobalFilter
          search={value}
          setSearch={setValue}
          setGlobalFilter={setGlobalFilter}
          optionPermissionGroup={optionPermissionGroup}
        />
      )}
      {isSMSBrandnameFilter && (
        <SMSBrandnameFilter
          search={value}
          setSearch={setValue}
          setGlobalFilter={setGlobalFilter}
          optionPermissionGroup={optionPermissionGroup}
        />
      )}
      {isTrackAccountTempFilter && (
        <TrackAccountTempFilter
          search={value}
          setSearch={setValue}
          setGlobalFilter={setGlobalFilter}
          optionPermissionGroup={optionPermissionGroup}
        />
      )}
      {isAccountStatisticsAccumulationFilter && (
        <AccountStatisticsAccumulationFilter
          search={value}
          setSearch={setValue}
          setGlobalFilter={setGlobalFilter}
          optionPermissionGroup={optionPermissionGroup}
          data={data}
          type={type}
        />
      )}
      {isAccountStatisticsActivationFilter && (
        <AccountStatisticsActivationFilter
          search={value}
          setSearch={setValue}
          setGlobalFilter={setGlobalFilter}
          optionPermissionGroup={optionPermissionGroup}
        />
      )}
    </React.Fragment>
  )
}

const TableContainer = ({
  columns,
  data,
  isGlobalFilter,
  isManageShopGlobalFilter,
  isPortfolioGlobalFilter,
  isProductFilter,
  isAddOptions,
  isAddUserList,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  customPageSizeOptions,
  iscustomPageSizeOptions,
  isPagination,
  isShowingPageLength,
  paginationDiv,
  pagination,
  tableClass,
  theadClass,
  isUpdateFilter,
  setGlobalFilterUser,
  placeholderSearch,
  gotoPageCustom,
  currentPage,
  total,
  useAPIChangePaging,
  isNoneGlobalFilter,
  isKTV,
  isShowPagination = true,
  isProductActivationFilter,
  isManageProgramGlobalFilter,
  isManageUserGlobalFilter,
  isManageLocalityGlobalFilter,
  isAccountLocalityFilter,
  isAccountStatisticsAccumulationFilter,
  isAccountStatisticsActivationFilter,
  isManageClassConfigGlobalFilter,
  isManageClassStructureGlobalFilter,
  isManagePointHistoryGlobalFilter,
  isManagePointConfigByTimeGlobalFilter,
  isManageGuestGlobalFilter,
  isManageContentGlobalFilter,
  isManageBanerGlobalFilter,
  isProductBonusTempFilter,
  isProgramBonusTempFilter,
  isReconcileProgramByAreaFilter,
  optionPermissionGroup,
  isSearchInput = true,
  isSearchSelect = false,
  isReconcile = false,
  titleSearchSelect,
  placeholderSearchSelect,
  isAccumulationFilter,
  isActivationFilter,
  setSortBy,
  programingCode,
  isManageBankGlobalFilter,
  isContactGlobalFilter,
  isManageNotificationGlobalFilter,
  isReportUserStatisticGlobalFilter,
  isSMSBrandnameFilter,
  isTrackAccountTempFilter,
  isShowTotal = true,
  label,
  type
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    state: { pageIndex, pageSize, sortBy }
  } = useTable(
    {
      columns,
      data,
      // defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize
      },
      // disableSortBy: true,
      autoResetSortBy: false
    },
    useGlobalFilter,
    // useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )

  useEffect(() => {
    if (setSortBy) {
      setSortBy(sortBy)
    }
  }, [sortBy])

  useEffect(() => {
    if (setSortBy) {
      setSortBy(sortBy)
    }
  }, [sortBy])

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value))
  }

  const getEmptyData = () => {
    return (
      <div className='d-flex flex-column justify-content-center align-items-center p-4'>
        <DefaultEmptySvg />
        <span className='fs-4 fw-semibold'>Không có dữ liệu</span>
      </div>
    )
  }

  return (
    <Fragment>
      <Row className='mb-2'>
        {iscustomPageSizeOptions && (
          <Col md={customPageSizeOptions ? 2 : 1}>
            <select className='form-select' value={pageSize} onChange={onChangeInSelect}>
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col>
        )}

        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            isUpdateFilter={isUpdateFilter}
            isKTV={isKTV}
            isReconcile={isReconcile}
            isShowPagination={isShowPagination}
            isNoneGlobalFilter={isNoneGlobalFilter}
            setGlobalFilter={setGlobalFilterUser}
            isManageShopGlobalFilter={isManageShopGlobalFilter}
            isPortfolioGlobalFilter={isPortfolioGlobalFilter}
            isProductFilter={isProductFilter}
            placeholderSearch={placeholderSearch}
            isProductActivationFilter={isProductActivationFilter}
            isManageProgramGlobalFilter={isManageProgramGlobalFilter}
            isManageUserGlobalFilter={isManageUserGlobalFilter}
            isAccountLocalityFilter={isAccountLocalityFilter}
            isAccountStatisticsAccumulationFilter={isAccountStatisticsAccumulationFilter}
            isAccountStatisticsActivationFilter={isAccountStatisticsActivationFilter}
            isManageLocalityGlobalFilter={isManageLocalityGlobalFilter}
            isManageClassConfigGlobalFilter={isManageClassConfigGlobalFilter}
            isManageClassStructureGlobalFilter={isManageClassStructureGlobalFilter}
            isManagePointHistoryGlobalFilter={isManagePointHistoryGlobalFilter}
            isManagePointConfigByTimeGlobalFilter={isManagePointConfigByTimeGlobalFilter}
            isManageGuestGlobalFilter={isManageGuestGlobalFilter}
            isAccumulationFilter={isAccumulationFilter}
            isActivationFilter={isActivationFilter}
            optionPermissionGroup={optionPermissionGroup}
            isSearchInput={isSearchInput}
            isSearchSelect={isSearchSelect}
            titleSearchSelect={titleSearchSelect}
            placeholderSearchSelect={placeholderSearchSelect}
            isManageContentGlobalFilter={isManageContentGlobalFilter}
            isManageBanerGlobalFilter={isManageBanerGlobalFilter}
            isProductBonusTempFilter={isProductBonusTempFilter}
            isProgramBonusTempFilter={isProgramBonusTempFilter}
            isReconcileProgramByAreaFilter={isReconcileProgramByAreaFilter}
            programingCode={programingCode}
            isManageBankGlobalFilter={isManageBankGlobalFilter}
            isContactGlobalFilter={isContactGlobalFilter}
            isManageNotificationGlobalFilter={isManageNotificationGlobalFilter}
            isReportUserStatisticGlobalFilter={isReportUserStatisticGlobalFilter}
            isSMSBrandnameFilter={isSMSBrandnameFilter}
            isTrackAccountTempFilter={isTrackAccountTempFilter}
            label={label}
            data={data}
            type={type}
          />
        )}
        {isAddOptions && (
          <Col sm='7' xxl='8'>
            <div className='text-sm-end'>
              <Button type='button' color='success' className='btn-rounded  mb-2 me-2' onClick={handleOrderClicks}>
                <i className='mdi mdi-plus me-1' />
                Add New Order
              </Button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm='7' xxl='8'>
            <div className='text-sm-end'>
              <Button type='button' color='primary' className='btn mb-2 me-2' onClick={handleUserClick}>
                <i className='mdi mdi-plus-circle-outline me-1' />
                Create New User
              </Button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm='7' xxl='8'>
            <div className='text-sm-end'>
              <Button type='button' color='success' className='btn-rounded mb-2 me-2' onClick={handleCustomerClick}>
                <i className='mdi mdi-plus me-1' />
                New Customers
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <div className='table-responsive'>
        {page.length > 0 ? (
          <Table {...getTableProps()} className={tableClass}>
            <thead className={theadClass}>
              {headerGroups.map((headerGroup) => (
                <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th key={column.id} className={column.isSort ? 'sorting' : ''}>
                      <div
                        className='m-0 d-flex justify-content-between align-items-center'
                        {...column.getSortByToggleProps()}
                        style={{ height: '50px' }}
                      >
                        {column.render('Header')}
                        {!column.hideSort && (
                          <span className='font-size-20'>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <i className='mdi mdi-menu-down'></i>
                              ) : (
                                ~(<i className='mdi mdi-menu-up'></i>)
                              )
                            ) : (
                              <i className='mdi mdi-menu-swap'></i>
                            )}
                          </span>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row)
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr>
                      {row.cells.map((cell) => {
                        return (
                          <td key={cell.id} {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  </Fragment>
                )
              })}
            </tbody>
          </Table>
        ) : (
          getEmptyData()
        )}
      </div>

      {isPagination && page.length > 0 && (
        <Row className='justify-content-between align-items-center'>
          {isShowingPageLength ? (
            <div className='col-sm'>
              <div className='text-muted'>
                Showing <span className='fw-semibold'>{page.length}</span> of{' '}
                <span className='fw-semibold'>{data.length}</span> entries
              </div>
            </div>
          ) : (
            <div className='col-sm' />
          )}
          {useAPIChangePaging ? (
            <>
              <div className='d-flex flex-row '>
                {isShowTotal && (
                  <div className='d-flex w-100 justify-content-start'>
                    <p>
                      <i>Tổng {total} bản ghi</i>
                    </p>
                  </div>
                )}
                <div className='d-flex w-100 justify-content-end' style={{ display: 'flex', justifyContent: 'right' }}>
                  {isShowPagination && (
                    <PaginationAntd
                      total={total}
                      current={currentPage}
                      showSizeChanger={false}
                      onChange={gotoPageCustom}
                    />
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className={paginationDiv}>
              <ul className={pagination}>
                <li className={`page-item ${!canPreviousPage ? 'disabled' : ''}`}>
                  <Link to='#' className='page-link' onClick={previousPage}>
                    <i className='mdi mdi-chevron-left'></i>
                  </Link>
                </li>
                {pageOptions.map((item, key) => (
                  <React.Fragment key={key}>
                    <li className={pageIndex === item ? 'page-item active' : 'page-item'}>
                      <Link to='#' className='page-link' onClick={() => gotoPage(item)}>
                        {item + 1}
                      </Link>
                    </li>
                  </React.Fragment>
                ))}
                <li className={`page-item ${!canNextPage ? 'disabled' : ''}`}>
                  <Link to='#' className='page-link' onClick={nextPage}>
                    <i className='mdi mdi-chevron-right'></i>
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </Row>
      )}
    </Fragment>
  )
}

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any
}

export default TableContainer
