import axios from 'axios'
import { COOKIE_KEYS_REFRESH_TOKEN, COOKIE_KEYS_TOKEN, HTTP_STATUS_CODE } from 'constants/constants'
import { toast } from 'react-toastify'
import { refreshToken } from './backend_helper'
import { parseCookies, removeCookieUtil, setCookieUtil } from './global_helper'
import accessTokenAuth from './jwt-token-access/accessToken'

//pass new generated access token here
const tokenAuth = accessTokenAuth
let accessToken = ''
//apply base url for axios
const API_URL = process.env.REACT_APP_API_URL

const axiosApi = axios.create({
  baseURL: API_URL,
  timeout: 2 * 1000 * 60
})

export const refreshTokenErrorEvent = new EventTarget()
const unauthorizedEvent = new Event('unauthorizedEvent')

axiosApi.defaults.headers.common['Authorization'] = tokenAuth
axiosApi.defaults.headers.common['Accept-Language'] = 'vi'

const fetcher = async (url, method, data, config) => {
  try {
    const response = await fetch(`${API_URL}${url}`, {
      method,
      body: data ? JSON.stringify(data) : undefined,
      headers: {
        ...config.headers,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: accessToken
      },
      ...config
    })

    return response
  } catch (ex) {
    return ex
  }
}

let refreshTokenPromise

const applyToken = (config, session) => {
  if (!config.headers) {
    config.headers = {}
  }

  if (session) {
    accessToken = `Bearer ${session}`
    config.headers.Authorization = `Bearer ${session}`
  }
}

axiosApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    const statusCode = error.response.status
    if (statusCode === HTTP_STATUS_CODE.UNAUTHORIZED) {
      refreshTokenErrorEvent.dispatchEvent(unauthorizedEvent)
    }

    const token = parseCookies(null)[COOKIE_KEYS_TOKEN]
    if (statusCode === HTTP_STATUS_CODE.UNAUTHORIZED && token) {
      toast.dismiss()
      if (!refreshTokenPromise) {
        const refreshTokenKey = parseCookies(null)[COOKIE_KEYS_REFRESH_TOKEN]
        refreshTokenPromise = refreshToken(refreshTokenKey)
      }

      return refreshTokenPromise
        .then((session) => {
          setCookieUtil(COOKIE_KEYS_TOKEN, String(session.data.data.token))
          setCookieUtil(COOKIE_KEYS_REFRESH_TOKEN, String(session.data.data?.refreshToken))
          applyToken(error.config, session.data.data.token)
          return axiosApi.request(error.config)
        })
        .catch(async (refreshError) => {
          removeCookieUtil(COOKIE_KEYS_REFRESH_TOKEN)
          removeCookieUtil(COOKIE_KEYS_TOKEN)
          refreshTokenErrorEvent.dispatchEvent(unauthorizedEvent)
          return Promise.reject(refreshError)
        })
        .finally(() => {
          refreshTokenPromise = undefined
        })
    }

    return Promise.reject(error)
  }
)

axiosApi.interceptors.request.use((config) => {
  if (refreshTokenPromise) {
    refreshTokenPromise.then((session) => {
      applyToken(config, session.data.data.token)
      return config
    })
  }
  const token = parseCookies(null)[COOKIE_KEYS_TOKEN]
  if (token) {
    applyToken(config, token)
  }

  return {
    ...config
  }
})

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then((response) => response.data)
}

export async function patch(url, data, config = {}) {
  return await axiosApi.patch(url, { ...data }, { ...config }).then((response) => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi.post(url, { ...data }, { ...config }).then((response) => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi.put(url, { ...data }, { ...config }).then((response) => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi.delete(url, { ...config }).then((response) => response.data)
}

export const fetcherApi = {
  get: (url, { params }, config = {}) => {
    const queryString = new URLSearchParams(params || {}).toString();
    return fetcher(url+ `?${queryString}`, 'get', null, config)
  },
  post: (url, data, config = {}) => fetcher(url, 'post', data, config)
}
