import PropsType from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { Label, UncontrolledTooltip } from 'reactstrap'
import React from 'react'
import { Row, Col, Space, Flex, Form, Select } from 'antd'

//flatpickr
import { ActivationVN, CommonVN, ProductVN } from 'constants/vn'

import { DatePicker } from 'antd'
import { FormInput } from 'components/Common/form-input/FormInput'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { MAX_LENGTH_SEARCH, StatusProductOptions, SumProductOptions, TypeProgramOptions } from 'constants/constants'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { FormDateRange } from 'components/Common/form-date-range/FormDateRange'
import { getChannelAPI, getPortfolioAllAPI } from 'helpers/backend_helper'
import { mode } from 'crypto-js'
import dayjs from 'dayjs'
import { useCategoriesFilter } from 'hooks/useCategoriesFiltter'

const { RangePicker } = DatePicker

ManageAccumulationFilter.propTypes = {
  handleFilter: PropsType.func,
  handleConfirm: PropsType.func,
  handleResetFilter: PropsType.func,
  handleExport: PropsType.func
}

function ManageAccumulationFilter({ handleFilter, handleConfirm, handleResetFilter, handleExport }) {
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    zone: app.zone,
    subzone: app.subzone,
    province: app.province,
    district: app.district,
    ward: app.ward,
    myLocationManagement: app.myLocationManagement
  }))
  const { zone, subzone, province, district, myLocationManagement, ward } = useSelector(LayoutProperties)
  const [filter, setFilter] = useState({
    start_date1: dayjs()
      .month(dayjs().get('month') - 2)
      .startOf('month')
      .startOf('day'),
    end_date1: dayjs().endOf('day')
  })
  const [industry, setIndustry] = useState()
  const [industryOptions, setIndustryOptions] = useState()
  const [industrySector, setIndustrysector] = useState()
  const [category, setCategory] = useState()
  const [channel, setChannel] = useState([])
  const [form] = Form.useForm()
  const watchType = Form.useWatch('type', form)
  const watchForm = Form.useWatch([], form)
  const [model, setModel] = useState()

  const onFilterChange = (name, value) =>
    setFilter((prev) => ({
      ...prev,
      [name]: value
    }))

  const handleSelect = (value, key) => {
    setFilter((e) => ({ ...e, [key]: value }))
  }

  const ColProps = useMemo(() => ({
    xs: { span: 12 },
    xxl: { span: 8 }
    // lg: { span: 6 }
  }))

  const ColProps2 = useMemo(() => ({
    xs: { span: 24 },
    xxl: { span: 8 }
    // lg: { span: 6 }
  }))

  const onResetFilter = () =>
    setFilter({
      serial: '',
      model_name: '',
      shop_name: '',
      phone: '',
      zone_ids: undefined,
      sub_zone_ids: undefined,
      province_ids: undefined,
      district_ids: undefined,
      ward_ids: undefined,
      zone_names: undefined,
      sub_zone_names: undefined,
      province_names: undefined,
      district_names: undefined,
      ward_names: undefined,
      client: undefined,
      client_namme: undefined,
      start_date1: undefined,
      end_date1: undefined,
      industry_ids: undefined,
      industry_sector_ids: undefined,
      category_ids: undefined,
      model_names: undefined,
      industry_names: undefined,
      industry_sector_names: undefined,
      category_names: undefined,
      model_names: undefined,
      group_by: undefined,
      group_by_name: undefined,
      status: undefined,
      status_name: undefined,
      serial_zone_ids: undefined,
      serial_zone_names: undefined
    })

  const lstDistrict = myLocationManagement

  ////Lấy danh sách ward
  // Get all unique parentId values from lstDistrict
  const wardParentIds = myLocationManagement?.map((district) => district.id)

  //Từ wardParentIds, lấy ra những ward có id trong wardParentIds
  const lstWard = ward.filter((ward) => wardParentIds.includes(ward.parentId))

  ////Lấy danh sách province
  // Get all unique parentId values from lstDistrict
  const districtParentIds = lstDistrict?.map((district) => district.parentId)
  //Từ districtParentIds, lấy ra những district có id trong districtParentIds
  const lstProvionce = province.filter((province) => districtParentIds.includes(province.id))

  ////Lấy danh sách subZone
  // Get all unique subZoneId(parentId) values from lstProvionce
  const provinceParentIds = lstProvionce?.map((province) => province.subZoneId)
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstSubZone = subzone?.filter((subZone) => provinceParentIds.includes(subZone.id))

  ////Lấy danh sách zone
  // Get all unique zoneId(parentId) values from lstProvionce
  const subZoneParentIds = lstProvionce?.map((province) => province.zoneId) // trong lstProvionce có lưu zoneId
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstZone = zone.filter((zone) => subZoneParentIds.includes(zone.id))
  // const 1stIndustrySector =
  // const industryMemo = useMemo(() => {
  //   const parentDatas = industryOptions > 0 ? industryOptions : industry_ids((e) => e.id)
  //   return
  // })
  const subZoneMemo = useMemo(() => {
    const parentDatas = filter?.zone_ids?.length > 0 ? filter.zone_ids : lstZone.map((e) => e.id)
    return lstSubZone?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.zoneId) : true))?.length > 0
      ? [{ label: 'Tất cả', value: 'all' }].concat(
          lstSubZone
            ?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.zoneId) : true))
            ?.map((item) => ({
              value: item.id,
              label: item.name
            }))
        )
      : []
  }, [lstSubZone, lstZone, filter])
  const provinceMemo = useMemo(() => {
    const parentDatas = filter?.sub_zone_ids?.length > 0 ? filter.sub_zone_ids : subZoneMemo.map((e) => e.value)
    return lstProvionce?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.subZoneId) : true))?.length >
      0
      ? [{ label: 'Tất cả', value: 'all' }].concat(
          lstProvionce
            ?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.subZoneId) : true))
            ?.map((item) => ({
              value: item.id,
              label: item.name
            }))
        )
      : []
  }, [lstProvionce, filter])
  const districtMemo = useMemo(() => {
    const parentDatas = filter?.province_ids?.length > 0 ? filter.province_ids : provinceMemo.map((e) => e.value)
    return lstDistrict?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.parentId) : true))?.length > 0
      ? [{ label: 'Tất cả', value: 'all' }].concat(
          lstDistrict
            ?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.parentId) : true))
            ?.map((item) => ({
              value: item.id,
              label: item.name
            }))
        )
      : []
  }, [lstDistrict, filter])
  const getIndustryDataOptions = async () => {
    try {
      const resIndustry = await getPortfolioAllAPI({ type: 'industry' })
      const resIndustrysector = await getPortfolioAllAPI({ type: 'industrySector' })
      const resCategory = await getPortfolioAllAPI({ type: 'category' })
      const resModel = await getPortfolioAllAPI({ type: 'model' })
      setIndustry(resIndustry?.data)
      const resChannel = await getChannelAPI()
      setChannel(resChannel?.data)
      if (resIndustry?.data?.length > 0) {
        setIndustryOptions(
          [{ label: 'Tất cả', value: 'all' }].concat(
            resIndustry?.data?.map((item) => ({
              value: item.id,
              label: item.name
            }))
          )
        )
      } else {
        setIndustryOptions([])
      }

      setIndustrysector(resIndustrysector?.data)
      setCategory(resCategory?.data)
      setModel(resModel?.data)
    } catch (error) {
      console.log(error)
    }
  }
  const { industrySectorOptions, categoryOptions, modelOptions } = useCategoriesFilter({
    selectedCategory: filter?.category_ids || [],
    selectedIndustrySector: filter?.industry_sector_ids || []
  })

  useEffect(() => {
    getIndustryDataOptions()
  }, [])

  return (
    <Row gutter={[10, 10]} align='bottom'>
      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Ngành hàng</Label>
        <FormSelect
          options={industrySectorOptions.options}
          onChange={(value, label) => {
            onFilterChange('industry_sector_ids', value)
            onFilterChange(
              'industry_sector_names',
              label.map((e) => e.label)
            )

            onFilterChange('category_ids', [])
            onFilterChange('model_ids', [])
            onFilterChange('category_names', [])
            onFilterChange('model_names', [])
          }}
          onSelect={(value) => {
            const all = industrySector?.filter((e) => filter.industry_ids?.includes(e.parentId))?.map((item) => item.id)
            const all_names = industrySector
              ?.filter((e) => filter.industry_ids?.includes(e.parentId))
              ?.map((item) => item.name)

            if (value && value === 'all') {
              onFilterChange('industry_sector_ids', [...industrySectorOptions.ids])
              onFilterChange('industry_sector_names', [...industrySectorOptions.names])
            }
          }}
          value={filter.industry_sector_ids}
          placeholder='Chọn Ngành hàng'
          mode='multiple'
        />
      </Col>

      <Col {...ColProps} className='mt-5'>
        <Label className='form-label'>Nhóm sản phẩm</Label>
        <FormSelect
          options={categoryOptions.options}
          onChange={(value, label) => {
            onFilterChange('category_ids', value)
            onFilterChange(
              'category_names',
              label.map((e) => e.label)
            )

            onFilterChange('model_ids', [])
            onFilterChange('model_names', [])
          }}
          onSelect={(value) => {
            const all = category
              ?.filter((e) => filter.industry_sector_ids?.includes(e?.parentId))
              ?.map((item) => item.id)
            const all_names = category
              ?.filter((e) => filter.industry_sector_ids?.includes(e?.parentId))
              ?.map((item) => item.name)
            if (value && value === 'all') {
              onFilterChange('category_ids', [...categoryOptions.ids])
              onFilterChange('category_names', [...categoryOptions.names])
            }
          }}
          value={filter.category_ids}
          placeholder='Chọn nhóm sản phẩm'
          mode='multiple'
        />
      </Col>

      <Col {...ColProps} className='mt-5'>
        <Label className='form-label'>Nhập Model</Label>

        <FormSelect
          placeholder={'Nhập Model'}
          options={modelOptions.options}
          onChange={(value, label) => {
            onFilterChange('model_ids', value)
            onFilterChange(
              'model_names',
              label.map((e) => e.label)
            )
          }}
          onSelect={(value) => {
            const all = model?.filter((e) => filter.category_ids?.includes(e.parentId))?.map((item) => item.id)
            const all_names = model?.filter((e) => filter.category_ids?.includes(e.parentId))?.map((item) => item.name)

            if (value && value === 'all') {
              onFilterChange('model_ids', [...modelOptions.ids])
              onFilterChange('model_names', [...modelOptions.names])
            }
          }}
          value={filter.model_ids}
          mode='multiple'
        />
      </Col>

      <Col {...ColProps} className='mt-5'>
        <Label className='form-label'>Nhập Serial</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập Serial'
          value={filter?.serial || ''}
          name='serial'
          id={`input-serial-filter`}
          onChange={(e) => onFilterChange('serial', e.target.value)}
          onBlur={(e) => onFilterChange('serial', e.target.value)}
        />
        <UncontrolledTooltip trigger='hover' placement='top' target={`input-serial-filter`}>
          {''}
        </UncontrolledTooltip>
      </Col>

      <Col {...ColProps} className='mt-5'>
        <Label className='form-label'>Kênh kinh doanh</Label>

        <FormSelect
          options={[{ label: 'Tất cả', value: 'all' }].concat(channel?.map((e) => ({ label: e.name, value: e.id })))}
          onChange={(value, label) => {
            handleSelect(value, 'channel_ids')
            handleSelect(
              label.map((e) => e.label),
              'channel_names'
            )
          }}
          onSelect={(value) => {
            const all = channel?.map((item) => item.id)
            const all_names = channel?.map((item) => item.name)
            if (value && value === 'all') {
              handleSelect([...all], 'channel_ids')
              handleSelect([...all_names], 'channel_names')
            }
          }}
          value={filter.channel_ids}
          placeholder='Chọn kênh'
          mode='multiple'
        />
      </Col>

      <Col {...ColProps} className='mt-5'>
        <Label className='form-label'>Nhập tên cửa hàng</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập tên cửa hàng'
          value={filter?.shop_name || ''}
          name='shop-name'
          id={`input-shop-name-filter`}
          onChange={(e) => onFilterChange('shop_name', e.target.value)}
          onBlur={(e) => onFilterChange('shop_name', e.target.value)}
        />
        <UncontrolledTooltip trigger='hover' placement='top' target={`input-shop-name-filter`}>
          {''}
        </UncontrolledTooltip>
      </Col>

      <Col {...ColProps} className='mt-5'>
        <Label className='form-label'>Số điện thoại</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập SĐT'
          value={filter?.phone || ''}
          name='phone'
          id={`input-phone-filter`}
          onChange={(e) => onFilterChange('phone', e.target.value)}
          onBlur={(e) => onFilterChange('phone', e.target.value)}
        />
        <UncontrolledTooltip trigger='hover' placement='top' target={`input-phone-filter`}>
          {''}
        </UncontrolledTooltip>
      </Col>

      <Col {...ColProps} className='mt-5'>
        <Label className='form-label'>{ActivationVN.filter.type.label}</Label>
        <FormSelect
          options={[
            {
              label: 'Cửa hàng',
              value: 'user'
            },
            {
              label: 'Kĩ thuật viên',
              value: 'technician'
            }
          ]}
          value={filter.client}
          onChange={(value, label) => {
            onFilterChange('client', value)
            onFilterChange('client_namme', label.label)
          }}
          placeholder={ActivationVN.filter.type.placeholder}
        />
      </Col>

      <Col {...ColProps} className='mt-5'>
        <Label className='form-label'>Miền</Label>
        <FormSelect
          options={[{ label: 'Tất cả', value: 'all' }].concat(
            lstZone?.map((item) => ({
              value: item.id,
              label: item.name
            }))
          )}
          value={filter?.zone_ids}
          onChange={(value, label) => {
            onFilterChange('zone_ids', value)
            onFilterChange(
              'zone_names',
              label.map((e) => e.label)
            )

            onFilterChange([], 'sub_zone_ids')
            onFilterChange([], 'province_ids')
            onFilterChange([], 'district_ids')
            onFilterChange([], 'ward_ids')
            onFilterChange([], 'sub_zone_names')
            onFilterChange([], 'province_names')
            onFilterChange([], 'district_names')
            onFilterChange([], 'ward_names')
          }}
          onSelect={(value, label) => {
            const all = lstZone?.map((item) => item.id)
            const all_names = lstZone?.map((item) => item.name)
            if (value && value === 'all') {
              onFilterChange('zone_ids', [...all])
              onFilterChange('zone_names', all_names)
            }
          }}
          placeholder={ProductVN.filter.zone.placeholder}
          mode='multiple'
        />
      </Col>

      <Col {...ColProps} className='mt-5'>
        <Label className='form-label'>Vùng</Label>
        <FormSelect
          options={subZoneMemo}
          value={filter.sub_zone_ids}
          onChange={(value, label) => {
            onFilterChange('sub_zone_ids', value)
            onFilterChange(
              'sub_zone_names',
              label.map((e) => e.label)
            )

            onFilterChange([], 'province_ids')
            onFilterChange([], 'district_ids')
            onFilterChange([], 'ward_ids')
            onFilterChange([], 'province_names')
            onFilterChange([], 'district_names')
            onFilterChange([], 'ward_names')
          }}
          onSelect={(value) => {
            const all_names = lstSubZone?.filter((e) => filter.zone_ids?.includes(e.zoneId))?.map((item) => item.name)
            const all = subZoneMemo.map((item) => item.value).filter((num) => !isNaN(num))
            if (value && value === 'all') {
              handleSelect([...all], 'sub_zone_ids')
              handleSelect([...all_names], 'sub_zone_names')
            }
          }}
          placeholder={ProductVN.filter.zone.placeholder}
          mode='multiple'
        />
      </Col>

      <Col {...ColProps} className='mt-5'>
        <Label className='form-label'>Tỉnh/ Thành phố</Label>
        <FormSelect
          options={provinceMemo}
          value={filter.province_ids}
          onChange={(value, label) => {
            onFilterChange('province_ids', value)
            onFilterChange(
              'province_names',
              label.map((e) => e.label)
            )

            onFilterChange('district_ids', [])
            onFilterChange('ward_ids', [])
            onFilterChange('district_names', [])
            onFilterChange('ward_names', [])
          }}
          onSelect={(value) => {
            const all = provinceMemo.map((item) => Number(item.value)).filter((num) => !isNaN(num))
            const all_names = lstProvionce
              ?.filter((e) => filter.sub_zone_ids?.includes(e.subZoneId))
              ?.map((item) => item.name)

            if (value && value === 'all') {
              onFilterChange('province_ids', [...all])
              onFilterChange('province_names', [...all_names])
            }
          }}
          placeholder='Tất cả'
          mode='multiple'
        />
      </Col>

      <Col {...ColProps} className='mt-5'>
        <Label className='form-label'>Quận/ Huyện</Label>
        <FormSelect
          options={districtMemo}
          value={filter.district_ids}
          onChange={(value, label) => {
            onFilterChange('district_ids', value)
            onFilterChange(
              'district_names',
              label.map((e) => e.label)
            )

            onFilterChange('ward_ids', [])
            onFilterChange('ward_names', [])
          }}
          onSelect={(value) => {
            const all = districtMemo.map((item) => Number(item.value)).filter((num) => !isNaN(num))
            const all_names = lstDistrict
              ?.filter((e) => filter.province_ids?.includes(e.parentId))
              ?.map((item) => item.name)

            if (value && value === 'all') {
              onFilterChange('district_ids', [...all])
              onFilterChange('district_names', [...all_names])
            }
          }}
          placeholder='Tất cả'
          mode='multiple'
        />
      </Col>

      <Col {...ColProps} className='mt-5'>
        <Label className='form-label'>Trạng thái</Label>
        <FormSelect
          // listHeight={450}
          placeholder='Trạng thái'
          style={{ width: '100%' }}
          options={StatusProductOptions}
          onChange={(value, label) => {
            onFilterChange('status', value)
            onFilterChange('status_name', label.label)
          }}
          value={filter.status}
        />
      </Col>

      <Col {...ColProps} className='mt-5'>
        <Label className='form-label'>Tính tổng</Label>
        <FormSelect
          // listHeight={450}
          placeholder='Tính tổng'
          style={{ width: '100%' }}
          options={SumProductOptions}
          onChange={(value, label) => {
            onFilterChange('group_by', value)
            onFilterChange('group_by_name', label.label)
          }}
          value={filter.group_by}
        />
      </Col>

      <Col {...ColProps2} className='mt-5'>
        <Label className='form-label'>Thời gian tích lũy</Label>
        <FormDateRange
          inputReadOnly
          className='w-100'
          size='large'
          format={CommonVN.datePlaceholder.toUpperCase()}
          placeholder={[CommonVN.datePlaceholder, CommonVN.datePlaceholder]}
          value={[filter.start_date1, filter.end_date1]}
          onChange={(value) => {
            if (value) {
              onFilterChange('start_date1', value[0])
              onFilterChange('end_date1', value[1])
            } else {
              onFilterChange('start_date1', undefined)
              onFilterChange('end_date1', undefined)
            }
          }}
        />
      </Col>

      <Col {...ColProps} className='mt-5'>
        <Label className='form-label'>Miền xuất kho (của serial)</Label>
        <FormSelect
          options={[{ label: 'Tất cả', value: 'all' }].concat(
            lstZone?.map((item) => ({
              value: item.id,
              label: item.name
            }))
          )}
          value={filter.serial_zone_ids}
          onChange={(value, label) => {
            onFilterChange('serial_zone_ids', value)
            onFilterChange(
              'serial_zone_names',
              label.map((e) => e.label)
            )
          }}
          onSelect={(value) => {
            const all = lstZone?.map((item) => item.id)
            const all_names = lstZone?.map((item) => item.id)

            if (value && value === 'all') {
              onFilterChange('serial_zone_ids', [...all])
              onFilterChange('serial_zone_names', [...all_names])
            }
          }}
          placeholder={ProductVN.filter.zone.placeholder}
          mode='multiple'
        />
      </Col>

      <Col xxl={6} className='mt-5'>
        <Space>
          <button
            type='button'
            className='btn btn-soft-danger'
            onClick={() => {
              onResetFilter()
              handleResetFilter()
            }}
          >
            Xóa tìm kiếm
          </button>

          <button type='button' className='btn btn-primary' onClick={() => handleFilter(filter)}>
            Tìm kiếm
          </button>
        </Space>
      </Col>
    </Row>
  )
}

export default ManageAccumulationFilter
