import { formateDate, formateDateTime } from 'helpers/global_helper'
import React, { useState } from 'react'
import { Badge, Input, UncontrolledTooltip } from 'reactstrap'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { labelsData } from 'common/data'
import { StatusAccumulationOptions } from 'constants/constants'

const Serial = (cell) => {
  return (
    <>
      {cell.row.original?.userRewardIsDeleted && (
        <>
          <i
            className='mdi mdi-recycle-variant'
            style={{ marginRight: '4px', color: 'red' }}
            id={`viewtooltip-bravo-${cell.row.original.id}`}
          />
          <UncontrolledTooltip trigger='hover' placement='top' target={`viewtooltip-bravo-${cell.row.original.id}`}>
            Sản phẩm bị thu hồi
          </UncontrolledTooltip>
        </>
      )}
      <span>{cell.value ? cell.value : '--'}</span>
    </>
  )
}

const AccumulationIndustrySector = (cell) => {
  return cell.value ? cell.value : '--'
}

const AccumulationCategory = (cell) => {
  return cell.value ? cell.value : '--'
}

const AccumulationModel = (cell) => {
  return cell.value ? cell.value : '--'
}

const Shop = (cell) => {
  return (
    <div>
      <p className='m-0'>{cell.value ? cell.value : cell.row.original?.uShopName}</p>
      <p className='m-0'>{cell.row.original?.usersPhone}</p>
      <span>{cell.row.original?.uPhone}</span>
      <p>
        <span>
          {cell.row.original?.provinceName} <br />
          {cell.row.original?.districtName} <br /> {cell.row.original?.wardName}
        </span>
      </p>
    </div>
  )
}
const Customer = (cell) => {
  return (
    <div>
      <p className='m-0'>{cell.value ? cell.value : cell.row.original?.customerName}</p>
      <span>{cell.row.original?.customerPhone}</span>
      <p>
        <span>
          {cell.row.original?.customerAddress?.split('-').map((e, index) => (
            <span key={`${index}${cell.row.original?.id}`}>
              {e}
              <br />
            </span>
          ))}
        </span>
      </p>
    </div>
  )
}

const Ratio = (cell) => {
  return (
    <div>
      <p className='m-0'>
        <Badge className='badge-soft-success'>
          Hoàn thành: {cell.value ? cell.value : cell.row.original?.totalCompleted}
        </Badge>
      </p>
      <p className='m-0'>
        <Badge className='badge-soft-danger'>
          Thất bại: {cell.value ? cell.value : cell.row.original?.totalFailed}
        </Badge>
      </p>
      <p className='m-0'>
        <Badge className='badge-soft-info'>
          Đang xử lý: {cell.value ? cell.value : cell.row.original?.totalProcessing}
        </Badge>
      </p>
      <p className='m-0'>
        <Badge className='badge-soft-warning'>
          Pending: {cell.value ? cell.value : cell.row.original?.totalPending}
        </Badge>
      </p>
    </div>
  )
}

const mapTypeUserReward = (type) => {
  if (type === 'accumulation') return { title: 'Đã tích lũy', color: 'badge-soft-success' }
  if (type === 'activation') return { title: 'Đã kích hoạt', color: 'badge-soft-info' }
}

const CustomerCareProductListStatus = (cell) => {
  const userRewards = cell.row.original?.userRewards
  const statuses = userRewards.length
    ? userRewards
        .filter((data) => ['accumulation', 'activation'].includes(data.type))
        .map((item) => mapTypeUserReward(item.type))
    : [{ title: 'Chưa tích lũy', color: 'badge-soft-warning' }]

  return (
    <div>
      {statuses.map((status, index) => (
        <p className='m-0' key={index}>
          <Badge className={status.color}>{status.title}</Badge>
        </p>
      ))}
    </div>
  )
}

const Status = (cell) => {
  switch (cell.value) {
    case 'pending':
      return <Badge className='badge-soft-info'>{'Đang xử lý'}</Badge>
    case 'failed':
      return <Badge className='badge-soft-warning'>{'Thất bại'}</Badge>
    case 'completed':
      return <Badge className='badge-soft-success'>{'Hoàn thành'}</Badge>
  }
}

const Zone = (cell) => {
  return cell.value ? cell.value : '--'
}
const ActivatedDate = (cell) => {
  return cell.value ? formateDateTime(cell.value) : '--'
}

const ReconcileStatusOptions = [
  {
    label: 'Chờ đối soát'
  },
  {
    label: 'Đã đối soát'
  },
  {
    label: 'Từ chối'
  },
  {
    label: 'Từ chối tự động'
  }
]
const RewardConfirmStatusOptions = [
  {
    label: 'Chưa trả thưởng'
  },
  {
    label: 'Trả thưởng'
  },
  {
    label: 'Từ chối'
  },
  {
    label: 'Thanh toán'
  },
  {
    label: 'Từ chối tự động'
  }
]

const RewardConfirmStatus = ({ data }) => {
  switch (data) {
    case 0:
      return <Badge className='badge-soft-warning'>{RewardConfirmStatusOptions[0].label}</Badge>
    case 1:
      return <Badge className='badge-soft-info'>{RewardConfirmStatusOptions[1].label}</Badge>
    case 2:
      return <Badge className='badge-soft-secondary'>{RewardConfirmStatusOptions[2].label}</Badge>
    case 3:
      return <Badge className='badge-soft-success'>{RewardConfirmStatusOptions[3].label}</Badge>
    case 4:
      return <Badge className='badge-soft-secondary'>{RewardConfirmStatusOptions[4].label}</Badge>
  }
}

const ReconcileStatus = ({ data }) => {
  switch (data) {
    case 0:
      return <Badge className='badge-soft-info'>{ReconcileStatusOptions[0].label}</Badge>
    case 1:
      return <Badge className='badge-soft-success'>{ReconcileStatusOptions[1].label}</Badge>
    case 2:
      return <Badge className='badge-soft-secondary'>{ReconcileStatusOptions[2].label}</Badge>
    case 3:
      return <Badge className='badge-soft-warning'>{ReconcileStatusOptions[3].label}</Badge>
  }
}
const StatusAccumulation = (cell) => {
  switch (cell.value) {
    case 'no_reward':
      return <Badge className='badge-soft-secondary'>{StatusAccumulationOptions[0].label}</Badge>
    case 'waiting_for_control':
      return <Badge className='badge-soft-info'>{StatusAccumulationOptions[1].label}</Badge>
    case 'for_control':
      return <Badge className='badge-soft-success'>{StatusAccumulationOptions[2].label}</Badge>
    case 'payed':
      return <Badge className='badge-soft-warning'>{StatusAccumulationOptions[3].label}</Badge>
  }
}
const StatusAccumulationV_2 = (cell) => {
  switch (cell.value) {
    case 'Không thưởng':
      return <Badge className='badge-soft-secondary'>{cell.value}</Badge>
    case 'Chờ đối soát':
      return <Badge color='warning'>{cell.value}</Badge>
    case 'Đã đối soát':
      return <Badge className='badge-soft-info'>{cell.value}</Badge>
    case 'Từ chối':
      return <Badge color='dark'>{cell.value}</Badge>
    case 'Thanh toán':
      return <Badge className='badge-soft-success'>{cell.value}</Badge>
    case 'Trả thưởng':
      return (
        <Badge className='' color='light' style={{ color: 'green', fontWeight: 'bold' }}>
          {cell.value}
        </Badge>
      )
  }
}

const StatusImportFile = (cell) => {
  switch (cell.value) {
    case 'pendding':
      return <Badge className='badge-soft-warning'>{'Đang chờ xử lý'}</Badge>
    case 'completed':
      return <Badge className='badge-soft-success'>{'Hoàn thành'}</Badge>
    case 'failed':
      return <Badge className='badge-soft-danger'>{'Thất bại'}</Badge>
  }
}

const StatusMBH = (cell) => {
  switch (cell.value) {
    case 1:
      return <Badge className='badge-soft-success'>{'Đã kích hoạt'}</Badge>
    case 2:
      return <Badge className='badge-soft-warning'>{'Chưa kích hoạt'}</Badge>
    case 3:
      return <Badge className='badge-soft-danger'>{'Đã khoá'}</Badge>
  }
}

const TruncateCell = (cell) => {
  return <span style={{ minWidth: 50, display: 'inline-block' }} className='m-0 text-truncate'>{cell.value}</span>
}

const RelatedSerials = (cell) => {
  const serials = cell.row?.original?.userRewards?.[0]?.serials;
  if (serials?.length) {
    return (
      <>
        {serials.map((element, index) => (
          <span
            key={index}
            style={{ minWidth: 50, display: 'inline-block' }}
            className="m-0 text-truncate"
          >
            {element}
          </span>
        ))}
      </>
    );
  }
}

// Hàm chuyển đổi từ số thập phân sang độ, phút, giây (DMS)
const convertToDMS = (decimal, isLat) => {
  const absolute = Math.abs(decimal)
  const degrees = Math.floor(absolute)
  const minutesNotTruncated = (absolute - degrees) * 60
  const minutes = Math.floor(minutesNotTruncated)
  const seconds = ((minutesNotTruncated - minutes) * 60).toFixed(1)

  const direction = decimal >= 0 ? (isLat ? 'N' : 'E') : isLat ? 'S' : 'W'

  return `${degrees}°${minutes}'${seconds}"${direction} `
}

// Hàm để chuyển latitude và longitude thành chuỗi tọa độ
const LongLatToDMS = (cell) => {
  const lat = parseFloat(cell?.value?.userRewardLatitude ? cell?.value?.userRewardLatitude : 0).toFixed(6) // Làm tròn thành 6 chữ số sau dấu phẩy
  const long = parseFloat(cell?.value?.userRewardLongitude ? cell?.value?.userRewardLongitude : 0).toFixed(6) // Làm tròn thành 6 chữ số sau dấu phẩy

  // Nếu tọa độ là 0, không hiển thị gì
  if (lat === '0.000000' && long === '0.000000') {
    return null
  } else {
    const dmsLatitude = convertToDMS(cell?.value?.userRewardLatitude, true) // true => là latitude
    const dmsLongitude = convertToDMS(cell?.value?.userRewardLongitude, false) // false => là longitude

    return (
      <>
        <a href={`https://www.google.com/maps?q=${lat},${long}`} target='_blank' rel='noopener noreferrer'>
          {dmsLatitude} {dmsLongitude}
        </a>
      </>
    )
  }
}

const Locate = (cell) => {
  return (
    <>
      <a href={cell} target='_blank' rel='noopener noreferrer'>
        (`(${lat}, ${long})`)
      </a>
    </>
  )
}

export {
  AccumulationModel,
  AccumulationCategory,
  Shop,
  Zone,
  AccumulationIndustrySector,
  StatusAccumulation,
  Serial,
  RelatedSerials,
  ActivatedDate,
  Ratio,
  StatusImportFile,
  Status,
  LongLatToDMS,
  Locate,
  CustomerCareProductListStatus,
  StatusMBH,
  TruncateCell,
  ReconcileStatus,
  RewardConfirmStatus,
  StatusAccumulationV_2,
  Customer
}
