import React, { useEffect, useState } from 'react'
import { Col, Label } from 'reactstrap'

//flatpickr
import 'flatpickr/dist/themes/material_blue.css'
import { ClassConfigVN, ProductVN } from 'constants/vn'
import { FormSelect } from './form-select/FormSelect'
import { getPortfolioAllAPI } from 'helpers/backend_helper'
import { toast } from 'react-toastify'
import { FormInput } from './form-input/FormInput'
import { MAX_LENGTH_SEARCH } from 'constants/constants'
import { getStorage } from 'helpers/global_helper'

const ClassConfigGlobalFilter = ({ setGlobalFilter, search, setSearch }) => {
  const [filter, setFilter] = useState({})

  const handleSelect = (value, key) => {
    setFilter((e) => ({ ...e, [key]: value }))
  }

  const [industry, setIndustry] = useState()
  const [industryOptions, setIndustryOptions] = useState()
  const [industrySector, setIndustrysector] = useState()
  const [category, setCategory] = useState()
  const [model, setModel] = useState()

  const getDataOptions = async () => {
    try {
      const resIndustry = await getPortfolioAllAPI({ type: 'industry' })
      const resIndustrysector = await getPortfolioAllAPI({ type: 'industrySector' })
      const resCategory = await getPortfolioAllAPI({ type: 'category' })
      const resModel = await getPortfolioAllAPI({ type: 'model' })
      setIndustry(resIndustry?.data)
      if (resIndustry?.data?.length > 0) {
        setIndustryOptions(
          [{ label: 'Tất cả', value: 'all' }].concat(
            resIndustry?.data?.map((item) => ({
              value: item.id,
              label: item.name
            }))
          )
        )
      } else {
        setIndustryOptions([])
      }

      setIndustrysector(resIndustrysector?.data)
      setCategory(resCategory?.data)
      setModel(resModel?.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const filterManagePointConfig = getStorage('filterManagePointConfig')
    setFilter(filterManagePointConfig)
    getDataOptions()
  }, [])

  const onFilterChange = (name, value) =>
    setFilter((prev) => ({
      ...prev,
      [name]: value
    }))

  return (
    <React.Fragment>
      <Col xxl={2} lg={2} className='mt-3'>
        <Label className='form-label'>Ngành</Label>

        <FormSelect
          options={industryOptions}
          onChange={(value) => {
            handleSelect(value, 'industry_ids')
            handleSelect([], 'industry_sector_ids')
            handleSelect([], 'category_ids')
            handleSelect([], 'model_ids')
          }}
          onSelect={(value) => {
            const all = industry?.map((item) => item.id)
            if (value && value === 'all') {
              return handleSelect([...all], 'industry_ids')
            }
          }}
          value={filter?.industry_ids}
          placeholder='Chọn Ngành'
          mode='multiple'
        />
      </Col>

      <Col xxl={2} lg={2} className='mt-3'>
        <Label className='form-label'>Ngành hàng</Label>
        <FormSelect
          options={
            industrySector?.filter((e) => filter?.industry_ids?.includes(e.parentId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  industrySector
                    ?.filter((e) => filter?.industry_ids?.includes(e.parentId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : [{ label: 'Tất cả', value: 'all' }].concat(
                industrySector
                  ?.map((item) => ({
                    value: item.id,
                    label: item.name
                  }))
              )
          }
          onChange={(value, label) => {
            onFilterChange('industry_sector_ids', value)
            onFilterChange(
              'industry_sector_names',
              label.map((e) => e.label)
            )

            onFilterChange('category_ids', [])
            onFilterChange('model_ids', [])
            onFilterChange('category_names', [])
            onFilterChange('model_names', [])
          }}
          onSelect={(value) => {
            let all = [], all_names = [];
            if (filter?.industry_ids && filter?.industry_ids?.length > 0) {
              all = industrySector
                ?.filter((e) => filter?.industry_ids?.includes(e.parentId))
                ?.map((item) => item.id)
              all_names = industrySector
                ?.filter((e) => filter?.industry_ids?.includes(e.parentId))
                ?.map((item) => item.name)
            } else {
              all = industrySector
                ?.map((item) => item.id)
              all_names = industrySector
                ?.map((item) => item.name)
            }
            
            if (value && value === 'all') {
              onFilterChange('industry_sector_ids', [...all])
              onFilterChange('industry_sector_names', [...all_names])
            }
          }}
          value={filter?.industry_sector_ids}
          placeholder='Chọn Ngành hàng'
          mode='multiple'
        />
      </Col>

      <Col xxl={2} lg={2} className='mt-3'>
        <Label className='form-label'>{ProductVN.filter?.category.label}</Label>
        <FormSelect
          options={
            category?.filter((e) => filter?.industry_sector_ids?.includes(e?.parentId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  category
                    ?.filter((e) => filter?.industry_sector_ids?.includes(e?.parentId))
                    .map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : [{ label: 'Tất cả', value: 'all' }].concat(
                category
                  .map((item) => ({
                    value: item.id,
                    label: item.name
                  }))
              )
          }
          value={filter?.category_ids}
          onChange={(value) => {
            handleSelect(value, 'category_ids')
            handleSelect([], 'model_ids')
          }}
          onSelect={(value) => {
            let all = [];
            if (filter?.industry_sector_ids && filter?.industry_sector_ids?.length > 0) {
              all = category
                ?.filter((e) => filter?.industry_sector_ids?.includes(e?.parentId))
                ?.map((item) => item.id)
            } else {
              all = category
                ?.map((item) => item.id)
            }
            if (value && value === 'all') {
              return handleSelect([...all], 'category_ids')
            }
          }}
          placeholder={ProductVN.filter?.category.placeholder}
          mode='multiple'
        />
      </Col>

      <Col xxl={2} lg={2} className='mt-3'>
        <Label className='form-label'>Nhập Model</Label>
        <FormSelect
          placeholder='Nhập Model'
          options={
            model?.filter((e) => filter?.category_ids?.includes(e.parentId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  model
                    ?.filter((e) => filter?.category_ids?.includes(e.parentId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : [{ label: 'Tất cả', value: 'all' }].concat(
                model
                  ?.map((item) => ({
                    value: item.id,
                    label: item.name
                  }))
              )
          }
          onChange={(value) => {
            handleSelect(value, 'model_ids')
          }}
          onSelect={(value) => {
            let all = [];
            if (filter?.category_ids && filter?.category_ids?.length > 0) {
              all = model?.filter((e) => filter?.category_ids?.includes(e.parentId))?.map((item) => item.id)
            } else {
              all = model?.map((item) => item.id)
            }
            if (value && value === 'all') {
              return handleSelect([...all], 'model_ids')
            }
          }}
          value={filter?.model_ids}
          mode='multiple'
        />
      </Col>

      <Col xxl={2} lg={2} className='mt-3'>
        <Label className='form-label'>Nhập điểm</Label>
        <FormInput
          type='number'
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập điểm'
          value={filter?.point || ''}
          name='point'
          id={`input-filter`}
          onChange={(value) => {
            handleSelect(value.target.value, 'point')
          }}
          onBlur={(value) => {
            handleSelect(value.target.value, 'point')
          }}
        />
      </Col>

      <Col xxl={3} lg={3} className='d-flex align-items-end mt-3'>
        <div className='mb-xxl-0 me-2'>
          <button
            type='button'
            className='btn btn-soft-danger w-100'
            onClick={() => {
              setFilter({
                status: undefined,
                province_id: undefined,
                industry_ids: undefined,
                industry_sector_ids: undefined,
                category_ids: undefined,
                model_ids: undefined
              })
              setSearch('')
              setGlobalFilter({})
            }}
          >
            Xóa tìm kiếm
          </button>
        </div>
        <div className='mb-xxl-0'>
          <button
            type='button'
            className='btn btn-primary w-100'
            onClick={() => {
              const _filter = !filter
                ? filter
                : Object.fromEntries(Object.entries(filter).filter(([key, value]) => value !== undefined))
              setGlobalFilter({ ..._filter, search })
            }}
          >
            Tìm kiếm
          </button>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default ClassConfigGlobalFilter
