import React from 'react'
import { Badge, UncontrolledTooltip } from 'reactstrap'
import { Link } from 'react-router-dom'
import { ManageContentVN } from 'constants/vn'
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { formateDate } from 'helpers/global_helper'
import { TypeContentOptions, TypeProgramOptions } from 'constants/constants'
import moment from 'moment'

const ContentNo = (cell) => {
  return (
    <div className='d-flex justify-content-between align-items-center'>
      <div className='text-body fw-bold'>{cell?.row ? cell.row.index + 1 + (cell.page - 1) * 10 : ''}</div>
    </div>
  )
}

const ContentName = (cell) => {
  return cell.value ? (
    <div>
      <span
        style={{ maxWidth: 300 }}
        className='d-inline-block text-truncate'
        id={`namecontent-tooltip-${cell.row.original.id}`}
      >
        {cell.value}
      </span>
      <UncontrolledTooltip placement='top' target={`namecontent-tooltip-${cell.row.original.id}`}>
        {cell.value}
      </UncontrolledTooltip>
    </div>
  ) : (
    '--'
  )
}

const DisplayDate = (cell) => {
  return cell.value ? formateDate(cell.value) + '-' + formateDate(cell.row.original?.endDate) : '--'
}

const UpdatetedDate = (cell) => {
  return cell.value ? formateDate(cell.value) : '--'
}

const Type = (cell) => {
  const type = [
    {
      label: 'Tích lũy',
      value: 'accumulation'
    },
    {
      label: 'Kích hoạt',
      value: 'activation'
    },
    {
      label: 'Trưng bày',
      value: 'display'
    },
    {
      label: 'Tin tức',
      value: 'news'
    },
    {
      label: 'Khác',
      value: 'other'
    }
  ]
  return cell?.value ? type.find((e) => e.value === cell?.value)?.label : '--'
}

// const Status = (cell) => {
//   switch (cell.value) {
//     case 'pending':
//       return <Badge className='badge-soft-info'>{TypeContentOptions[0].label}</Badge>
//     case 'pause':
//       return <Badge className='badge-soft-success'>{TypeContentOptions[1].label}</Badge>
//     case 'active':
//       return <Badge className='badge-soft-success'>{TypeContentOptions[1].label}</Badge>
//     case 'rejected':
//       return <Badge className='badge-soft-danger'>{TypeContentOptions[2].label}</Badge>
//   }
// }

const Status = (cell) => {
  switch (cell.value) {
    case 'pending':
      return <Badge className='badge-soft-info'>{'Chờ log'}</Badge>
    case 'processing':
      return <Badge className='badge-soft-warning'>{'Đang log'}</Badge>
    case 'completed':
      return <Badge className='badge-soft-success'>{'Đã log'}</Badge>
  }
}

const StringGrp = (cell) => {
  return cell.value ? cell?.value.split('-')[1] : '--'
}

const NumberGrp = (cell) => {
  return cell?.value ? priceSplitter(cell?.value.split('-')[1]) : '--'
}

const ApplyDuration = (cell) => {
  let concatenatedDate = ''
  if (cell?.value?.applyTime == 'each_day') {
    concatenatedDate = cell?.value?.applyDates
  } else {
    concatenatedDate = cell?.value
      ? moment(cell?.value?.startDate).format('DD/MM/YYYY') + ' - ' + moment(cell?.value?.endDate).format('DD/MM/YYYY')
      : '--'
  }

  return <p> {concatenatedDate} </p>
}

const AvgTotal = (cell) => {
  return cell?.value ? cell?.value?.totalCompleted + '/' + cell?.value?.total : '--'
}

const AvgTotalUser = (cell) => {
  return cell?.value ? cell?.value?.totalUser : '--'
}

export {
  ContentNo,
  ContentName,
  DisplayDate,
  Status,
  UpdatetedDate,
  Type,
  StringGrp,
  NumberGrp,
  ApplyDuration,
  AvgTotal,
  AvgTotalUser
}
