import React, { useEffect, useState } from 'react'
import { Col, Label, Row } from 'reactstrap'

//flatpickr
import 'flatpickr/dist/themes/material_blue.css'
import { ActivationVN, CommonVN, ProductVN } from 'constants/vn'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'

import { FormDateRange } from 'components/Common/form-date-range/FormDateRange'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { getStorage } from 'helpers/global_helper'

const TrackAccountTempFilter = ({ setGlobalFilter, search, setSearch, isActivationFilter }) => {
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    zone: app.zone,
    subzone: app.subzone,
    province: app.province,
    district: app.district,
    ward: app.ward,
    myLocationManagement: app.myLocationManagement
  }))

  const { zone, province, myLocationManagement } = useSelector(LayoutProperties)

  const [filter, setFilter] = useState({})
  //   const [zone, setZone] = useState([])
  const lstDistrict = myLocationManagement

  //   useEffect(() => {
  //     handleGetAllZone()
  //   }, [])

  //   useEffect(() => {
  //     if (selectedZone) {
  //       handleGetSubZone()
  //     }
  //   }, [selectedZone])

  const handleSelect = (value, key) => {
    setFilter((e) => ({ ...e, [key]: value }))
  }

  useEffect(() => {
    const filterManageLocality = getStorage('filterManageLocality')
    setFilter(filterManageLocality)
  }, [])

  ////Lấy danh sách ward
  // Get all unique parentId values from lstDistrict

  //Từ wardParentIds, lấy ra những ward có id trong wardParentIds

  ////Lấy danh sách province
  // Get all unique parentId values from lstDistrict
  const districtParentIds = lstDistrict?.map((district) => district.parentId)
  //Từ districtParentIds, lấy ra những district có id trong districtParentIds
  const lstProvionce = province.filter((province) => districtParentIds.includes(province.id))

  ////Lấy danh sách subZone
  // Get all unique subZoneId(parentId) values from lstProvionce
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds

  ////Lấy danh sách zone
  // Get all unique zoneId(parentId) values from lstProvionce
  const subZoneParentIds = lstProvionce?.map((province) => province.zoneId) // trong lstProvionce có lưu zoneId
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstZone = zone.filter((zone) => subZoneParentIds.includes(zone.id))

  return (
    <React.Fragment>
      <Col xl={3} lg={2}>
        <Label className='form-label'>{'Thời gian'}</Label>
        <FormDateRange
          inputReadOnly
          onKeyDown={(e) => e.preventDefault()}
          className='w-100'
          size='large'
          format={CommonVN.datePlaceholder.toUpperCase()}
          placeholder={[CommonVN.datePlaceholder, CommonVN.datePlaceholder]}
          value={[filter?.start_date, filter?.end_date]}
          onChange={(value) => {
            if (value) {
              handleSelect(value[0], 'start_date')
              handleSelect(value[1], 'end_date')
            } else {
              handleSelect(undefined, 'start_date')
              handleSelect(undefined, 'end_date')
            }
          }}
        />
      </Col>
      <Col xl={3} lg={2}>
        <Label className='form-label'>Miền </Label>
        <FormSelect
          options={[{ label: 'Tất cả', value: 'all' }].concat(
            lstZone?.map((item) => ({
              value: item.id,
              label: item.name
            }))
          )}
          value={filter?.zone_ids}
          onChange={(value) => {
            handleSelect(value, 'zone_ids')
            handleSelect([], 'sub_zone_ids')
            handleSelect([], 'province_ids')
            handleSelect([], 'district_ids')
            handleSelect([], 'ward_ids')
          }}
          onSelect={(value) => {
            const all = lstZone?.map((item) => item.id)
            if (value && value === 'all') {
              return handleSelect([...all], 'zone_ids')
            }
          }}
          mode='multiple'
          placeholder={ProductVN.filter?.zone.placeholder}
        />
      </Col>
      <Col
        xl={isActivationFilter ? 3 : 6}
        lg={isActivationFilter ? 5 : 7}
        className='d-flex align-items-end justify-content-end mt-3'
      >
        <div className='mb-xxl-0 me-2'>
          <button
            type='button'
            className='btn btn-soft-danger w-100'
            onClick={() => {
              setFilter({
                type: undefined,
                type_name: undefined,
                start_date: undefined,
                end_date: undefined
              })
              setSearch(undefined)
              setGlobalFilter({})
            }}
          >
            Xóa tìm kiếm
          </button>
        </div>
        <div className='mb-xxl-0'>
          <button
            type='button'
            className='btn btn-primary w-100'
            onClick={() => {
              const _filter = !filter
                ? filter
                : Object.fromEntries(Object.entries(filter).filter(([, value]) => value !== undefined && value !== ''))
              setGlobalFilter({ ..._filter, search })
            }}
          >
            Tìm kiếm
          </button>
        </div>
      </Col>
      <Row xxl={3} className='mt-3' lg={3}>
        <div className='mb-xxl-0'></div>
      </Row>
    </React.Fragment>
  )
}
export default TrackAccountTempFilter
