export const USER_INFORMATION_KEY = 'user-info'
export const COOKIE_KEYS_TOKEN = 'token'
export const COOKIE_KEYS_REFRESH_TOKEN = 'refreshToken'

export const HTTP_STATUS_CODE = {
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  UNPROCESSABLE_ENTITY: 422,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
  // Custom
  DEACTIVATED_ACCOUNT: 8888,
  LOGIN_FAILED: 1015
}

// Max length
export const MAX_LENGTH_PASSWORD = 20
export const MAX_LENGTH_EMAIL = 320
export const MIN_LENGTH_PASSWORD = 6
export const MIN_LENGTH_ACCOUNT_NUMBER = 6
export const MAX_LENGTH_ACCOUNT_NUMBER = 10
export const MIN_LENGTH_CARDID = 9
export const MAX_LENGTH_CARDID = 12
export const MAX_LENGTH_ADDRESS = 500
export const MAX_LENGTH_NAME = 255
export const MAX_LENGTH_TAXCODE = 13
export const MAX_LENGTH_SEARCH = 250
export const MAX_LENGTH_NAME_PORTFOLIO = 50
export const MAX_LENGTH_100 = 100
export const MAX_LENGTH_50 = 50
export const MAX_LENGTH_30 = 30
export const MAX_LENGTH_20 = 20
export const IMAGE_RATIO = 85 / 54

// File size
export const MAX_FILE_SIZE = 5 * 1024

// Valid Date
export const MAX_DATE = '05/25/9999'
export const MIN_DATE = '05/01/0000'
export const FORMAT_DATE_BE = 'YYYY-MM-DD'
// Options
export const TypePortfolioOptions = [
  // {
  //   label: 'Danh mục Ngành',
  //   value: 'industry'
  // },
  {
    label: 'Danh mục Ngành hàng',
    value: 'industrySector'
  },
  {
    label: 'Danh mục Nhóm sản phẩm',
    value: 'category'
  },
  {
    label: 'Danh mục Model',
    value: 'model'
  }
]

export const StatusOptions = [
  {
    label: 'Đã kích hoạt',
    value: 'active'
  },
  {
    label: 'Chưa kích hoạt',
    value: 'inactive'
  }
]

export const SumProductOptions = [
  {
    label: 'Cửa hàng/KTV',
    value: 'USER'
  },
  {
    label: 'Không',
    value: 'UNGROUP'
  }
]

export const StatusProductOptions = [
  {
    label: 'Không thưởng',
    value: 'no_reward'
  },
  {
    label: 'Chờ đối soát',
    value: 'waiting_for_control'
  }
]

export const StatusReconcileOptions = [
  {
    label: 'Chờ đối soát',
    value: 0
  },
  {
    label: 'Đã đối soát',
    value: 1
  },
  {
    label: 'Từ chối',
    value: 2
  },
  {
    label: 'Từ chối tự động',
    value: 3
  },
]

export const StatusActivationOptions = [
  {
    label: 'Đã kích hoạt',
    value: 'activate'
  },
  {
    label: 'Chưa kích hoạt',
    value: 'inactivate'
  },
  {
    label: 'Đã khóa',
    value: 'temporary_deleted'
  }
]

export const TypeProgramOptions = [
  {
    label: 'Tích lũy',
    value: 'accumulation'
  },
  {
    label: 'Kích hoạt',
    value: 'activation'
  },
  {
    label: 'Trưng bày',
    value: 'display'
  }
]
export const TypeContentOptions = [
  {
    label: 'Chờ duyệt',
    value: 'pending'
  },
  {
    label: 'Đã duyệt',
    value: 'active'
  },
  {
    label: 'Từ chối',
    value: 'rejected'
  }
  // {
  //     label: "Tạm dừng",
  //     value: "pause",
  // },
]

export const StatusProgramOptions = [
  {
    label: 'Lưu nháp',
    value: 'draft'
  },
  {
    label: 'Chờ duyệt',
    value: 'pending'
  },
  {
    label: 'Đã duyệt',
    value: 'active'
  },
  {
    label: 'Từ chối',
    value: 'rejected'
  },
  {
    label: 'Tạm dừng',
    value: 'pause'
  }
]

export const typeAccountProgramOptions = [
  {
    label: 'Tất cả',
    value: 'all'
  },
  {
    label: 'Cửa hàng',
    value: 'user'
  },
  {
    label: 'Kĩ thuật viên',
    value: 'technician'
  }
]

export const accountTypeOptions = [
  {
    label: 'Cửa hàng',
    value: 'user'
  },
  {
    label: 'Kĩ thuật viên',
    value: 'technician'
  }
]

export const StatusAccumulationOptions = [
  {
    label: 'Không thưởng',
    value: 'no_reward'
  },
  {
    label: 'Chờ đối soát',
    value: 'waiting_for_control'
  },
  {
    label: 'Đã đối soát',
    value: 'for_control'
  },
  {
    label: 'Đã trả thưởng',
    value: 'payed'
  }
]
