import React, { useEffect, useMemo, useState } from 'react'
import { getReportAccountRank as onGetReportAccountRank } from 'store/actions'
import { BaseString } from './ReportAccountRankCol'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { Space } from 'antd'
import ConfirmModal from 'components/Common/ConfirmModal'
import Spinners from 'components/Common/Spinner'
import { TableRowSpan } from 'components/Common/TableCustom'
import { deleteContentAPI, exportReportAccountRankAPI, patchStatusContentAPI } from 'helpers/backend_helper'
import fileDownload from 'js-file-download'
import moment from 'moment'
import { toast } from 'react-toastify'
import { Card, CardBody, Col, Row } from 'reactstrap'
import ReportAccountRankFilter from './components/ReportAccountRankFilter'
import SpinnerVersion2 from 'components/Common/SpinnerVersion2'

function ReportAccountRank() {
  //meta title
  document.title = 'Content List | HPG'
  const dispatch = useDispatch()
  const selectReportAccountRankState = (state) => state.ReportAccountRankReducer
  const ReportProperties = createSelector(selectReportAccountRankState, (reportReducer) => ({
    notifications: reportReducer.notifications,
    loading: reportReducer.loading,
    total: reportReducer.total
  }))

  const { notifications, loading, total } = useSelector(ReportProperties)

  const [isLoading, setLoading] = useState(loading)
  const [showDelete, setShowDelete] = useState(false)
  const [showPause, setShowPause] = useState(false)
  const [showPlay, setShowPlay] = useState(false)
  const [selected, setSelected] = useState()
  const [filter, setFilter] = useState()
  const [page, setPage] = useState(1)

  const selectProfileState = (state) => state.Profile
  const ProfileProperties = createSelector(selectProfileState, (profile) => ({
    profile: profile.profile
  }))
  const { profile } = useSelector(ProfileProperties)

  const [pagination, setPagination] = useState({
    pageIndex: page,
    pageSize: 10,
    totalRows: total
  })

  useEffect(() => {
    dispatch(onGetReportAccountRank({ page: 1 }))
  }, [dispatch])

  const columns = useMemo(
    () => [
      {
        Header: 'STT',
        accessor: 'stt',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return (page - 1) * 10 + cellProps.row.index + 1
        }
      },
      {
        Header: 'Miền',
        accessor: 'zone.name',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <BaseString {...cellProps} />
        }
      },
      {
        Header: 'Vùng',
        accessor: 'subZone.name',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <BaseString {...cellProps} />
        }
      },
      {
        Header: 'Tỉnh/ Thành phố',
        accessor: 'province.name',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <BaseString {...cellProps} />
        }
      },
      {
        Header: 'Quận/ Huyện',
        accessor: 'district.name',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <BaseString {...cellProps} />
        }
      },
      {
        Header: 'Phường xã',
        accessor: 'ward.name',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <BaseString {...cellProps} />
        }
      },
      {
        Header: 'Tên tài khoản',
        accessor: 'userName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <BaseString {...cellProps} />
        }
      },

      {
        Header: 'Ngành',
        accessor: 'industry.name',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <BaseString {...cellProps} />
        }
      },
      {
        Header: 'Ngành hàng',
        accessor: 'industrySector.name',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <BaseString {...cellProps} />
        }
      },
      {
        Header: 'Nhóm sản phẩm',
        accessor: 'category.name',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <BaseString {...cellProps} />
        }
      },
      {
        Header: 'Số lượng sản phẩm tích lũy',
        accessor: 'numProducts',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <BaseString {...cellProps} />
        }
      },
      {
        Header: 'Xếp hạng',
        accessor: 'rank',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <BaseString {...cellProps} />
        }
      }
    ],
    [filter, page, notifications]
  )

  const [itemSelect, setItemSelect] = useState()

  const onDelete = async () => {
    try {
      const res = await deleteContentAPI({
        id: selected.id
      })
      if (res) {
        toast('Xóa bài viết thành công', { type: 'success' })
        setShowDelete(false)
      }
    } catch (error) {
      console.log(error)
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const onUpdateRequest = async (status, note) => {
    try {
      const res = await patchStatusContentAPI({
        id: selected.id,
        status,
        node: note
      })
      if (res) {
        toast(status === 'pause' ? 'Tạm ngưng bài viết thành công' : 'Hiển thị lại bài viết thành công', {
          type: 'success'
        })
        dispatch(onGetReportAccountRank({ ...filter, page: page }))
      }
    } catch (error) {
      console.log(error)
      toast(error.response.data.message, { type: 'error' })
    }
    closePopup()
  }

  const closePopup = () => {
    setShowPause(false)
    setShowPlay(false)
  }

  const setGlobalFilterUser = async (data) => {
    setFilter({ ...data, page: 1 })
    dispatch(
      onGetReportAccountRank({
        ...data,
        meta: {
          Ngành: data?.industry_names,
          'Ngành hàng': data?.industry_sector_names,
          'Nhóm sản phẩm': data?.category_names,
          Miền: data?.zone_names,
          Vùng: data?.sub_zone_names,
          'Tỉnh/ Thành phố': data?.province_names,
          'Quận/ Huyện': data?.district_names
        }
      })
    )
    setPage(1)
  }

  const goToPage = (page) => {
    setFilter({ ...filter, page: page })
    setPage(page)
    dispatch(onGetReportAccountRank({ ...filter, page: page }))
  }

  const handleResetFilter = () => {
    setFilter({ province_id: undefined, page: 1 })
    dispatch(onGetReportAccountRank({ page: 1 }))
    setPage(1)
  }

  const handleExport = async (filterValue) => {
    setLoading(true)
    try {
      const response = await exportReportAccountRankAPI({ ...filterValue })
      const data = response.headers.get('Content-Type')
      if (data !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        toast('Hệ thống đang xử lý. Tệp của bạn đã được gửi tới email ' + profile?.email + ' trong vài phút', {
          type: 'success'
        })
      } else {
        try {
          const blob = await response.blob() // Fetch the blob
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Xếp hạng tài khoản ${moment().format('yyMMDD')}.xlsx`) // Tên file
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          toast('Export file thành công!', { type: 'success' })
        } catch (err) {
          console.log('err', err)
        }
      }
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <h4 className=' mb-sm-0 font-size-18 pb-4'>{'Thống kê xếp hạng tài khoản '}</h4>
            <SpinnerVersion2 loading={isLoading} setLoading={setLoading} />
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody>
                    <Space size='middle' direction='vertical' className='w-100'>
                      <ReportAccountRankFilter
                        handleFilter={setGlobalFilterUser}
                        handleResetFilter={handleResetFilter}
                        handleExport={handleExport}
                      />
                      <div className='mb-xxl-0 me-3 mt-2'></div>
                      <TableRowSpan
                        columns={columns}
                        data={notifications}
                        pagination={pagination}
                        setPagination={setPagination}
                        gotoPageCustom={goToPage}
                        currentPage={page}
                        total={total}
                        isShowTotal={true}
                      />
                    </Space>
                  </CardBody>
                </Card>
              </Col>
            </Row>
        </div>
      </div>
      <ConfirmModal
        show={showDelete}
        icon={
          <div className='avatar-title rounded-circle bg-danger text-light bg-opacity-60 font-size-20 rounded-3'>
            <i className='mdi mdi-delete-outline'></i>
          </div>
        }
        onConfirmClick={onDelete}
        onCloseClick={() => setShowDelete(false)}
        title='Xác nhận xóa bài viết'
        description='Bài viết đã xóa không thể khôi phục. Bạn có chắc muốn xóa bài viết?'
      />
      <ConfirmModal
        show={showPause}
        icon={
          <div className='avatar-title rounded-circle bg-danger text-light bg-opacity-60 font-size-20 rounded-3'>
            <i className='mdi mdi-delete-outline'></i>
          </div>
        }
        hasInput
        onConfirmClick={(note) => {
          onUpdateRequest('pause', note)
        }}
        onCloseClick={() => setShowPause(false)}
        title='Xác nhận tạm ngưng bài viết'
        description={`Bài viết sẽ không còn được hiển thị ở các module liên quan trên Mobile App. Bạn có chắc chắn muốn tạm ngưng hiển thị bài viết?`}
      />
      <ConfirmModal
        show={showPlay}
        onConfirmClick={(note) => {
          onUpdateRequest('active')
        }}
        onCloseClick={() => setShowPlay(false)}
        // hasInput
        title={'Xác nhận hiển thị lại bài viết'}
        description='Bài viết sẽ được hiển thị ở các module liên quan trên Mobile App. Bạn có chắc chắn muốn hiển thị lại bài viết?'
      />
    </React.Fragment>
  )
}

export default ReportAccountRank
