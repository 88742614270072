import { MAX_LENGTH_PASSWORD } from './constants'

const signInVN = {
  title: 'Đăng nhập',
  inputDetails: [
    {
      name: 'username',
      title: 'Tên đăng nhập',
      placeholder: 'Nhập tên đăng nhập'
    },
    {
      name: 'password',
      title: 'Mật khẩu',
      placeholder: 'Nhập mật khẩu',
      secureTextEntry: true
    },
    {
      name: 'remember',
      title: 'Ghi nhớ đăng nhập'
    }
  ],
  button: 'Đăng nhập'
}

const profileMenuVN = {
  profile: {
    title: 'Thông tin tài khoản',
    link: '/profile'
  },
  changePassword: {
    title: 'Đổi mật khẩu',
    link: '/change-password'
  },
  logout: {
    title: 'Đăng xuất',
    link: '/logout'
  }
}

const changePasswordVN = {
  title: 'Đổi mật khẩu',

  inputDetails: [
    {
      name: 'oldPassword',
      title: 'Nhập mật khẩu hiện tại',
      placeholder: 'Nhập mật khẩu',
      maxLength: MAX_LENGTH_PASSWORD,
      secureTextEntry: true
    },
    {
      name: 'password',
      title: 'Nhập mật khẩu mới',
      placeholder: 'Nhập mật khẩu mới',
      maxLength: MAX_LENGTH_PASSWORD,
      secureTextEntry: true
    },
    {
      name: 'confirmPassword',
      title: 'Nhập lại mật khẩu mới',
      placeholder: 'Nhập lại mật khẩu mới',
      maxLength: MAX_LENGTH_PASSWORD,
      secureTextEntry: true
    }
  ],
  rule: {
    rule1: '• Mật khẩu phải bao gồm từ 6 đến 20 ký tự',
    rule2: '• Mật khẩu phải bao gồm cả chữ cái viết hoa và viết thường.',
    rule3: '• Mật khẩu phải bao gồm ít nhất 1 số.',
    rule4: '• Mật khẩu có thể bao gồm ký tự đặc biệt ~`!@#$%^&*()-_+={}[]|\\;:",./?'
  },
  button: {
    cancel: {
      title: 'Hủy',
      type: 'outline'
    },
    changePassword: {
      title: 'Đổi mật khẩu'
    }
  }
}

const profileVN = {
  title: 'Thông tin cá nhân',
  subTitle: 'Thông tin chi tiết',
  avatar: 'Ảnh đại diện',
  image: 'Tải lên ảnh đại diện',
  sign: 'Tải lên ảnh chữ ký',
  fields: {
    name: 'Họ và tên ',
    phone: 'Số điện thoại',
    email: 'Email',
    id: 'Mã nhân viên',
    position: 'Nhóm quyền',
    department: 'Bộ phận',
    area: 'Khu vực'
  },
  edit: 'Sửa',
  cancel: 'Hủy',
  save: 'Lưu'
}

const ManageShopVN = {
  title: 'Tài khoản Cửa hàng',
  view: 'Chi tiết cửa hàng',
  edit: 'Chỉnh sửa cửa hàng',
  openAccount: 'Mở khóa tài khoản',
  export: 'Xuất danh sách',
  shopList: 'Danh sách tài khoản cửa hàng',
  personalInfor: {
    title: 'Thông tin người đại diện',
    form: {
      name: 'Họ và tên',
      phone: 'Số điện thoại',
      cccd: 'CMND/CCCD'
    }
  },
  shopInfor: {
    title: 'Thông tin Cửa hàng',
    form: {
      shopName: 'Tên cửa hàng',
      taxCode: 'Mã số thuế',
      email: 'Email',
      city: 'Tỉnh/Thành phố',
      district: 'Quận/Huyện',
      ward: 'Phường/Xã',
      address: 'Địa chỉ cụ thể'
    }
  },
  billingInfor: {
    title: 'Thông tin thanh toán',
    form: {
      bank: 'Ngân hàng',
      accountOwner: 'Người thụ hưởng',
      accountNumber: 'Số tài khoản',
      paper: 'Loại giấy tờ xác minh người thụ hưởng'
    },
    paperOptions: [
      {
        value: 'idcard',
        label: 'CMND/CCCD'
      },
      {
        value: 'taxcode',
        label: 'Mã số thuế'
      }
    ]
  },
  requestChange: 'Yêu cầu thay đổi thông tin',
  noRequest: 'Không có yêu cầu',
  shop: 'CỬA HÀNG',
  ID: 'ID:',
  member: 'Hội viên',
  noMember: 'Chưa là hội viên',
  changePassword: 'Đặt lại mật khẩu',
  lockAccount: 'Khóa tài khoản',
  shopImages: 'Hình chụp cửa hàng',
  indentifications: 'Giấy tờ tùy thân người đại diện',
  font: 'Mặt trước',
  back: 'Mặt sau',
  reject: 'Từ chối',
  accept: 'Duyệt',
  adminChange: {
    title: 'Thông tin khóa tài khoản',
    lockedAt: 'Ngày khóa',
    admin: 'Người khóa',
    note: 'Lý do khóa tài khoản'
  },
  filters: {
    search: {
      title: 'Nhập từ khóa tìm kiếm',
      placeholder: 'Nhập tên, số điện thoại'
    },
    zone: {
      title: 'Vùng/miền',
      placeholder: 'Nhập tên, số điện thoại'
    },
    subzone: {
      title: 'Khu vực',
      placeholder: 'Nhập tên, số điện thoại'
    },
    city: {
      title: 'Tỉnh/Thành phố'
    },
    status: {
      title: 'Trạng thái hoạt động'
    },
    statusAccount: {
      title: 'Trạng thái tài khoản'
    }
  },
  statusOptions: [
    {
      value: 'pending',
      label: 'Chờ phê duyệt thay đổi'
    },
    {
      value: 'active',
      label: 'Đang hoạt động'
    },
    {
      value: 'inactive',
      label: 'Tạm khóa'
    },
    {
      value: 'terminated',
      label: 'Xóa vĩnh viễn'
    },
    {
      value: 'pending-removal',
      label: 'Tạm xóa'
    }
  ],
  statusAccountOptions: [
    {
      value: 'yes',
      label: 'Hội viên'
    },
    {
      value: 'no',
      label: 'Chưa là hội viên'
    }
  ],
  statusSMSBrandnameOptions: [
    {
      value: 'pending',
      label: 'Đang chờ'
    },
    {
      value: 'processing',
      label: 'Đang xử lý'
    },
    {
      value: 'failed',
      label: 'Thất bại'
    },
    {
      value: 'success',
      label: 'Thành công'
    }
  ],
  typeSMSBrandnameOptions: [
    {
      value: 'register',
      label: 'Đăng ký tài khoản'
    },
    {
      value: 'recoverpassword',
      label: 'Khôi phục mật khẩu'
    },
    {
      value: 'changepassword',
      label: 'Đổi mật khẩu'
    },
    {
      value: 'deleteaccount',
      label: 'Xoá tài khoản'
    },
    {
      value: 'resetpassword',
      label: 'Đặt lại mật khẩu'
    }
  ],
  cancel: 'Hủy',
  save: 'Lưu'
}

const ManageKTVVN = {
  title: 'Tài khoản kỹ thuật viên',
  view: 'Chi tiết kỹ thuật viên',
  edit: 'Chỉnh sửa kỹ thuật viên',
  export: 'Xuất danh sách',
  shopList: 'Danh sách tài khoản kỹ thuật viên',

  personalInfor: {
    title: 'Thông tin cá nhân',
    form: {
      name: 'Họ và tên',
      phone: 'Số điện thoại',
      cccd: 'CMND/CCCD',
      email: 'Email',
      city: 'Tỉnh/Thành phố',
      district: 'Quận/Huyện',
      ward: 'Phường/Xã',
      address: 'Địa chỉ cụ thể'
    }
  },

  billingInfor: {
    title: 'Thông tin thanh toán',
    form: {
      bank: 'Ngân hàng',
      accountOwner: 'Người thụ hưởng',
      accountNumber: 'Số tài khoản',
      paper: 'Loại giấy tờ xác minh người thụ hưởng'
    },
    paperOptions: [
      {
        value: 'idcard',
        label: 'CMND/CCCD'
      },
      {
        value: 'taxcode',
        label: 'Mã số thuế'
      }
    ]
  },
  requestChange: 'Yêu cầu thay đổi thông tin',
  noRequest: 'Không có yêu cầu',
  ktv: 'KỸ THUẬT VIÊN',
  ID: 'ID:',
  changePassword: 'Đặt lại mật khẩu',
  lockAccount: 'Khóa tài khoản',
  openAccount: 'Mở khóa tài khoản',
  indentifications: 'Hình chụp CMND/CCCD',
  font: 'Mặt trước',
  back: 'Mặt sau',
  reject: 'Từ chối',
  accept: 'Duyệt',
  adminChange: {
    title: 'Thông tin khóa tài khoản',
    lockedAt: 'Ngày khóa',
    admin: 'Người khóa',
    note: 'Lý do khóa tài khoản'
  },
  filters: {
    search: {
      title: 'Nhập từ khóa tìm kiếm',
      placeholder: 'Nhập tên, số điện thoại'
    },
    zone: {
      title: 'Vùng/miền',
      placeholder: 'Nhập tên, số điện thoại'
    },
    subzone: {
      title: 'Khu vực',
      placeholder: 'Nhập tên, số điện thoại'
    },
    city: {
      title: 'Thành phố'
    },
    status: {
      title: 'Trạng thái'
    }
  },
  statusOptions: [
    {
      value: 'pending',
      label: 'Chờ phê duyệt thay đổi'
    },
    {
      value: 'active',
      label: 'Đang hoạt động'
    },
    {
      value: 'inactive',
      label: 'Tạm khóa'
    },
    {
      value: 'terminated',
      label: 'Xóa vĩnh viễn'
    },
    {
      value: 'pending-removal',
      label: 'Tạm xóa'
    }
  ],
  cancel: 'Hủy',
  save: 'Lưu'
}

const PortfolioVN = {
  title: 'Quản lý danh mục sản phẩm',
  list: 'Danh sách danh mục',
  filter: {
    placeholderSearch: 'Nhập tên danh mục',
    typePortfolio: {
      label: 'Loại danh mục',
      placeholder: 'Chọn loại danh mục'
    }
  },
  import: 'Import',
  export: 'Export',
  importModal: {
    title: 'Import danh mục',
    note1: '- Chỉ được tải 1 tệp duy nhất / lần',
    note2: '- Chỉ chấp nhận tệp dưới định dạng excel',
    note3: '- Số lượng bản ghi tối đa / tệp: 10,000 bản ghi',
    download: 'Tải về biểu mẫu',
    dndHere: 'Kéo thả tệp ở đây',
    or: 'Hoặc',
    chooseHere: 'Chọn tệp tại đây'
  },
  add: 'Thêm mới',
  table: {
    STT: 'STT',
    type: 'Loại danh mục',
    name: 'Tên danh mục',
    parent: 'Danh mục cha',
    code: 'Mã kích hoạt',
    accumulate: 'Tích lũy',
    actived: 'Kích hoạt',
    action: 'Thao tác'
  },
  addPortfolio: 'Thêm mới danh mục',
  editPortfolio: 'Chỉnh sửa danh mục sản phẩm',
  viewPortfolio: 'Xem chi tiết danh mục',
  haveCode: 'Có mã',
  noCode: 'Không mã',
  form: {
    type: {
      name: 'type',
      label: 'Loại danh mục',
      placeholder: 'Chọn loại danh mục'
    },
    name: {
      name: 'name',
      label: 'Tên danh mục',
      placeholder: 'Nhập tên danh mục'
    },
    branch: {
      name: 'branch',
      label: 'Ngành',
      placeholder: 'Chọn ngành'
    },
    industry: {
      name: 'industry',
      label: 'Ngành hàng',
      placeholder: 'Chọn ngành hàng'
    },
    group: {
      name: 'group',
      label: 'Nhóm sản phẩm',
      placeholder: 'Chọn Nhóm sản phẩm'
    },
    code: {
      name: 'code',
      label: 'Mã kích hoạt'
    },
    reward: 'Tính thưởng',
    accumulate: {
      name: 'accumulate',
      label: 'Tích lũy'
    },
    actived: {
      name: 'actived',
      label: 'Kích hoạt'
    },
    model: {
      name: 'model',
      label: 'Nhập model',
      placeholder: '',
      required: 'Vui lòng chọn model'
    }
  },
  typeOptions: {
    industry: {
      label: 'Danh mục Ngành',
      value: 'industry'
    },
    industrySector: {
      label: 'Danh mục Ngành hàng',
      value: 'industrySector'
    },
    category: {
      label: 'Danh mục Nhóm sản phẩm',
      value: 'category'
    },
    model: {
      label: 'Danh mục Model',
      value: 'model'
    }
  },
  buttons: {
    cancel: 'Hủy',
    save: 'Lưu',
    close: 'Đóng',
    edit: 'Chỉnh sửa'
  }
}

const ProductVN = {
  title: 'Quản lý sản phẩm',
  list: 'Danh sách sản phẩm',
  filter: {
    placeholderSearch: 'Nhập Serial, Model',
    industrySector: {
      label: 'Ngành hàng',
      placeholder: 'Chọn Ngành hàng'
    },
    category: {
      label: 'Nhóm sản phẩm',
      placeholder: 'Chọn Nhóm sản phẩm',
      placeholder: 'Chọn Nhóm sản phẩm'
    },
    zone: {
      label: 'Miền',
      placeholder: 'Chọn Miền'
    },
    subZone: {
      label: 'Vùng',
      placeholder: 'Chọn Vùng'
    },
    status: {
      label: 'Trạng thái',
      placeholder: 'Chọn Trạng thái'
    }
  },
  import: 'Import',
  export: 'Export',
  add: 'Thêm mới',
  update: 'Update C2',

  importModal: {
    title: 'Import sản phẩm',
    note1: '- Chỉ được tải 1 tệp duy nhất / lần',
    note2: '- Chỉ chấp nhận tệp dưới định dạng excel',
    note3: '- Số lượng bản ghi tối đa / tệp: 10,000 bản ghi',
    download: 'Tải về biểu mẫu',
    dndHere: 'Kéo thả tệp ở đây',
    or: 'Hoặc',
    chooseHere: 'Chọn tệp tại đây'
  },
  table: {
    STT: 'STT',
    industry: 'Ngành',
    industrySector: 'Ngành hàng',
    category: 'Nhóm sản phẩm',
    type: 'Loại sản phẩm',
    model: 'Model',
    serial: 'Serial',
    warrantyCode: 'Mã bảo hành',
    channel: 'Kênh kinh doanh',
    subZone: 'Miền',
    status: 'Trạng thái',
    action: 'Thao tác'
  },
  active: 'Đã kích hoạt',
  inActive: 'Chưa tích luỹ',
  recall: 'Thu hồi',
  accumulated: 'Đã tích lũy',
  addProduct: 'Thêm mới sản phẩm',
  editProduct: 'Chỉnh sửa sản phẩm',
  viewProduct: 'Xem chi tiết sản phẩm',

  form: {
    serial: {
      name: 'serial',
      label: 'Serial sản phẩm',
      placeholder: 'Nhập serial'
    },
    zone: {
      name: 'zone',
      label: 'Miền',
      placeholder: 'Chọn miền'
    },
    industry: {
      name: 'industry',
      label: 'Ngành'
    },
    industrySector: {
      name: 'industrySector',
      label: 'Ngành hàng'
    },
    category: {
      name: 'category',
      label: 'Nhóm sản phẩm'
    },
    model: {
      name: 'model',
      label: 'Model',
      placeholder: 'Chọn model'
    },
    channel: {
      name: 'channel',
      label: 'Kênh kinh doanh',
      placeholder: 'Chọn kênh kinh doanh'
    },
    type: {
      name: 'type',
      label: 'Loại sản phẩm',
      placeholder: 'Chọn loại sản phẩm'
    },
    exportDate: {
      name: 'exportDate',
      label: 'Ngày xuất kho'
    },
    price: {
      name: 'price',
      label: 'Giá C2',
      placeholder: 'Nhập giá C2'
    }
  },

  buttons: {
    cancel: 'Hủy',
    save: 'Lưu',
    close: 'Đóng',
    edit: 'Chỉnh sửa'
  }
}

const ProductActivationVN = {
  title: 'Quản lý mã bảo hành',
  list: 'Danh sách mã bảo hành',
  filter: {
    placeholderSearch: 'Nhập mã bảo hành',
    industrySector: {
      label: 'Ngành hàng',
      placeholder: 'Chọn Ngành hàng'
    },
    category: {
      label: 'Nhóm sản phẩm',
      placeholder: 'Chọn Nhóm'
    },
    zone: {
      label: 'Miền',
      placeholder: 'Chọn Miền'
    },
    status: {
      label: 'Trạng thái',
      placeholder: 'Chọn Trạng thái'
    }
  },
  import: 'Import',
  export: 'Export',
  add: 'Thêm mới',
  update: 'Update C2',

  importModal: {
    title: 'Import mã bảo hành',
    note1: '- Chỉ được tải 1 tệp duy nhất / lần',
    note2: '- Chỉ chấp nhận tệp dưới định dạng excel',
    note3: '- Số lượng bản ghi tối đa / tệp: 10,000 bản ghi',
    download: 'Tải về biểu mẫu',
    dndHere: 'Kéo thả tệp ở đây',
    or: 'Hoặc',
    chooseHere: 'Chọn tệp tại đây'
  },
  table: {
    code: 'Mã bảo hành',
    industry: 'Ngành',
    industrySector: 'Ngành hàng',
    date: 'Thời gian áp dụng',
    status: 'Trạng thái',
    action: 'Thao tác'
  },
  active: 'Đã kích hoạt',
  inActive: 'Chưa kích hoạt',
  locked: 'Đã khóa',

  addProductActivation: 'Thêm mới mã bảo hành',
  editProductActivation: 'Chỉnh sửa mã bảo hành',
  viewProductActivation: 'Xem chi tiết mã bảo hành',

  form: {
    code: {
      name: 'code',
      label: 'Mã bảo hành',
      placeholder: 'Nhập mã bảo hành'
    },
    applyDate: {
      name: 'applyDate',
      label: 'Thời gian áp dụng'
    },
    industry: {
      name: 'industry',
      label: 'Ngành',
      placeholder: 'Chọn ngành'
    },
    industrySector: {
      name: 'industrySector',
      label: 'Ngành hàng',
      placeholder: 'Chọn ngành hàng'
    },
    status: {
      name: 'status',
      label: 'Trạng thái kích hoạt'
    }
  },

  buttons: {
    cancel: 'Hủy',
    save: 'Lưu',
    close: 'Đóng',
    edit: 'Chỉnh sửa'
  }
}
const UnitVN = {
  title: 'Quản lý đơn vị bộ phận',
  list: 'Danh sách đơn vị bộ phận',
  filter: {
    title: 'Nhập từ khoá tìm kiếm',
    placeholderSearch: 'Nhập tên bộ phận'
  },
  add: 'Thêm mới',
  update: 'Update C2',
  tree: 'Điện máy gia dụng Hòa Phát',
  table: {
    code: 'Mã BP',
    name: 'Tên Bộ phận',
    rank: 'Cấp bậc',
    updateDate: 'Ngày cập nhật',
    action: 'Thao tác'
  },
  viewUnit: 'Xem chi tiết đơn vị bộ phận',
  addUnit: 'Thêm mới đơn vị bộ phận',
  editUnit: 'Chỉnh sửa đơn vị bộ phận',
  form: {
    unitTree: {
      name: 'unitTree',
      label: 'Đơn vị cấp trên',
      placeholder: 'Chọn đơn vị cha',
      required: 'Vui lòng chọn đơn vị cấp trên'
    },
    name: {
      name: 'name',
      label: 'Tên bộ phận',
      placeholder: 'Nhập tên bộ phận',
      required: 'Vui lòng nhập tên bộ phận'
    }
  },
  typeOptions: {
    industry: {
      label: 'Danh mục Ngành',
      value: 'industry'
    },
    industrySector: {
      label: 'Danh mục Ngành hàng',
      value: 'industrySector'
    },
    category: {
      label: 'Danh mục Nhóm sản phẩm',
      value: 'category'
    },
    model: {
      label: 'Danh mục Model',
      value: 'model'
    }
  },
  buttons: {
    cancel: 'Hủy',
    save: 'Lưu',
    close: 'Đóng',
    edit: 'Chỉnh sửa'
  }
}

const PermissionVN = {
  title: 'Quản lý phân quyền',
  list: 'Danh sách phân quyền',
  add: 'Thêm mới',
  addPermission: 'Thêm mới nhóm quyền',
  editPermission: 'Chỉnh sửa nhóm quyền',
  viewPermission: 'Xem nhóm quyền',
  filter: {
    title: 'Nhập từ khoá tìm kiếm',
    placeholderSearch: 'Nhập nhóm quyền'
  },
  table: {
    STT: 'STT',
    rightsGroup: 'Nhóm quyền',
    describe: 'Mô tả',
    updateDate: 'Ngày cập nhật',
    action: 'Thao tác'
  },
  form: {
    name: {
      name: 'name',
      label: 'Tên nhóm quyền',
      required: 'Vui lòng nhập tên nhóm quyền',
      placeholder: 'Nhập tên nhóm quyền'
    },
    description: {
      name: 'description',
      label: 'Mô tả',
      placeholder: 'Nhập mô tả nhóm quyền'
    },
    functionPermission: {
      name: 'functionPermission',
      label: 'Chức năng nhóm quyền',
      required: 'Vui lòng chọn chức năng nhóm quyền'
    },
    allow_duplicate_area: {
      name: 'allow_duplicate_area',
      label: 'Cho phép quản lý trùng khu vực'
    }
  }
}

const UserVN = {
  title: 'Quản lý người dùng hệ thống CMS',
  list: 'Danh sách người dùng hệ thống',
  add: 'Thêm mới',
  filters: {
    search: {
      placeholder: 'Nhập tên, số điện thoại, mã nhân viên'
    },
    permissionGroup: {
      title: 'Nhóm quyền',
      placeholder: 'Chọn nhóm quyền'
    },
    status: {
      title: 'Trạng thái',
      placeholder: 'Chọn trạng thái',
      statusOptions: [
        {
          value: 'active',
          label: 'Đang hoạt động'
        },
        {
          value: 'inactive',
          label: 'Tạm khóa'
        }
      ]
    }
  },
  table: {
    stt: 'STT',
    id: 'Mã nhân viên',
    name: 'Họ và tên',
    email: 'Email',
    part: 'Bộ phận',
    permissionZone: 'Miền',
    permissionGroup: 'Nhóm quyền',
    status: 'Trạng thái',
    action: 'Thao tác'
  },
  addTitle: 'Thêm mới tài khoản người dùng CMS',
  viewTitle: 'Xem chi tiết tài khoản người dùng CMS',
  editTitle: 'Chỉnh sửa tải khoản người dùng CMS',
  form: {
    title: 'Thông tin chi tiết',
    name: {
      name: 'name',
      label: 'Họ và tên',
      placeholder: 'Nhập họ và tên',
      required: 'Vui lòng nhập họ và tên'
    },
    phone: {
      name: 'phone',
      label: 'Số điện thoại',
      placeholder: 'Nhập số điện thoại',
      required: 'Vui lòng nhập số điện thoại',
      requiredPhone: 'Số điện thoại sai định dạng'
    },
    email: {
      name: 'email',
      label: 'Email',
      placeholder: 'Nhập Email',
      required: 'Vui lòng nhập Email',
      requiredEmail: 'Email nhập sai định dạng'
    },
    code: {
      name: 'code',
      label: 'Mã nhân viên',
      placeholder: 'Nhập mã nhân viên',
      required: 'Vui lòng nhập Mã nhân viên'
    },
    permissionGroup: {
      name: 'permissionGroup',
      label: 'Nhóm quyền',
      placeholder: 'Chọn nhóm quyền',
      required: 'Vui lòng chọn Nhóm quyền'
    },
    part: {
      name: 'part',
      label: 'Bộ phận',
      required: 'Vui lòng chọn Bộ phận'
    },
    managementArea: {
      name: 'managementArea',
      label: 'Khu vực quản lý',
      required: 'Vui lòng chọn Khu vực quản lý'
    }
  },
  changePassword: 'Đặt lại mật khẩu',
  openAccount: 'Mở khóa tài khoản',
  lockAccount: 'Khóa tài khoản'
}

const ManageProgramVN = {
  title: 'Xây dựng chương trình',
  create: 'Thêm mới',
  viewForm: 'Chi tiết chương trình',
  editForm: 'Chỉnh sửa chương trình',
  createForm: 'Thêm mới chương trình',
  openAccount: 'Mở khóa tài khoản',
  programList: 'Danh sách chương trình',
  table: {
    code: 'Mã CT',
    type: 'Loại chương trình',
    name: 'Tên chương trình',
    applyDate: 'Thời gian áp dụng',
    typeAccount: 'Loại tài khoản',
    bonus: 'Cơ cấu thưởng',
    status: 'Trạng thái'
  },
  filter: {
    search: {
      title: 'Nhập từ khóa tìm kiếm',
      placeholder: 'Nhập tên chương trình'
    },
    type: {
      title: 'Loại chương trình',
      placeholder: 'Chọn loại chương trình'
    },
    applyDate: {
      title: 'Thời gian áp dụng'
    },
    status: {
      title: 'Trạng thái',
      placeholder: 'Chọn trạng thái'
    }
  },
  condition: 'Điều kiện',
  config: 'Cơ cấu thưởng',
  form: {
    type: {
      label: 'Loại chương trình',
      placeholder: 'Chọn loại chương trình'
    },
    applyParallel: {
      label: 'Áp dụng song song',
      options: [
        {
          label: 'Không',
          value: false,
          style: {
            fontWeight: '400'
          }
        },
        {
          label: 'Có',
          value: true,
          style: {
            fontWeight: '400'
          }
        }
      ]
    },
    name: {
      label: 'Tên chương trình',
      placeholder: 'Nhập tên chương trình'
    },
    applyTime: {
      label: 'Thời gian áp dụng',
      options: [
        {
          label: 'Khoảng ngày',
          value: 'range_time',
          style: {
            fontWeight: '400'
          }
        },
        {
          label: 'Ngày cụ thể',
          value: 'each_day',
          style: {
            fontWeight: '400'
          }
        }
      ]
    },
    productApply: 'Sản phẩm áp dụng',
    industry: {
      label: 'Ngành',
      placeholder: 'Chọn ngành'
    },
    industrySector: {
      label: 'Ngành hàng',
      placeholder: 'Chọn ngành'
    },
    category: {
      label: 'Nhóm sản phẩm',
      placeholder: 'Chọn nhóm sản phẩm'
    },
    model: {
      label: 'Model',
      placeholder: 'Chọn model'
    },
    channel: {
      label: 'Kênh phân phối',
      placeholder: 'chọn kênh phân phối'
    },
    warehouseZone: {
      label: 'Miền xuất kho',
      placeholder: 'Chọn Miền'
    },
    warehouseDate: {
      label: 'Ngày xuất kho'
    },
    accountApply: 'Tài khoản áp dụng',
    applyClientType: {
      label: 'Loại tài khoản',
      placeholder: 'Chọn loại tài khoản'
    },
    rank: {
      label: 'Hạng tài khoản',
      placeholder: 'Chọn hạng tài khoản'
    },
    createAtAccount: {
      label: 'Thời gian tạo tài khoản'
    },
    zoneApply: 'Khu vực áp dụng',
    zone: {
      label: 'Miền',
      placeholder: 'Chọn miền'
    },
    subzone: {
      label: 'Vùng',
      placeholder: 'Chọn vùng'
    },
    province: {
      label: 'Tỉnh/thành phố',
      placeholder: 'Chọn tỉnh/thành phố'
    },
    district: {
      label: 'Quận/huyện',
      placeholder: 'Chọn quận/huyện'
    },

    ward: {
      label: 'Phường/xã',
      placeholder: 'Chọn phường/xã'
    },
    conditionDisplay: 'Điều kiện trưng bày',
    timeComplete: {
      label: 'Thời gian hoàn thiện hồ sơ trưng bày',
      placeholder: 'Nhập thời gian',
      unit: 'ngày'
    },
    upload: 'Tải lên biên bản Đăng ký tham gia trưng bày mẫu',
    programConfig: {
      label: 'Cơ cấu chương trình',
      options: [
        {
          label: 'Sản phẩm',
          value: 'product',
          style: {
            fontWeight: '400'
          }
        },
        {
          label: 'Gói sản phẩm',
          value: 'package',
          style: {
            fontWeight: '400'
          }
        },
        {
          label: 'Doanh thu',
          value: 'revenue',
          style: {
            fontWeight: '400'
          }
        }
      ]
    },
    screenCodeConfig: {
      options: [
        {
          label: 'Sản phẩm',
          value: 'product'
        },
        {
          label: 'Danh mục sản phẩm',
          value: 'category'
        },
        {
          label: 'Mã bảo hành',
          value: 'product_activation'
        },
        {
          label: 'Đối soát thưởng',
          value: 'reconcile'
        },
        {
          label: 'Cửa hàng',
          value: 'users'
        },
        {
          label: 'Kỹ thuật viên',
          value: 'technician'
        },
        {
          label: 'Quản lý kích hoạt',
          value: 'activation'
        },
        {
          label: 'Quản lý tích lũy',
          value: 'accumulation'
        },
        {
          label: 'Khách hàng',
          value: 'customer'
        },
        {
          label: 'Báo cáo tạm tính theo sản phẩm',
          value: 'report_by_product'
        },
        {
          label: 'Báo cáo tạm tính theo chương trình',
          value: 'report_by_programming'
        }
      ]
    },

    // product
    programAccumulation: {
      label: 'Phương thức tích lũy',
      options: [
        {
          label: 'Theo từng model',
          value: 'each_model',
          style: {
            fontWeight: '400'
          }
        },
        {
          label: 'Tất cả model',
          value: 'all_model',
          style: {
            fontWeight: '400'
          }
        }
      ]
    },
    rewardConditionType: {
      label: 'Tiêu chí tính thưởng',
      options: [
        {
          label: 'Theo số lượng',
          value: 'number',
          style: {
            fontWeight: '400'
          }
        },
        {
          label: 'Theo sản phẩm',
          value: 'product',
          style: {
            fontWeight: '400'
          }
        }
        // {
        //     label: "Theo số lượng & sản phẩm",
        //     value: "number_model",
        //     style: {
        //         fontWeight: "400",
        //     },
        // },
      ]
    },
    tableConfigNumber: 'Bảng thiết lập theo số lượng',
    tableConfigModel: 'Bảng thiết lập theo sản phẩm',
    fromQuantity: {
      label: 'Từ số lượng',
      placeholder: 'Nhập số lượng'
    },
    toQuantity: {
      label: 'Đến số lượng',
      placeholder: 'Nhập số lượng'
    },
    reward: {
      label: 'Tiền thưởng/sản phẩm',
      placeholder: 'Nhập số tiền'
    },

    // package
    programReward: {
      label: 'Loại thưởng',
      options: [
        {
          label: 'Tiền',
          value: 'money',
          style: {
            fontWeight: '400'
          }
        },
        {
          label: 'Hiện vật',
          value: 'object',
          style: {
            fontWeight: '400'
          }
        },
        {
          label: 'Tiền & Hiện vật',
          value: 'money_object',
          style: {
            fontWeight: '400'
          }
        }
      ]
    },
    quantityBonus: {
      label: 'Số lượng gói thưởng',
      placeholder: 'Chọn số gói thưởng'
    },
    quantityBonusMax: {
      label: 'Số gói xét thưởng tối đa',
      placeholder: 'Chọn số gói xét thưởng tối đa'
    },
    package: {
      maxReward: 'Nhập số lần nhận thưởng tối đa/gói',
      amountBonus: 'Nhập số tiền thưởng/gói',
      quantity: 'Nhập số lượng',
      object: 'Nhập/Chọn hiện vật',
      price: 'Nhập đơn giá quy đổi',
      totalExchange: 'Tổng giá trị quy đổi: '
    },
    // revenue
    productProgram: 'Danh sách sản phẩm thuộc chương trình',
    configProfit: 'Thiết lập hoa hồng trên doanh thu'
  },

  cancel: 'Hủy',
  save: 'Lưu',
  reject: 'Từ chối',
  accept: 'Duyệt',
  close: 'Đóng',
  edit: 'Chỉnh sửa',
  send: 'Gửi phê duyệt'
}

const ManageNotificaionVN = {
  form: {
    applySettingSending: {
      options: [
        {
          label: 'Gửi thông báo ngay',
          value: false,
          style: {
            fontWeight: '400'
          }
        },
        {
          label: 'Thiết lập thời gian gửi',
          value: true,
          style: {
            fontWeight: '400'
          }
        }
      ]
    },

    applyTime: {
      label: 'Thời gian áp dụng',
      options: [
        {
          label: 'Khoảng ngày',
          value: 'range_time',
          style: {
            fontWeight: '400'
          }
        },
        {
          label: 'Ngày cụ thể',
          value: 'each_day',
          style: {
            fontWeight: '400'
          }
        }
      ]
    }
  },

  cancel: 'Hủy',
  save: 'Lưu',
  reject: 'Từ chối',
  accept: 'Duyệt',
  close: 'Đóng',
  edit: 'Chỉnh sửa',
  send: 'Gửi phê duyệt'
}

const LocalityVN = {
  title: 'Quản lý địa bàn',
  list: 'Danh sách địa bàn',
  add: 'Thêm mới',
  addModal: 'Thêm mới địa bàn',
  viewModal: 'Xem chi tiết địa bàn',
  editModal: 'Chỉnh sửa địa bàn',
  filters: {
    search: {
      placeholder: 'Nhập tên địa bàn, mã địa bàn'
    },
    geographicalLevel: {
      title: 'Cấp địa lý',
      placeholder: 'Chọn cấp địa lý'
    },
    domain: {
      title: 'Miền',
      placeholder: 'Chọn miền'
    },
    zone: {
      title: 'Vùng',
      placeholder: 'Chọn vùng'
    }
  },
  table: {
    code: 'Mã địa bàn',
    name: 'Tên địa bàn',
    geographicalLevel: 'Cấp địa lý',
    domain: 'Miền',
    zone: 'Vùng',
    city: 'Tỉnh/Thành phố',
    district: 'Quận/Huyện',
    action: 'Thao tác'
  },
  form: {
    geographicalLevel: {
      name: 'geographicalLevel',
      label: 'Cấp địa lý',
      placeholder: 'Chọn cấp địa lý',
      required: 'Vui lòng chọn cấp địa lý'
    },
    code: {
      name: 'code',
      label: 'Mã địa bàn',
      placeholder: 'Nhập mã địa bàn',
      required: 'Vui lòng nhập mã địa bàn'
    },
    name: {
      name: 'name',
      label: 'Tên địa bàn',
      placeholder: 'Nhập tên địa bàn',
      required: 'Vui lòng nhập tên địa bàn'
    },
    domain: {
      name: 'domain',
      label: 'Miền',
      placeholder: 'Chọn miền',
      required: 'Vui lòng chọn miền'
    },
    zone: {
      name: 'zone',
      label: 'Vùng',
      placeholder: 'Chọn vùng',
      required: 'Vui lòng chọn vùng'
    },
    city: {
      name: 'city',
      label: 'Tỉnh/Thành phố',
      placeholder: 'Chọn tỉnh/thành phố',
      required: 'Vui lòng chọn tỉnh/thành phố'
    },
    district: {
      name: 'district',
      label: 'Quận/Huyện',
      placeholder: 'Chọn quận/huyện',
      required: 'Vui lòng chọn quận/huyện'
    }
  }
}

const AccountClassVN = {
  title: 'Quản lý hạng tài khoản',
  list: 'Danh sách loại hạng tài khoản',
  add: 'Thêm mới',
  addModal: 'Thêm mới loại hạng tài khoản',
  viewModal: 'Xem chi tiết loại hạng tài khoản',
  editModal: 'Chỉnh sửa loại hạng tài khoản',
  filters: {
    search: {
      placeholder: 'Nhập tên hạng tài khoản'
    },
    accoutType: {
      title: 'Loại tài khoản',
      placeholder: 'Chọn loại tài khoản'
    }
  },
  table: {
    stt: 'STT',
    name: 'Tên hạng',
    accountType: 'Loại tài khoản',
    updateDate: 'Ngày cập nhật',
    action: 'Thao tác'
  },
  form: {
    name: {
      name: 'name',
      label: 'Tên hạng',
      placeholder: 'Nhập tên hạng',
      required: 'Vui lòng nhập tên hạng'
    },
    accountType: {
      name: 'accountType',
      label: 'Loại tài khoản',
      placeholder: 'Chọn loại tài khoản',
      required: 'Vui lòng chọn loại tài khoản'
    }
  }
}

const ClassStructureVN = {
  title: 'Cơ cấu hạng tài khoản',
  list: 'Danh sách cơ cấu hạng tài khoản',
  add: 'Thêm mới',
  addModal: 'Thêm mới cơ cấu hạng tài khoản',
  viewModal: 'Xem chi tiết cơ cấu hạng tài khoản',
  editModal: 'Chỉnh sửa cơ cấu hạng tài khoản',
  filters: {
    industry: {
      title: 'Ngành hàng',
      placeholder: 'Chọn ngành hàng'
    },
    accoutType: {
      title: 'Loại tài khoản',
      placeholder: 'Chọn loại tài khoản'
    }
  },
  table: {
    stt: 'STT',
    accountType: 'Loại tài khoản',
    industry: 'Ngành hàng',
    updateDate: 'Ngày cập nhật',
    action: 'Thao tác'
  },
  form: {
    accountType: {
      name: 'accountType',
      label: 'Loại tài khoản',
      placeholder: 'Chọn loại tài khoản',
      required: 'Vui lòng chọn loại tài khoản'
    },
    industry: {
      name: 'industry',
      label: 'Ngành hàng',
      placeholder: 'Chọn ngành hàng',
      required: 'Vui lòng chọn ngành hàng'
    },
    table: {
      type: 'Loại hạng',
      point: 'Điểm tối thiếu đạt hạng',
      action: 'Thao tác'
    }
  }
}

const ClassConfigVN = {
  title: 'Cấu hình điểm',
  list: 'Danh sách cấu hình điểm',
  edit: 'Chỉnh sửa',
  filters: {
    search: {
      placeholder: 'Nhập tên Model'
    },
    branch: {
      title: 'Ngành',
      placeholder: 'Chọn ngành'
    },
    industry: {
      title: 'Ngành hàng',
      placeholder: 'Chọn ngành hàng'
    },
    products: {
      title: 'Nhóm sản phẩm',
      placeholder: 'Chọn nhóm sản phẩm'
    }
  },
  table: {
    stt: 'STT',
    branch: 'Ngành',
    industry: 'Ngành hàng',
    products: 'Nhóm sản phẩm',
    model: 'Model',
    point: 'Điểm'
  }
}

const PointHistoryVN = {
  title: 'Lịch sử tích điểm',
  list: 'Danh sách lịch sử tích điểm',
  edit: 'Chỉnh sửa',
  filters: {
    search: {
      placeholder: 'Nhập tên KTV/ Cửa hàng, ID, Model'
    },
    accountType: {
      title: 'Loại tài khoản',
      placeholder: 'Chọn loại tài khoản'
    },
    industry: {
      title: 'Ngành hàng',
      placeholder: 'Chọn ngành hàng'
    },
    products: {
      title: 'Nhóm sản phẩm',
      placeholder: 'Chọn nhóm sản phẩm'
    }
  },
  table: {
    datePoint: 'Ngày tích điểm',
    accountType: 'Loại tài khoản',
    id: 'ID tài khoản',
    name: 'Tên KTV/ Cửa hàng',
    industry: 'Ngành hàng',
    products: 'Nhóm sản phẩm',
    model: 'Model',
    serial: 'Serial',
    point: 'Điểm'
  }
}
const AccumulationVN = {
  title: 'Quản lý tích lũy',
  list: 'Danh sách tích lũy',
  filter: {
    placeholderSearch: 'Nhập Serial, Model, Cửa hàng, Số điện thoại',

    zone: {
      label: 'Miền',
      placeholder: 'Chọn Miền'
    },
    accumulationTime: 'Thời gian tích lũy'
  },
  export: 'Export',
  table: {
    serial: 'Serial',
    model: 'Model',
    category: 'Nhóm SP',
    industrySector: 'Ngành hàng',
    shop: 'Cửa hàng',
    customer: 'Khách hàng',
    zone: 'Miền',
    activatedDate: 'Ngày tích lũy',
    status: 'Trạng thái',
    action: 'Thao tác'
  },
  active: 'Đã kích hoạt',
  inActive: 'Chưa kích hoạt',

  editAccumulation: 'Chỉnh sửa thông tin tích lũy',
  viewAccumulation: 'Xem chi tiết thông tin tích lũy',

  form: {
    //product info
    productInfo: 'Thông tin sản phẩm',
    serial: 'Serial sản phẩm',
    industry: 'Ngành',
    industrySector: 'Ngành hàng',
    category: 'Nhóm sản phẩm',
    model: 'Model',
    zone: 'Miền xuất',
    channel: 'Kênh kinh doanh',
    exportDate: 'Ngày xuất kho',
    //shop info
    shopInfo: 'Thông tin cửa hàng',
    shopName: 'Tên cửa hàng',
    taxCode: 'Mã số thuế',
    phone: 'Số điện thoại',
    email: 'Email',
    province: 'Tỉnh/thành phố',
    district: 'Quận/huyện',
    ward: 'Phường/xã',
    address: 'Địa chỉ cụ thể',
    PIC: 'Người đại diện',
    cardId: 'CMND/CCCD',
    accumulationDate: 'Ngày tích lũy',
    status: 'Trạng thái'
  },

  buttons: {
    cancel: 'Hủy',
    save: 'Lưu',
    edit: 'Chỉnh sửa'
  }
}

const ActivationVN = {
  title: 'Quản lý kích hoạt',
  list: 'Danh sách kích hoạt',
  filter: {
    placeholderSearch: 'Nhập Serial, Model, thông tin Cửa hàng/KTV, Khách hàng',
    accountType: {
      label: 'Miền',
      placeholder: 'Chọn miền'
    },
    type: {
      label: 'Loại tài khoản',
      placeholder: 'Chọn loại tài khoản'
    },
    accumulationTime: 'Thời gian kích hoạt'
  },
  export: 'Export',
  table: {
    serial: 'Serial kích hoạt',
    model: 'Model',
    category: 'Nhóm SP',
    activationCode: 'Mã kích hoạt',
    accountType: 'Loại tài khoản',
    PICName: 'KTV/Cửa hàng',
    customer: 'Khách hàng',
    activatedDate: 'Ngày kích hoạt',
    status: 'Trạng thái',
    action: 'Thao tác'
  },
  active: 'Đã kích hoạt',
  inActive: 'Chưa kích hoạt',

  editActivation: 'Chỉnh sửa thông tin kích hoạt',
  viewActivation: 'Xem chi tiết thông tin kích hoạt',

  form: {
    //product info
    productInfo: 'Thông tin sản phẩm',
    serial: 'Serial sản phẩm',
    industry: 'Ngành',
    industrySector: 'Ngành hàng',
    category: 'Nhóm sản phẩm',
    model: 'Model',
    zone: 'Miền xuất',
    channel: 'Kênh kinh doanh',
    exportDate: 'Ngày xuất kho',

    //shop info
    shopInfo: 'Thông tin KTV/Cửa hàng',
    accountType: 'Loại tài khoản',
    accountName: 'Tên tài khoản',
    taxCode: 'Mã số thuế',
    PIC: 'Người đại diện',

    //consumers info
    consumersInfo: 'Thông tin người tiêu dùng',
    name: 'Họ và tên',
    phone: 'Số điện thoại',
    province: 'Tỉnh/thành phố',
    address: 'Địa chỉ cụ thể',

    //activation info
    activationInfo: 'Thông tin kích hoạt',
    activationCode: 'Mã kích hoạt',
    activationDate: 'Ngày kích hoạt',
    serialRelatetTo: 'Sản phẩm liên quan',
    status: 'Trạng thái'
  },
  buttons: {
    cancel: 'Hủy',
    save: 'Lưu',
    edit: 'Chỉnh sửa'
  }
}

const GuestVN = {
  title: 'Quản lý khách hàng',
  list: 'Danh sách khách hàng',
  editGuest: 'Chỉnh sửa thông tin khách hàng',
  filters: {
    placeholderSearch: 'Nhập tên khách hàng, số điện thoại',
    name: {
      label: 'Tên khách hàng',
      placeholder: 'Nhập tên khách hàng'
    },
    city: {
      label: 'Tỉnh/thành phố',
      placeholder: 'Chọn tỉnh/thành phố'
    },
    district: {
      label: 'Quận/huyện',
      placeholder: 'Chọn quận/huyện'
    },
    ward: {
      label: 'Phường/xã',
      placeholder: 'Chọn phường/xã'
    }
  },
  export: 'Export',
  table: {
    stt: 'STT',
    name: 'Tên khách hàng',
    phone: 'Số điện thoại',
    city: 'Tỉnh/thành phố',
    district: 'Quận/huyện',
    ward: 'Phường/xã',
    address: 'Địa chỉ chi tiết',
    action: 'Thao tác'
  },
  form: {
    name: {
      label: 'Họ và tên',
      placeholder: 'Nhập họ và tên',
      required: 'Vui lòng nhập họ và tên'
    },
    phone: {
      label: 'Số điện thoại',
      placeholder: 'Nhập số điện thoại',
      required: 'Vui lòng nhập số điện thoại'
    },
    city: {
      label: 'Tỉnh/Thành phố',
      placeholder: 'Chọn tỉnh/thành phố',
      required: 'Vui lòng chọn tỉnh/thành phố'
    },
    district: {
      label: 'Quận/Huyện',
      placeholder: 'Chọn quận/huyện',
      required: 'Vui lòng chọn quận/huyện'
    },
    ward: {
      label: 'Phường/Xã',
      placeholder: 'Chọn phường/xã',
      required: 'Vui lòng chọn phường/xã'
    },
    address: {
      label: 'Địa chỉ cụ thể',
      placeholder: 'Nhập địa chỉ cụ thể',
      required: 'Vui lòng nhập địa chỉ cụ thể'
    }
  }
}

const ManageContentVN = {
  title: 'Quản lý bài viết',
  view: 'Chi tiết bài viết',
  edit: 'Chỉnh sửa bài viết',
  add: 'Thêm mới bài viết',
  addAction: 'Thêm mới',
  table: {
    stt: 'STT',
    name: 'Tên bài viết',
    type: 'Phân loại',
    timeDisplay: 'Thời gian hiển thị',
    status: 'Trạng thái',
    updatedAt: 'Ngày cập nhật',
    action: 'Thao tác'
  },
  contentList: 'Danh sách bài viết',
  articleContent: 'Nội dung bài viết',
  approved: 'Phê duyệt',
  cancel: 'Huỷ',

  requestChange: 'Yêu cầu thay đổi thông tin',
  noRequest: 'Không có yêu cầu',

  reject: 'Từ chối',
  accept: 'Phê duyệt',
  adminChange: {
    status: 'Trạng thái',
    note: 'Lý do từ chối',
    notePause: 'Lý do tạm ngưng hiển thị',
    time: 'Thời gian phê duyệt'
  },
  filters: {
    search: {
      title: 'Nhập từ khóa tìm kiếm',
      placeholder: 'Nhập tên bài viết'
    },
    zone: {
      title: 'Miền',
      placeholder: 'Chọn miền'
    },
    subzone: {
      title: 'Vùng',
      placeholder: 'Chọn vùng'
    },
    city: {
      title: 'Tỉnh/Thành phố',
      placeholder: 'Chọn vùng'
    },
    status: {
      title: 'Trạng thái'
    },
    type: {
      title: 'Phân loại'
    },
    timeDisplay: 'Thời gian hiển thị'
  },
  generalInfor: {
    title: 'Thông tin người đại diện',
    form: {
      contentName: 'Tên bài viết',
      contentDuration: 'Thời gian hiển thị bài viêt',
      contentType: 'Phân loại',
      program: 'Chương trình liên kết'
    }
  },
  image: 'Ảnh bìa',
  chooseFile: 'Chọn tệp',
  noFile: 'Không file nào được chọn',
  detailInfor: {
    title: 'Tài khoản hiển thị',

    form: {
      accountType: 'Loại tài khoản',
      accountKTVRank: 'Hạng tài khoản KTV',
      accountShopRank: 'Hạng tài khoản cửa hàng',
      zone: 'Miền',
      region: 'Vùng',
      city: 'Tỉnh/thành phố',
      district: 'Quận/huyện',
      ward: 'Phường/xã'
    }
  },
  cancel: 'Hủy',
  save: 'Lưu'
}

const SidebarVN = {
  dashboards: 'Dashboards',
  manageShop: 'Tài khoản cửa hàng',
  manageKTV: 'Tài khoản kỹ thuật viên',
  managePortfolio: 'Danh mục sản phẩm',
  manageProduct: 'Quản lý sản phẩm',
  manageProductActivation: 'Quản lý mã bảo hành',
  manageUserCMS: 'Quản lý người dùng CMS',
  manageUnit: 'Quản lý đơn vị',
  managePermission: 'Quản lý phân quyền',
  manageUser: 'Quản lý người dùng',
  manageProgram: 'Xây dựng chương trình',
  manageLocality: 'Quản lý địa bàn',
  manageClass: 'Quản lý hạng',
  manageAccountClass: 'Quản lý loại hạng',
  manageClassStructure: 'Cơ cấu hạng',
  managePointConfig: 'Cấu hình điểm',
  managePointHistory: 'Lịch sử tích điểm',
  manageAccumulation: 'Quản lý tích lũy',
  manageActivated: 'Quản lý kích hoạt',
  manageHistory: 'Quản lý lịch sử thay đổi tích lũy-kích hoạt',
  manageArticle: 'Quản lý nội dung',
  manageContent: 'Quản lý bài viết',
  manageBaner: 'Quản lý banner',
  manageGuest: 'Quản lý người tiêu dùng',
  reportTemporaryReconciliation: 'Báo cáo tạm tính đối soát',
  remportProductBonusTemp: 'Tạm tính thưởng theo sản phẩm',
  remportProgramBonusTemp: 'Tạm tính thưởng theo chương trình',
  historyActivationAccumulation: 'Quản lý thay đổi tích lũy/kích hoạt',
  manageContact: 'Quản lý thông tin liên hệ',
  manageBank: 'Quản lý ngân hàng',
  // manageContact: 'Quản lý thông tin liên hệ',
  manageSMSBrandname: 'Quản lý SMS Brandname',
  manageNotification: 'Quản lý thông báo',
  userStatistic: 'Báo cáo thống kê hoạt động',
  accountRank: 'Báo cáo xếp hạng tài khoản',
  auditlog: 'Lịch sử thay đổi thông tin sản phẩm'
}

const BankVN = {
  title: 'Quản lý ngân hàng',
  list: 'Danh sách ngân hàng',
  add: 'Thêm mới',
  addModal: 'Thêm mới ngân hàng',
  viewModal: 'Xem chi tiết ngân hàng',
  editModal: 'Chỉnh sửa ngân hàng',
  filters: {
    search: {
      placeholder: 'Nhập tên ngân hàng'
    }
  },
  table: {
    abbreviation: 'Tên viết tắt',
    name: 'Tên ngân hàng',
    action: 'Thao tác'
  },
  form: {
    abbreviation: {
      name: 'abbreviation',
      label: 'Tên viết tắt',
      placeholder: 'Nhập tên viết tắt',
      required: 'Vui lòng nhập tên viết tắt'
    },
    name: {
      name: 'name',
      label: 'Tên ngân hàng',
      placeholder: 'Nhập tên ngân hàng',
      required: 'Vui lòng nhập tên ngân hàng'
    }
  }
}
const CommonVN = {
  datePlaceholder: 'dd/mm/yyyy'
}

const HistoryVN = {
  title: 'Quản lý thay đổi tích lũy-kích hoạt',
  list: 'Danh sách thay đổi tích lũy-kích hoạt',
  filter: {
    placeholderSearch: 'Nhập Serial, Model, thông tin Cửa hàng/KTV, Khách hàng',
    accountType: {
      label: 'Miền',
      placeholder: 'Chọn miền'
    },
    type: {
      label: 'Loại tài khoản',
      placeholder: 'Chọn loại tài khoản'
    },
    accumulationTime: 'Thời gian kích hoạt'
  },
  export: 'Export',
  table: {
    serial: 'Serial kích hoạt',
    model: 'Model',
    category: 'Nhóm SP',
    activationCode: 'Mã kích hoạt',
    accountType: 'Loại tài khoản',
    PICName: 'KTV/Cửa hàng',
    customer: 'Khách hàng',
    activatedDate: 'Ngày kích hoạt',
    status: 'Trạng thái',
    action: 'Thao tác'
  },
  active: 'Đã kích hoạt',
  inActive: 'Chưa kích hoạt',

  editActivation: 'Chỉnh sửa thông tin kích hoạt',
  viewActivation: 'Xem chi tiết thông tin kích hoạt',

  form: {
    //product info
    productInfo: 'Thông tin sản phẩm',
    serial: 'Serial sản phẩm',
    industry: 'Ngành',
    industrySector: 'Ngành hàng',
    category: 'Nhóm sản phẩm',
    model: 'Model',
    zone: 'Miền xuất',
    channel: 'Kênh kinh doanh',
    exportDate: 'Ngày xuất kho',

    //shop info
    shopInfo: 'Thông tin KTV/Cửa hàng',
    accountType: 'Loại tài khoản',
    accountName: 'Tên tài khoản',
    taxCode: 'Mã số thuế',
    PIC: 'Người đại diện',

    //consumers info
    consumersInfo: 'Thông tin người tiêu dùng',
    name: 'Họ và tên',
    phone: 'Số điện thoại',
    province: 'Tỉnh/thành phố',
    address: 'Địa chỉ cụ thể',

    //activation info
    activationInfo: 'Thông tin kích hoạt',
    activationCode: 'Mã kích hoạt',
    activationDate: 'Ngày kích hoạt',
    serialRelatetTo: 'Sản phẩm liên quan',
    status: 'Trạng thái'
  },
  buttons: {
    cancel: 'Hủy',
    save: 'Lưu',
    edit: 'Chỉnh sửa'
  }
}
const AccountVN = {
  title: 'Hoạt động tài khoản Cửa hàng và Kỹ thuật Viên',
  list: 'Danh sách tài khoản Cửa hàng và Kỹ thuật Viên',
  filters: {
    accountLevel: {
      title: 'Loại tài khoản',
      placeholder: 'Chọn lại tài khoản'
    },
    domain: {
      title: 'Miền',
      placeholder: 'Chọn miền'
    },
    zone: {
      title: 'Vùng',
      placeholder: 'Chọn vùng'
    },

    city: {
      title: 'Tỉnh/Thành Phố',
      placeholder: 'Chọn Thành phố'
    },
    industrySector: {
      title: 'Ngành hàng',
      placeholder: 'Chọn Ngành hàng'
    },
    dateA: {
      title: 'Thời gian A',
      placeholder: 'dd/mm/yyyy'
    },
    dateB: {
      title: 'Thời gian B',
      placeholder: 'dd/mm/yyyy'
    }
  },
  table: {
    code: 'Loại tài khoản',
    name: 'Tên Cửa hàng/KTV',
    phoneNumber: 'Số điện thoại',
    industrySector: 'Ngành hàng',
    city: 'Tỉnh/Thành phố',
    text1: 'Số lượng tích lũy theo thời gian A',
    text2: 'Số lượng tích lũy theo thời gian B',
    text3: 'Tỷ lệ tích lũy theo thời gian A và B',
    text4: 'Số lượng kích hoạt thời gian A',
    text5: 'Số lượng kích hoạt thời gian B',
    text6: 'Tỷ lệ kích hoạt theo thời gian A và B'
  },
  form: {
    code: {
      name: 'code',
      label: 'Mã địa bàn',
      placeholder: 'Nhập mã địa bàn',
      required: 'Vui lòng nhập mã địa bàn'
    },
    name: {
      name: 'name',
      label: 'Tên địa bàn',
      placeholder: 'Nhập tên địa bàn',
      required: 'Vui lòng nhập tên địa bàn'
    },
    phoneNumber: {
      name: 'phoneNumber',
      label: 'Số điện thoại',
      placeholder: 'Nhập số điện thoại',
      required: 'Vui lòng nhập số điện thoại'
    },
    domain: {
      name: 'domain',
      label: 'Miền',
      placeholder: 'Chọn miền',
      required: 'Vui lòng chọn miền'
    },
    zone: {
      name: 'zone',
      label: 'Vùng',
      placeholder: 'Chọn vùng',
      required: 'Vui lòng chọn vùng'
    },
    city: {
      name: 'city',
      label: 'Tỉnh/Thành phố',
      placeholder: 'Chọn tỉnh/thành phố',
      required: 'Vui lòng chọn tỉnh/thành phố'
    }
  }
}
const AccountStatisticsVN = {
  title: 'Thống kê tài khoản',
  list_1: 'Thống kê tài khoản tham gia và thực hiện tích lũy',
  List_2: 'Thống kê tài khoản tham gia và thực hiện kích hoạt',
  filters: {
    accountLevel: {
      title: 'Loại tài khoản',
      placeholder: 'Chọn lại tài khoản'
    },
    domain: {
      title: 'Miền',
      placeholder: 'Chọn miền'
    },
    zone: {
      title: 'Vùng',
      placeholder: 'Chọn vùng'
    },

    city: {
      title: 'Tỉnh/Thành Phố',
      placeholder: 'Chọn Thành phố'
    },
    industrySector: {
      title: 'Ngành hàng',
      placeholder: 'Chọn Ngành hàng'
    },
    date: {
      title: 'Tháng',
      placeholder: 'Chọn tháng'
    }
  },
  table: {
    code: 'Loại tài khoản',
    zone: 'Khu vực',
    industrySector: 'Ngành hàng',
    text1: 'Tổng số tài khoản tham  gia tích lũy trước chương trình',
    text2: 'Mục tiêu đạt tài khoản tháng',
    text3: 'Mục tiêu đạt sản lượng tích lũy',
    text4: 'Tổng số tài khoản tham gia tích lũy trong tháng',
    text5: 'Tổng số tích lũy trong tháng',
    text6: 'Tỷ lệ tài khoản tham gia tích lũy trong tháng/Mục tiêu',
    text7: 'Tỷ lệ tích lũy trong tháng/Mục tiêu',
    text8: 'Xếp hạng'
  },
  form: {
    code: {
      name: 'code',
      label: 'Mã địa bàn',
      placeholder: 'Nhập mã địa bàn',
      required: 'Vui lòng nhập mã địa bàn'
    },
    name: {
      name: 'name',
      label: 'Tên địa bàn',
      placeholder: 'Nhập tên địa bàn',
      required: 'Vui lòng nhập tên địa bàn'
    },
    phoneNumber: {
      name: 'phoneNumber',
      label: 'Số điện thoại',
      placeholder: 'Nhập số điện thoại',
      required: 'Vui lòng nhập số điện thoại'
    },
    domain: {
      name: 'domain',
      label: 'Miền',
      placeholder: 'Chọn miền',
      required: 'Vui lòng chọn miền'
    },
    zone: {
      name: 'zone',
      label: 'Vùng',
      placeholder: 'Chọn vùng',
      required: 'Vui lòng chọn vùng'
    },
    city: {
      name: 'city',
      label: 'Tỉnh/Thành phố',
      placeholder: 'Chọn tỉnh/thành phố',
      required: 'Vui lòng chọn tỉnh/thành phố'
    }
  }
}
export {
  AccountStatisticsVN,
  signInVN,
  profileMenuVN,
  changePasswordVN,
  profileVN,
  SidebarVN,
  ManageShopVN,
  ManageKTVVN,
  PortfolioVN,
  ProductVN,
  UnitVN,
  CommonVN,
  PermissionVN,
  ProductActivationVN,
  UserVN,
  ManageProgramVN,
  LocalityVN,
  AccountClassVN,
  ClassStructureVN,
  ClassConfigVN,
  PointHistoryVN,
  AccumulationVN,
  ActivationVN,
  GuestVN,
  ManageContentVN,
  BankVN,
  HistoryVN,
  ManageNotificaionVN,
  AccountVN
}
