import React from 'react'
import { Input } from 'antd'

export const FormInput = ({ ...props }) => {
  const { disabled, onChange, ...rest } = props
  // const [field, meta, helpers] = useField({ name })

  return (
    <Input
      onEnter
      style={{minWidth: 100, width: '100%' }}
      disabled={disabled}
      size='large'
      onChange={onChange}
      {...rest}
    />
  )
}
