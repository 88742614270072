import { Space } from 'antd'
import Spinners from 'components/Common/Spinner'
import { TableSimple } from 'components/Common/TableCustom'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, Col, Row, UncontrolledTooltip } from 'reactstrap'
import React from 'react'
import RewardVerifyFilter from './components/RewardVerifyFilter'
import { toast } from 'react-toastify'
import { exportAccountingByProgrammingFetcherAPI, getReportAccountingSearchByProgramingAPI } from 'helpers/backend_helper'
import { ApplyDuration, AvgTotal, AvgTotalUser, Status, Type } from './components/RewardVerifyCol'
import TableContainer from 'components/Common/TableContainer'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import moment from 'moment'

function RewardVerifyV2({ type }) {
  document.title = 'Accounting ' + type + 'list'
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 10,
    totalRows: 100
  })
  const [queryParams, setQueryParams] = useState({
    search: '',
    sortBy: []
  })
  const selectAccumulationsState = (state) => state.AccumulationReducer
  const AccumulationsProperties = createSelector(selectAccumulationsState, (reducer) => ({
    accumulations: reducer.accumulations,
    loading: reducer.loading,
    total: reducer.total
  }))

  const selectProfileState = (state) => state.Profile
  const ProfileProperties = createSelector(selectProfileState, (profile) => ({
    profile: profile.profile
  }))
  const { profile } = useSelector(ProfileProperties)

  const [data, setData] = useState([])
  const [value, setValue] = useState()
  const [total, setTotal] = useState()
  const [filter, setFilter] = useState({})
  const [page, setPage] = useState(1)

  useEffect(() => {
    handleGetListTable()
  }, [filter, type])

  const handleGetListTable = async () => {
    try {
      // const res = await getPointConfigListAPI(filter)
      const res = await getReportAccountingSearchByProgramingAPI({ ...filter, type })

      if (res.data) {
        setData(res.data)
      }

      if (res.meta) {
        setTotal(res.meta.totalItems)
      }

      if (res.value) {
        setValue(res.value)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const handleFilter = (filterValue) => {
    const transformedFilter = {
      ...filterValue,
      timeApplied: filterValue.timeApplied.map((x) => dayjs(x).format('YYYY-MM-DD'))
    }
    setQueryParams((prev) => ({ ...prev, transformedFilter }))
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Mã CT',
        accessor: 'code',
        hideSort: true,
        Cell: (cellProps) => {
          return cellProps.value
        }
      },

      {
        Header: 'Loại CT',
        accessor: 'type',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Type {...cellProps} />
        }
      },
      {
        Header: 'Tên Chương trình',
        accessor: 'name',
        hideSort: true,
        minWidth: '200px'
      },
      {
        Header: 'Thời gian áp dụng',
        // accessor: 'programming',
        accessor: (cell) => {
          return cell
        },
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ApplyDuration {...cellProps} />
        }
      },
      {
        Header: 'Số lượng tham gia',
        hideSort: true,
        // accessor: 'total'
        accessor: (cell) => {
          return cell
        },
        Cell: (cellProps) => {
          return <AvgTotalUser {...cellProps} />
        }
      },
      {
        Header: 'Số lượng mã/Số thanh toán',
        accessor: (cell) => {
          return cell
        },
        hideSort: true,
        Cell: (cellProps) => {
          return <AvgTotal {...cellProps} />
        }
      },
      {
        Header: 'Trạng thái',
        accessor: 'status',
        hideSort: true,
        Cell: (cellProps) => <Status {...cellProps} />
      },
      {
        Header: 'Thao tác',
        accessor: 'action',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          let linkToLog
          let linkToView
          const encodedName = encodeURIComponent(cellProps?.row?.original?.name);
          //link xác nhận trả thưởng
          if (cellProps?.row?.original?.configType == 'package') {
            if (cellProps?.row?.original?.type == 'accumulation' || cellProps?.row?.original?.type == 'activation') {
              linkToLog = `/detail-reward-confirm-report/${type}/${cellProps.row.original.id}/${encodedName}`
            } else if (cellProps?.row?.original?.type == 'display') {
              linkToLog = `/detail-reward-confirm-report/${type}/${cellProps.row.original.id}/${encodedName}`
            }
          } else {
            linkToLog = `/detail-reward-confirm-report/${type}/${cellProps.row.original.id}/${encodedName}`
          }

          ////link view
          // debugger
          // console.log('cellProps.row.original', cellProps.row.original)
          if (cellProps?.row?.original?.configType == 'package') {
            if (cellProps?.row?.original?.type == 'accumulation' || cellProps?.row?.original?.type == 'activation') {
              linkToView = `/detail-reward-confirm-report/${type}/${cellProps.row.original.id}/${encodedName}`
            } else if (cellProps?.row?.original?.type == 'display') {
              linkToView = `/detail-reward-confirm-report/${type}/${cellProps.row.original.id}/${encodedName}`
            }
          } else {
            linkToView = `/detail-reward-confirm-report/${type}/${cellProps.row.original.id}/${encodedName}`
          }

          return (
            <ul className='list-unstyled hstack gap-1 mb-0'>
              <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                <Link
                  to={linkToView}
                  className='btn btn-sm btn-soft-primary'
                  id={`viewtooltip-${cellProps.row.original.id}`}
                >
                  <i className='mdi mdi-eye-outline' />
                  <UncontrolledTooltip placement='top' target={`viewtooltip-${cellProps.row.original.id}`}>
                    Xem
                  </UncontrolledTooltip>
                </Link>
              </li>
              {/* <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                <Link
                  to={linkToLog}
                  className='btn btn-sm btn-soft-primary'
                  id={`viewtooltip-${cellProps.row.original.id}`}
                >
                  <i className='mdi mdi-file-check' />
                  <UncontrolledTooltip placement='top' target={`viewtooltip-${cellProps.row.original.id}`}>
                    Đối soát
                  </UncontrolledTooltip>
                </Link>
              </li>
              {cellProps.row.original.isMember == false &&
                cellProps.row.original.status !== 'terminated' &&
                cellProps.row.original.status !== 'pending-removal' &&
                cellProps.row.original.status !== 'inactive' && (
                  <li>
                    <Link
                      to='#'
                      className='btn btn-sm btn-soft-success'
                      onClick={() => {
                        const shopData = cellProps.row.original
                        setItemSelect(shopData)
                        setShowMember(true)
                        // onAcceptMember({ user_id: shopData.id })
                      }}
                      id={`accepttooltip-${cellProps.row.original.id}`}
                    >
                      <i className='mdi mdi-check' />
                      <UncontrolledTooltip
                        trigger='hover'
                        placement='top'
                        target={`accepttooltip-${cellProps.row.original.id}`}
                      >
                        Đồng ý phê duyệt hội viên
                      </UncontrolledTooltip>
                    </Link>
                  </li>
                )}
              <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                <Link
                  to={`/manage-shop/view/${cellProps.row.original.id}`}
                  className='btn btn-sm btn-soft-success'
                  id={`viewtooltip-${cellProps.row.original.id}`}
                >
                  <i className='mdi mdi-arrow-down' />
                  <UncontrolledTooltip
                    trigger='hover'
                    placement='top'
                    target={`viewtooltip-${cellProps.row.original.id}`}
                  >
                    Xem
                  </UncontrolledTooltip>
                </Link>
              </li> */}
            </ul>
          )
        }
      }
    ],
    [value]
  )

  const handleSortBy = (value) => {
    setQueryParams((prev) => ({ ...prev, sortBy: value }))
  }

  const setGlobalFilterUser = async (data) => {
    data.start_date = data.start_date?.startOf('day')?.toDate()
    data.end_date = data.end_date?.endOf('day')?.toDate()

    setFilter(data)
    setPage(1)
  }

  const goToPage = (page) => {
    setFilter({ ...filter, page })
    // setEdit(false)
    setPage(page)
  }

  const handleClickExport = async () => {
    setLoading(true)
    try {
      const response = await exportAccountingByProgrammingFetcherAPI({ ...filter, type })
      const data = response.headers.get('Content-Type')
      if (data !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        toast('Hệ thống đang xử lý. Tệp của bạn đã được gửi tới email ' + profile?.email + ' trong vài phút', {
          type: 'success'
        })
      } else {
        try {
          const blob = await response.blob() // Fetch the blob
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${moment().format('yyMMDD')}_Danh sach tra thuong chuong trinh.xlsx`) // Tên file
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          toast('Export file thành công!', { type: 'success' })
        } catch (err) {
          console.log('err', err)
        }
      }
    } catch (error) {}
    setLoading(false)
  }

  return (
    <div className='page-content'>
      <div className='page-title-box container-fluid'>
        <h4 className=' mb-sm-0 font-size-18 pb-4'>Log kế toán</h4>
        {loading ? (
          <Spinners setLoading={setLoading} />
        ) : (
          <Row>
            <Col lg='12'>
              <Card>
                <CardBody className='border-bottom'>
                  <div className='d-flex align-items-center'>
                    <h5 className='mb-0 card-title flex-grow-1'>{''}</h5>
                    <div className='flex-shrink-0'>
                      <button onClick={() => handleClickExport()} className='btn btn-primary me-2 pe-4'>
                        <i className='mdi mdi-database-export me-1 ms-2' />
                        {'Export'}
                      </button>
                    </div>
                  </div>
                </CardBody>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={data}
                    isGlobalFilter={true}
                    setGlobalFilterUser={setGlobalFilterUser}
                    isProgramBonusTempFilter={true}
                    isUpdateFilter={type}
                    placeholderSearch={'Nhập tên chương trình'}
                    isPagination={true}
                    iscustomPageSizeOptions={false}
                    isShowingPageLength={false}
                    customPageSize={10}
                    tableClass='table-bordered align-middle nowrap mt-2'
                    paginationDiv='col-sm-12 col-md-7'
                    pagination='pagination justify-content-end pagination-rounded'
                    useAPIChangePaging
                    currentPage={page}
                    gotoPageCustom={goToPage}
                    total={total}
                    label={'Tên chương trình/ mã chương trình'}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </div>
    </div>
  )
}

export default RewardVerifyV2
