import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'

//import components
import Breadcrumbs from 'components/Common/Breadcrumb'

import { getManageShop as onGetManageShop } from 'store/actions'

import { Location, Status, ShopID, AccountName, ShopName, CreatedDate, StatusAccount } from './ManageShopCol'

//redux
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

import { Col, Row, UncontrolledTooltip, Card, CardBody } from 'reactstrap'
import Spinners from 'components/Common/Spinner'
import { toast } from 'react-toastify'
import TableContainer from 'components/Common/TableContainer'
import { ManageShopVN } from 'constants/vn'
import ConfirmModal from 'components/Common/ConfirmModal'
import {
  acceptMemberAPI,
  checkExportFileAPI,
  exportListAPI,
  exportListFetcherAPI,
  lockAccountAPI,
  lockShopAccountAPI
} from 'helpers/backend_helper'
import { adjustEndDate, checkIsLocked, formateDate, getStorage, setStorage } from 'helpers/global_helper'
import fileDownload from 'js-file-download'
import PermissionWrapper from 'components/Common/PermissionWrapper'
import usePermission from 'hooks/usePermission'
import { USER_ACCOUNT_MANAGEMENT_CODE, SCREEN_CODE } from 'constants/permission-code'
import { success } from 'toastr'
import moment from 'moment'
import { reset } from 'redux-form'

function ManageShop() {
  //meta title
  document.title = 'Shop List | HPG'
  const screenCode = SCREEN_CODE.USER_ACCOUNT_MANAGEMENT

  const dispatch = useDispatch()
  const hasPermission = usePermission({
    screenCode,
    code: USER_ACCOUNT_MANAGEMENT_CODE.ADMIN_GET_USER_PROFILE
  })

  const selectShopsState = (state) => state.ShopReducer
  const ShopsProperties = createSelector(selectShopsState, (shopReducer) => ({
    shops: shopReducer.shops,
    loading: shopReducer.loading,
    total: shopReducer.total
  }))

  const { shops, loading, total } = useSelector(ShopsProperties)

  const selectProfileState = (state) => state.Profile
  const ProfileProperties = createSelector(selectProfileState, (profile) => ({
    profile: profile.profile
  }))

  const { profile } = useSelector(ProfileProperties)

  const [isLoading, setLoading] = useState(loading)
  const [showLockAccount, setShowLockAccount] = useState(false)
  const [showMember, setShowMember] = useState(false)
  const [selected, setSelected] = useState()
  const [filter, setFilter] = useState()

  useEffect(() => {
    const filterMangeShop = getStorage('filterManageShop')
    setFilter(filterMangeShop)
    dispatch(onGetManageShop(filterMangeShop))
  }, [dispatch])

  const onClickLock = (id) => {
    setSelected(id)
    setShowLockAccount(true)
  }

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'employeeCode',
        filterable: true,
        disableSortBy: true,

        hideSort: true,
        Cell: (cellProps) => {
          return <ShopID {...cellProps} />
        }
      },
      {
        Header: 'Số điện thoại',
        accessor: 'phone',
        filterable: true,
        Cell: (cellProps) => {
          return <AccountName {...cellProps} />
        }
      },
      {
        Header: 'Tên cửa hàng',
        accessor: 'shopName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ShopName {...cellProps} />
        }
      },
      {
        Header: 'Miền',
        accessor: 'zoneName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Location {...cellProps} />
        }
      },
      {
        Header: 'Tỉnh/Thành phố',
        accessor: 'provinceName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Location {...cellProps} />
        }
      },
      {
        Header: 'Quận/ Huyện',
        accessor: 'districtName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Location {...cellProps} />
        }
      },
      {
        Header: 'Phường xã',
        accessor: 'wardName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Location {...cellProps} />
        }
      },
      {
        Header: 'Ngày tạo tài khoản',
        accessor: 'createdAt',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <CreatedDate {...cellProps} />
        }
      },
      {
        Header: 'Trạng thái tài khoản',
        accessor: 'isMember',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <StatusAccount {...cellProps} />
        }
      },
      {
        Header: 'Trạng thái hoạt động',
        accessor: 'status',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Status {...cellProps} />
        }
      },
      {
        Header: 'Thao tác',
        accessor: 'action',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        show: hasPermission,
        Cell: (cellProps) => {
          // console.log(cellProps)
          return (
            <ul className='list-unstyled hstack gap-1 mb-0'>
              <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                <Link
                  to={`/manage-shop/view/${cellProps.row.original.id}`}
                  className='btn btn-sm btn-soft-primary'
                  id={`viewtooltip-${cellProps.row.original.id}`}
                >
                  <i className='mdi mdi-eye-outline' />
                  <UncontrolledTooltip
                    trigger='hover'
                    placement='top'
                    target={`viewtooltip-${cellProps.row.original.id}`}
                  >
                    Xem
                  </UncontrolledTooltip>
                </Link>
              </li>
              <PermissionWrapper screenCode={screenCode} code={USER_ACCOUNT_MANAGEMENT_CODE.ADMIN_UPDATE_USER_PROFILE}>
                {cellProps.row.original.status !== 'terminated' &&
                  cellProps.row.original.status !== 'pending-removal' && (
                    <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                      <Link
                        to={`/manage-shop/edit/${cellProps.row.original.id}`}
                        className='btn btn-sm btn-soft-info'
                        id={`edittooltip-${cellProps.row.original.id}`}
                      >
                        <i className='mdi mdi-pencil-outline' />
                        <UncontrolledTooltip
                          trigger='hover'
                          placement='top'
                          target={`edittooltip-${cellProps.row.original.id}`}
                        >
                          Sửa
                        </UncontrolledTooltip>
                      </Link>
                    </li>
                  )}
              </PermissionWrapper>
              <PermissionWrapper code={USER_ACCOUNT_MANAGEMENT_CODE.ADMIN_APPROVE_USER_MEMBER} screenCode={screenCode}>
                {cellProps.row.original.isMember == false &&
                  cellProps.row.original.status !== 'terminated' &&
                  cellProps.row.original.status !== 'pending-removal' &&
                  cellProps.row.original.status !== 'inactive' && (
                    <li>
                      <Link
                        to='#'
                        className='btn btn-sm btn-soft-success'
                        onClick={() => {
                          const shopData = cellProps.row.original
                          setItemSelect(shopData)
                          setShowMember(true)
                        }}
                        id={`accepttooltip-${cellProps.row.original.id}`}
                      >
                        <i className='mdi mdi-check' />
                        <UncontrolledTooltip
                          trigger='hover'
                          placement='top'
                          target={`accepttooltip-${cellProps.row.original.id}`}
                        >
                          Đồng ý phê duyệt hội viên
                        </UncontrolledTooltip>
                      </Link>
                    </li>
                  )}
              </PermissionWrapper>
            </ul>
          )
        }
      }
    ],
    [hasPermission]
  )

  const [itemSelect, setItemSelect] = useState()

  const setGlobalFilterUser = async (data) => {
    const newData = adjustEndDate(data)
    setStorage('filterManageShop', newData)
    setFilter(newData)
    dispatch(onGetManageShop(newData))
  }

  const handleSort = (sortBy) => {
    if (sortBy.length > 0) {
      setFilter({ ...filter, sort_field: sortBy[0].id, sort_order: sortBy[0].desc ? 'desc' : 'asc' })
      // dispatch(onGetManageShop({ ...filter, sort_field: sortBy[0].id, sort_order: sortBy[0].desc ? 'desc' : 'asc' }))
    } else {
      setFilter({ ...filter, sort_field: '', sort_order: '' })
      // dispatch(onGetManageShop({ ...filter, sort_field: '', sort_order: '' }))
    }
  }

  const handleClickExport = async () => {
    setLoading(true)
    try {
      const response = await exportListFetcherAPI({ ...filter, client: 'user' })
      const data = response.headers.get('Content-Type')
      if (data !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        toast('Hệ thống đang xử lý. Tệp của bạn đã được gửi tới email ' + profile?.email + ' trong vài phút', {
          type: 'success'
        })
      } else {
        try {
          const blob = await response.blob() // Fetch the blob
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${moment().format('yyMMDD')}_Danh sach tai khoan cua hang.xlsx`) // Tên file
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          toast('Export file thành công!', { type: 'success' })
        } catch (err) {
          console.log('err', err)
        }
      }
    } catch (error) {}
    setLoading(false)
  }

  const onLockAccount = async () => {
    try {
      const res = await lockShopAccountAPI({
        id: selected.id,
        status: checkIsLocked(selected.status) ? 'active' : 'inactive'
      })
      if (res) {
        toast(res.message, { type: 'success' })
        setShowLockAccount(false)
      }
    } catch (error) {
      console.log(error)
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const onAcceptMember = async () => {
    try {
      const res = await acceptMemberAPI({ user_id: itemSelect.id })
      if (res) {
        toast('Duyệt hội viên thành công.', { type: 'success' })
        dispatch(onGetManageShop())
        setShowMember(false)
      }
    } catch (error) {
      console.log(error)
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const goToPage = (page) => {
    setFilter({ ...filter, page: page })
    dispatch(onGetManageShop({ ...filter, page: page }))
  }

  const permissionColumns = useMemo(() => columns.filter((column) => column.show !== false))

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <h4 className=' mb-sm-0 font-size-18 pb-4'>{ManageShopVN.title}</h4>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody className='border-bottom'>
                    <div className='d-flex align-items-center'>
                      <h5 className='mb-0 card-title flex-grow-1'>{ManageShopVN.shopList}</h5>
                      <div className='flex-shrink-0'>
                        <PermissionWrapper
                          screenCode={screenCode}
                          code={USER_ACCOUNT_MANAGEMENT_CODE.ADMIN_EXPORT_LIST_USER}
                        >
                          <button
                            onClick={() => {
                              handleClickExport()
                            }}
                            className='btn btn-primary me-1'
                          >
                            {ManageShopVN.export}
                          </button>
                        </PermissionWrapper>
                      </div>
                    </div>
                  </CardBody>
                  <CardBody>
                    <TableContainer
                      columns={permissionColumns}
                      data={shops}
                      isGlobalFilter={true}
                      setGlobalFilterUser={setGlobalFilterUser}
                      isManageShopGlobalFilter={true}
                      isPagination={true}
                      iscustomPageSizeOptions={false}
                      isShowingPageLength={false}
                      customPageSize={10}
                      tableClass='table-bordered align-middle nowrap mt-2'
                      paginationDiv='col-sm-12 col-md-7'
                      pagination='pagination justify-content-end pagination-rounded'
                      setSortBy={handleSort}
                      useAPIChangePaging
                      gotoPageCustom={goToPage}
                      total={total}
                      isSearchInput={false}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <ConfirmModal
        show={showLockAccount}
        onConfirmClick={onLockAccount}
        onCloseClick={() => setShowLockAccount(false)}
        description={`Xác nhận khóa tài khoản bởi admin ${profile?.name ?? ''}`}
      />
      <ConfirmModal
        show={showMember}
        onConfirmClick={onAcceptMember}
        onCloseClick={() => setShowMember(false)}
        title='Duyệt hội viên'
        hasIcon={false}
        description={`Xác nhận duyệt hội viên cho tài khoản ${
          itemSelect?.phone ?? ''
        }.\n Bạn có đồng ý duyệt hội viên?`}
      />
    </React.Fragment>
  )
}

export default ManageShop
