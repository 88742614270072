import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

//import components

import { getLocalityList, setTypePopupLocality, getLocalityDetail, getLocalityDetailSuccess } from 'store/actions'

//redux
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

import { Col, Row, UncontrolledTooltip, Card, CardBody } from 'reactstrap'
import Spinners from 'components/Common/Spinner'
import { toast } from 'react-toastify'
import TableContainer from 'components/Common/TableContainer'
import { AccountStatisticsVN, LocalityVN } from 'constants/vn'
import ConfirmModal from 'components/Common/ConfirmModal'
import { deleteLocalityAPI } from 'helpers/backend_helper'

import { AREA_MANAGEMENT_CODE, SCREEN_CODE } from 'constants/permission-code'
import PermissionWrapper from 'components/Common/PermissionWrapper'
import { getStorage, setStorage } from 'helpers/global_helper'
import AddEditLocality from 'pages/ManageLocality/components/AddEditLocality'
import { City, Code, District, Domain, GeographicalLevel, Industry, Name, Text } from './components/AccounStatisticsCol'

import SpinnerVersion2 from 'components/Common/SpinnerVersion2'
import { Phone } from './components/AccounStatisticsCol'

function AccountStatisticsActivation() {
  //meta title
  document.title = 'Account Activity | HPG'
  const screenCode = useMemo(() => SCREEN_CODE.AREA_MANAGEMENT, [])

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const selectLocalitysState = (state) => state.LocalityReducer
  const LocalitysProperties = createSelector(selectLocalitysState, (localityReducer) => ({
    localitys: localityReducer.localitys,
    loading: localityReducer.loading,
    total: localityReducer.total
  }))

  const { localitys, loading, total } = useSelector(LocalitysProperties)

  const [isLoading, setLoading] = useState(loading)
  const [onDelete, setOnDelete] = useState(false)
  const [selected, setSelected] = useState()
  const [filter, setFilter] = useState()
  const [openModal, setOpenModal] = useState(false)
  const [page, setPage] = useState(1)

  useEffect(() => {
    const filterManageLocality = getStorage('filterManageLocality')
    dispatch(getLocalityList({ ...filterManageLocality }))
  }, [dispatch])

  const columns = useMemo(
    () => [
      {
        Header: AccountStatisticsVN.table.code,
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />
        }
      },
      {
        Header: AccountStatisticsVN.table.zone,
        accessor: 'name',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />
        }
      },
      {
        Header: AccountStatisticsVN.table.industrySector,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Industry {...cellProps} />
        }
      },
      {
        Header: AccountStatisticsVN.table.text1,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Text {...cellProps} />
        }
      },
      {
        Header: AccountStatisticsVN.table.text2,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Text {...cellProps} />
        }
      },
      {
        Header: AccountStatisticsVN.table.text3,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Text {...cellProps} />
        }
      },
      {
        Header: AccountStatisticsVN.table.text4,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Text {...cellProps} />
        }
      },
      {
        Header: AccountStatisticsVN.table.text5,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Text {...cellProps} />
        }
      },
      {
        Header: AccountStatisticsVN.table.text6,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Text {...cellProps} />
        }
      },
      {
        Header: AccountStatisticsVN.table.text7,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Text {...cellProps} />
        }
      },
      {
        Header: AccountStatisticsVN.table.text8,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Text {...cellProps} />
        }
      },
      
    ],
    []
  )

  const setGlobalFilterUser = async (data) => {
    setStorage('filterManageLocality', data)
    setFilter(data)
    dispatch(getLocalityList(data))
    setPage(1)
  }

  const onClickDelete = async () => {
    try {
      const res = await deleteLocalityAPI({ id: selected.id, type: selected.type })
      if (res) {
        toast('Xoá địa bàn thành công', { type: 'success' })
        dispatch(getLocalityList(filter))
        setOnDelete(false)
      }
    } catch (error) {
      toast.error(error.response.data.message, { type: 'error' })
    }
  }

  const goToPage = (page) => {
    dispatch(getLocalityList({ ...filter, page }))
    setFilter({ ...filter, page })
    setPage(page)
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <h4 className=' mb-sm-0 font-size-18 pb-4'>{AccountStatisticsVN.title}</h4>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody className='border-bottom'>
                    <div className='d-flex align-items-center'>
                      <div className='flex-shrink-0'></div>
                    </div>
                  </CardBody>
                  <CardBody>
                    <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
                      <TableContainer
                        columns={columns}
                        data={localitys}
                        isGlobalFilter={true}
                        isSearchInput={false}
                        setGlobalFilterUser={setGlobalFilterUser}
                        isAccountStatisticsActivationFilter={true}
                        isPagination={true}
                        iscustomPageSizeOptions={false}
                        isShowingPageLength={false}
                        customPageSize={10}
                        placeholderSearch={LocalityVN.filters.search.placeholder}
                        tableClass='table-bordered align-middle nowrap mt-2'
                        paginationDiv='col-sm-12 col-md-7'
                        pagination='pagination justify-content-end pagination-rounded'
                        useAPIChangePaging
                        currentPage={page}
                        gotoPageCustom={goToPage}
                        total={total}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      {openModal && (
        <AddEditLocality
          show={openModal}
          filter={filter}
          onCloseClick={() => {
            setOpenModal(false)
            dispatch(getLocalityDetailSuccess(null))
          }}
          setPage={setPage}
        />
      )}
      <ConfirmModal
        show={onDelete}
        icon={
          <div className='avatar-title rounded-circle bg-danger text-light bg-opacity-60 font-size-20 rounded-3'>
            <i className='mdi mdi-delete-outline'></i>
          </div>
        }
        onConfirmClick={onClickDelete}
        onCloseClick={() => setOnDelete(false)}
        title={`Xác nhận xóa địa bàn`}
        description={`Bạn có chắc chắn muốn xóa địa bàn?`}
      />
    </React.Fragment>
  )
}

export default AccountStatisticsActivation
