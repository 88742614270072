import React, { useEffect, useMemo, useState } from 'react'

//import components

import { getLocalityList, setTypePopupLocality, getLocalityDetail, getLocalityDetailSuccess } from 'store/actions'

//redux
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

import { Col, Row, UncontrolledTooltip, Card, CardBody } from 'reactstrap'
import Spinners from 'components/Common/Spinner'
import { toast } from 'react-toastify'
import TableContainer from 'components/Common/TableContainer'
import { AccountStatisticsVN, LocalityVN } from 'constants/vn'
import ConfirmModal from 'components/Common/ConfirmModal'
import { deleteLocalityAPI, getAccountStactisticsAPI } from 'helpers/backend_helper'

import { AREA_MANAGEMENT_CODE, SCREEN_CODE } from 'constants/permission-code'
import PermissionWrapper from 'components/Common/PermissionWrapper'
import { getStorage, setStorage } from 'helpers/global_helper'
import AddEditLocality from 'pages/ManageLocality/components/AddEditLocality'
import { City, Code, District, Domain, GeographicalLevel, Industry, Name, Text } from './components/AccounStatisticsCol'

import SpinnerVersion2 from 'components/Common/SpinnerVersion2'
import { Phone } from './components/AccounStatisticsCol'
function AccountStatisticsAccumulation({ type, title }) {
  //meta title
  document.title = 'Account Activity | HPG'

  const dispatch = useDispatch()

  const selectLocalitysState = (state) => state.LocalityReducer
  const LocalitysProperties = createSelector(selectLocalitysState, (localityReducer) => ({
    localitys: localityReducer.localitys,
    loading: localityReducer.loading,
    total: localityReducer.total
  }))

  const { localitys, loading, total } = useSelector(LocalitysProperties)
  const [countData, setCountdata] = useState({})
  const [isLoading, setLoading] = useState(false)

  const [filter, setFilter] = useState()
  const [page, setPage] = useState(1)

  const [datasource, setDatasource] = useState([])
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 10,
    totalItems: 0
  })

  const onInit = async (payload) => {
    setLoading(true)

    if (!payload || Object.keys(payload).length === 0) {
      setDatasource([])
      setLoading(false)
      return
    }

    const x = await getAccountStactisticsAPI({
      ...payload,
      // meta: {
      //   'Loại tài khoản': payload?.client_namme
      // },
      type
    })

    setDatasource(x.data)
    setCountdata(x.meta)
    setPagination(x.meta)
    setLoading(false)
  }
  useEffect(() => {
    // const filterManageLocality = getStorage('filterManageLocality')
    // dispatch(getLocalityList({ ...filterManageLocality }))

    setDatasource([])
  }, [type, title])

  const columns = useMemo(
    () => [
      {
        Header: 'Loại tài khoản',
        accessor: 'uClient',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return cellProps.value === 'user' ? 'Cửa hàng' : 'Kỹ thuật viên'
        }
      },
      {
        Header: AccountStatisticsVN.table.zone,
        accessor: 'provinceName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Industry {...cellProps} />
        }
      },
      {
        Header: AccountStatisticsVN.table.industrySector,
        accessor: 'industrySectorName',
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Industry {...cellProps} />
        }
      },
      {
        Header: AccountStatisticsVN.table.text1,
        accessor: 'tkLastMonth',
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Text {...cellProps} />
        }
      },
      {
        Header: AccountStatisticsVN.table.text2,
        accessor: 'targetTk',
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Text {...cellProps} />
        }
      },
      {
        Header: AccountStatisticsVN.table.text3,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Text {...cellProps} />
        }
      },
      {
        Header: AccountStatisticsVN.table.text4,
        disableSortBy: true,
        accessor: 'tkMonth',
        hideSort: true,
        Cell: (cellProps) => {
          return <Text {...cellProps} />
        }
      },
      {
        Header: AccountStatisticsVN.table.text5,
        accessor: 'recordMonth',
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Text {...cellProps} />
        }
      },
      {
        Header: AccountStatisticsVN.table.text6,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return `${cellProps.cell.row.original.tkMonth}/${cellProps.cell.row.original.targetTk}`
        }
      },
      {
        Header: AccountStatisticsVN.table.text7,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Text {...cellProps} />
        }
      },
      {
        Header: AccountStatisticsVN.table.text8,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return (page - 1) * 10 + cellProps.row.index + 1
        }
      }
    ],
    [page, type]
  )

  const setGlobalFilterUser = async (data) => {
    setFilter({ ...data, page: 1 })
    setPage(1)
    onInit(data)
  }

  const goToPage = (pageNumber) => {
    setPage(pageNumber)
    setFilter({ ...filter, page: pageNumber })
    onInit({ ...filter, page: pageNumber })
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <h4 className=' mb-sm-0 font-size-18 pb-4'>{title}</h4>

          <SpinnerVersion2 loading={isLoading} />

          <Row>
            <Col lg='12'>
              <Card>
                <CardBody className='border-bottom'>
                  <div className='d-flex align-items-center'>
                    <div className='flex-shrink-0'></div>
                  </div>
                </CardBody>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={datasource}
                    isGlobalFilter={true}
                    isSearchInput={false}
                    setGlobalFilterUser={setGlobalFilterUser}
                    isAccountStatisticsAccumulationFilter={true}
                    isPagination={true}
                    iscustomPageSizeOptions={false}
                    isShowingPageLength={false}
                    customPageSize={10}
                    placeholderSearch={LocalityVN.filters.search.placeholder}
                    tableClass='table-bordered align-middle nowrap mt-2'
                    paginationDiv='col-sm-12 col-md-7'
                    pagination='pagination justify-content-end pagination-rounded'
                    useAPIChangePaging
                    currentPage={page}
                    gotoPageCustom={goToPage}
                    total={pagination.totalItems}
                    type={type}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AccountStatisticsAccumulation
