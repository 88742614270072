import PropsType from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { Label, UncontrolledTooltip } from 'reactstrap'
import React from 'react'
import { Row, Col, Space, Flex, Form } from 'antd'

//flatpickr
import { ActivationVN, CommonVN, ProductVN } from 'constants/vn'

import { DatePicker } from 'antd'
import { FormInput } from 'components/Common/form-input/FormInput'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { MAX_LENGTH_SEARCH } from 'constants/constants'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { FormDateRange } from 'components/Common/form-date-range/FormDateRange'
import { getPortfolioAllAPI } from 'helpers/backend_helper'
import dayjs from 'dayjs'

const { RangePicker } = DatePicker

ManageRankHistoryFilter.propTypes = {
  handleFilter: PropsType.func,
  handleConfirm: PropsType.func,
  handleResetFilter: PropsType.func,
  handleExport: PropsType.func,
  rankNames: PropsType.arrayOf(PropsType.string)
}

function ManageRankHistoryFilter({ handleFilter, handleConfirm, handleResetFilter, handleExport, rankNames }) {
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    zone: app.zone,
    subzone: app.subzone,
    province: app.province,
    district: app.district,
    ward: app.ward,
    myLocationManagement: app.myLocationManagement
  }))
  const { zone, subzone, province, district, myLocationManagement, ward } = useSelector(LayoutProperties)

  const [filter, setFilter] = useState({
    start_date: dayjs()
      .month(dayjs().get('month') - 2)
      .startOf('month')
      .startOf('day'),
    end_date: dayjs().endOf('day')
  })
  const [industry, setIndustry] = useState()
  const [industrySector, setIndustrysector] = useState()
  const [category, setCategory] = useState()
  const [form] = Form.useForm()
  const watchType = Form.useWatch('type', form)
  const watchForm = Form.useWatch([], form)
  const [model, setModel] = useState()

  const onFilterChange = (name, value) => {
    return setFilter((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const ColProps = useMemo(() => ({
    xs: { span: 24 },
    xxl: { span: 4 }
  }))

  const lstDistrict = myLocationManagement

  ////Lấy danh sách ward
  // Get all unique parentId values from lstDistrict
  const wardParentIds = myLocationManagement?.map((district) => district.id)

  //Từ wardParentIds, lấy ra những ward có id trong wardParentIds
  const lstWard = ward.filter((ward) => wardParentIds.includes(ward.parentId))

  ////Lấy danh sách province
  // Get all unique parentId values from lstDistrict
  const districtParentIds = lstDistrict?.map((district) => district.parentId)
  //Từ districtParentIds, lấy ra những district có id trong districtParentIds
  const lstProvionce = province.filter((province) => districtParentIds.includes(province.id))

  ////Lấy danh sách subZone
  // Get all unique subZoneId(parentId) values from lstProvionce
  const provinceParentIds = lstProvionce?.map((province) => province.subZoneId)
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstSubZone = subzone?.filter((subZone) => provinceParentIds.includes(subZone.id))

  ////Lấy danh sách zone
  // Get all unique zoneId(parentId) values from lstProvionce
  const subZoneParentIds = lstProvionce?.map((province) => province.zoneId) // trong lstProvionce có lưu zoneId
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstZone = zone.filter((zone) => subZoneParentIds.includes(zone.id))
  const subZoneMemo = useMemo(() => {
    const parentDatas = filter?.zone_ids?.length > 0 ? filter.zone_ids : lstZone.map((e) => e.id)
    return lstSubZone?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.zoneId) : true))?.length > 0
      ? [{ label: 'Tất cả', value: 'all' }].concat(
          lstSubZone
            ?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.zoneId) : true))
            ?.map((item) => ({
              value: item.id,
              label: item.name
            }))
        )
      : []
  }, [lstSubZone, lstZone, filter])

  const provinceMemo = useMemo(() => {
    const parentDatas = filter?.sub_zone_ids?.length > 0 ? filter.sub_zone_ids : subZoneMemo.map((e) => e.value)

    return lstProvionce?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.subZoneId) : true))?.length >
      0
      ? [{ label: 'Tất cả', value: 'all' }].concat(
          lstProvionce
            ?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.subZoneId) : true))
            ?.map((item) => ({
              value: item.id,
              label: item.name
            }))
        )
      : []
  }, [lstProvionce, filter])

  const districtMemo = useMemo(() => {
    const parentDatas = filter?.province_ids?.length > 0 ? filter.province_ids : provinceMemo.map((e) => e.value)
    return lstDistrict?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.parentId) : true))?.length > 0
      ? [{ label: 'Tất cả', value: 'all' }].concat(
          lstDistrict
            ?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.parentId) : true))
            ?.map((item) => ({
              value: item.id,
              label: item.name
            }))
        )
      : []
  }, [lstDistrict, filter])
  const getIndustryDataOptions = async () => {
    try {
      const resIndustry = await getPortfolioAllAPI({ type: 'industry' })
      const resIndustrysector = await getPortfolioAllAPI({ type: 'industrySector' })
      const resCategory = await getPortfolioAllAPI({ type: 'category' })
      setIndustry(resIndustry?.data)
      setIndustrysector(resIndustrysector?.data)
      setCategory(resCategory?.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getIndustryDataOptions()
  }, [])

  const onResetFilter = () =>
    setFilter({
      serial: '',
      model_name: '',
      shop_name: '',
      phone: '',
      zone_ids: undefined,
      sub_zone_ids: undefined,
      province_ids: undefined,
      district_ids: undefined,
      ward_ids: undefined,
      client: undefined,
      start_date1: undefined,
      end_date1: undefined,
      start_date: undefined,
      end_date: undefined,
      industry_ids: undefined,
      industry_sector_ids: undefined,
      category_ids: undefined,
      email: '',
      search: '',
      label: '',
      type: 'product',
      rank_name: undefined
    })

  return (
    <Row gutter={[10, 10]} align='bottom'>
      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Nhập từ khoá tìm kiếm</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập tên KTV/ Cửa hàng'
          value={filter?.search || ''}
          name='search'
          id={`input-serial-filter`}
          onChange={(e) => onFilterChange('search', e.target.value)}
          onBlur={(e) => onFilterChange('search', e.target.value)}
        />
      </Col>

      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Nhập Số điện thoại</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập Số điện thoại'
          value={filter?.phone || ''}
          name='phone'
          id={`input-phone-filter`}
          onChange={(e) => onFilterChange('phone', e.target.value)}
          onBlur={(e) => onFilterChange('phone', e.target.value)}
        />
      </Col>

      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>{'Loại'}</Label>
        <FormSelect
          options={[
            {
              label: 'Cửa hàng',
              value: 'user'
            },
            {
              label: 'Kĩ thuật viên',
              value: 'technician'
            }
          ]}
          value={filter.client}
          onChange={(value) => {
            onFilterChange('client', value)
          }}
          placeholder={'Chọn loại tài khoản'}
        />
      </Col>
      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>{'Hạng'}</Label>
        <FormSelect
          options={rankNames}
          value={filter.rank_name}
          onChange={(value) => {
            onFilterChange('rank_name', value)
          }}
          placeholder={'Chọn hạng'}
        />
      </Col>

      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Ngành hàng</Label>
        <FormSelect
          options={
            industrySector?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  industrySector?.map((item) => ({
                    value: item.id,
                    label: item.name
                  }))
                )
              : []
          }
          onChange={(value) => {
            onFilterChange('industry_sector_ids', value)
          }}
          onSelect={(value) => {
            const all = industrySector?.map((item) => item.id)
            if (value && value === 'all') {
              return onFilterChange('industry_sector_ids', [...all])
            }
          }}
          value={filter.industry_sector_ids}
          placeholder='Chọn Ngành hàng'
          mode='multiple'
        />
      </Col>

      <Col {...ColProps} className='mt-5'>
        <Label className='form-label'>Miền</Label>
        <FormSelect
          options={[{ label: 'Tất cả', value: 'all' }].concat(
            lstZone?.map((item) => ({
              value: item.id,
              label: item.name
            }))
          )}
          value={filter.zone_ids}
          onChange={(value, label) => {
            onFilterChange('zone_ids', value)
            onFilterChange(
              'zone_names',
              label.map((e) => e.label)
            )

            onFilterChange('sub_zone_ids', [])
            onFilterChange('province_ids', [])
            onFilterChange('district_ids', [])
            onFilterChange('ward_ids', [])
            onFilterChange('sub_zone_names', [])
            onFilterChange('province_names', [])
            onFilterChange('district_names', [])
            onFilterChange('ward_names', [])
          }}
          onSelect={(value) => {
            const all = lstZone?.map((item) => item.id)
            const all_names = lstZone?.map((item) => item.id)

            if (value && value === 'all') {
              onFilterChange('zone_ids', [...all])
              onFilterChange('zone_names', [...all_names])
            }
          }}
          placeholder={ProductVN.filter.zone.placeholder}
          mode='multiple'
        />
      </Col>

      <Col {...ColProps} className='mt-5'>
        <Label className='form-label'>Vùng</Label>
        <FormSelect
          options={subZoneMemo}
          value={filter.sub_zone_ids}
          onChange={(value, label) => {
            onFilterChange('sub_zone_ids', value)
            onFilterChange(
              'sub_zone_names',
              label.map((e) => e.label)
            )

            onFilterChange('province_ids', [])
            onFilterChange('district_ids', [])
            onFilterChange('ward_ids', [])
            onFilterChange('province_names', [])
            onFilterChange('district_names', [])
            onFilterChange('ward_names', [])
          }}
          onSelect={(value) => {
            const all = subZoneMemo.map((item) => item.value).filter((num) => !isNaN(num))
            const all_names = lstSubZone?.filter((e) => filter.zone_ids?.includes(e.zoneId))?.map((item) => item.name)
            if (value && value === 'all') {
              onFilterChange('sub_zone_ids', [...all])
              onFilterChange('sub_zone_names', [...all_names])
            }
          }}
          placeholder={ProductVN.filter.subZone.placeholder}
          mode='multiple'
        />
      </Col>

      <Col {...ColProps} className='mt-5'>
        <Label className='form-label'>Tỉnh/ Thành phố</Label>
        <FormSelect
          options={provinceMemo}
          value={filter.province_ids}
          onChange={(value, label) => {
            onFilterChange('province_ids', value)
            onFilterChange(
              'province_names',
              label.map((e) => e.label)
            )

            onFilterChange('district_ids', [])
            onFilterChange('ward_ids', [])
            onFilterChange('district_names', [])
            onFilterChange('ward_names', [])
          }}
          onSelect={(value) => {
            const all = provinceMemo.map((item) => Number(item.value)).filter((num) => !isNaN(num))
            const all_names = lstProvionce
              ?.filter((e) => filter.sub_zone_ids?.includes(e.subZoneId))
              ?.map((item) => item.name)

            if (value && value === 'all') {
              onFilterChange('province_ids', [...all])
              onFilterChange('province_names', [...all_names])
            }
          }}
          placeholder='Tỉnh/ Thành phố'
          mode='multiple'
        />
      </Col>

      <Col {...ColProps} className='mt-5'>
        <Label className='form-label'>Quận/ Huyện</Label>
        <FormSelect
          options={districtMemo}
          value={filter.district_ids}
          onChange={(value, label) => {
            onFilterChange('district_ids', value)
            onFilterChange(
              'district_names',
              label.map((e) => e.label)
            )

            onFilterChange('ward_ids', [])
            onFilterChange('ward_names', [])
          }}
          onSelect={(value) => {
            const all = districtMemo.map((item) => Number(item.value)).filter((num) => !isNaN(num))
            const all_names = lstDistrict
              ?.filter((e) => filter.province_ids?.includes(e.parentId))
              ?.map((item) => item.name)

            if (value && value === 'all') {
              onFilterChange('district_ids', [...all])
              onFilterChange('district_names', [...all_names])
            }
          }}
          placeholder='Quận/ Huyện'
          mode='multiple'
        />
      </Col>

      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>{'Thời gian áp dụng'}</Label>
        <FormDateRange
          inputReadOnly
          className='w-100'
          size='large'
          format={CommonVN.datePlaceholder.toUpperCase()}
          placeholder={[CommonVN.datePlaceholder, CommonVN.datePlaceholder]}
          value={[filter.start_date, filter.end_date]}
          onChange={(value) => {
            if (value) {
              onFilterChange('start_date', value[0])
              onFilterChange('end_date', value[1])
            } else {
              onFilterChange('start_date', undefined)
              onFilterChange('end_date', undefined)
            }
          }}
        />
      </Col>

      <Col {...ColProps} className='w-100'>
        <Space>
          <button
            type='button'
            className='btn btn-soft-danger'
            onClick={() => {
              onResetFilter()
              handleResetFilter()
            }}
          >
            Xóa tìm kiếm
          </button>

          <button
            type='button'
            className='btn btn-primary'
            onClick={() => {
              handleFilter({
                ...filter,
                start_date: filter?.start_date?.startOf('day'),
                end_date: filter?.end_date?.endOf('day')
              })
            }}
          >
            Tìm kiếm
          </button>
        </Space>
      </Col>
    </Row>
  )
}

export default ManageRankHistoryFilter
