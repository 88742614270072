import { StatusAccumulationOptions } from 'constants/constants'
import { ActivationVN, ManageShopVN } from 'constants/vn'
import { formateDate, formateDateTime } from 'helpers/global_helper'
import React from 'react'
import { Badge, Input, UncontrolledTooltip } from 'reactstrap'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'

const Serial = (cell) => {
  return (
    <>
      {cell.row.original?.userRewardIsDeleted && (
        <>
          <i
            className='mdi mdi-recycle-variant'
            style={{ marginRight: '4px', color: 'red' }}
            id={`viewtooltip-bravo-${cell.row.original.id}`}
          />
          <UncontrolledTooltip trigger='hover' placement='top' target={`viewtooltip-bravo-${cell.row.original.id}`}>
            Sản phẩm bị thu hồi
          </UncontrolledTooltip>
        </>
      )}
      <span>
        <p className='m-0'>{cell?.value ? cell.value : cell.row.original?.productSerial}</p>
      </span>
      <span>
        <p className='m-0'>{cell?.value ? cell.value : cell.row.original?.userRewardSecondProductSerial}</p>
      </span>
    </>
  )
}

const ActivationCode = (cell) => {
  return cell.value ? cell.value : '--'
}

const ActivationCategory = (cell) => {
  return cell.value ? cell.value : '--'
}

const ActivationModel = (cell) => {
  return cell.value ? cell.value : '--'
}

const Model = (cell) => {
  return (
    <div>
      <span>
        <p className='m-0'>{cell?.value ? cell.value : cell.row.original?.modelName}</p>
      </span>
      <span>
        <p className='m-0'>{cell.value ? cell.value : cell.row.original?.secondProductModelName}</p>
      </span>
    </div>
  )
}

const AccountType = (cell) => {
  return cell.value ? (cell.value === 'user' ? 'Cửa hàng' : 'Kĩ thuật viên') : '--'
}

const PICName = (cell) => {
  return (
    <div>
      <p className='m-0'>
        {cell.row.original?.usersClient === 'technician'
          ? cell.row.original?.usersName
          : cell.row.original?.usersShopName}
      </p>
      <span>{cell.row.original?.usersPhone}</span>
      <p>
        <span>
          {cell.row.original?.zoneName} - {cell.row.original?.subZoneName} - {cell.row.original?.provinceName} -{' '}
          {cell.row.original?.districtName} - {cell.row.original?.wardName}
        </span>
      </p>
    </div>
  )
}

const Customer = (cell) => {
  // return cell.value ? cell.value : '--'
  return (
    <div>
      <p className='m-0'>{cell.value ? cell.value : '--'}</p>
      <span>{cell.row.original?.customerPhone}</span>
      <p>
        <span>
          {cell.row.original?.customerProvince?.name} - {cell.row.original?.customerDistrict?.name} -{' '}
          {cell.row.original?.customerWard?.name}
        </span>
      </p>
    </div>
  )
}

const ActivatedDate = (cell) => {
  // return cell.value ? formateDate(cell.value) : "--"
  return cell.value ? formateDateTime(cell.value) : '--'
}
const StatusActivation = (cell) => {
  switch (cell.value) {
    case 'no_reward':
      return <Badge className='badge-soft-secondary'>{StatusAccumulationOptions[0].label}</Badge>
    case 'waiting_for_control':
      return <Badge className='badge-soft-info'>{StatusAccumulationOptions[1].label}</Badge>
    case 'for_control':
      return <Badge className='badge-soft-success'>{StatusAccumulationOptions[2].label}</Badge>
    case 'payed':
      return <Badge className='badge-soft-warning'>{StatusAccumulationOptions[3].label}</Badge>
  }
}
const StatusAccumulationV_2 = (cell) => {
  switch (cell.value) {
    case 'Không thưởng':
      return <Badge className='badge-soft-secondary'>{cell.value}</Badge>
    case 'Chờ đối soát':
      return <Badge className='badge-soft-info'>{cell.value}</Badge>
    case 'Đã đối soát':
      return <Badge style={{ color: '#d1e7dd' }}>{cell.value}</Badge>
    case 'Từ chối':
      return <Badge className='badge-soft-warning'>{cell.value}</Badge>
    case 'Thanh toán':
      return (
        <Badge style={{ color: '#145a32' }} >
          {cell.value}
        </Badge>
      )
  }
}
export {
  ActivationModel,
  ActivationCategory,
  AccountType,
  PICName,
  ActivationCode,
  StatusActivation,
  Serial,
  ActivatedDate,
  Customer,
  Model,
  StatusAccumulationV_2
}
