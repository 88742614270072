import PropsType from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { Label, UncontrolledTooltip } from 'reactstrap'
import React from 'react'
import { Row, Col, Space, Flex } from 'antd'

//flatpickr
import { ActivationVN, CommonVN, ProductVN, UserVN } from 'constants/vn'

import { DatePicker } from 'antd'
import { FormInput } from 'components/Common/form-input/FormInput'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { MAX_LENGTH_SEARCH, TypeProgramOptions } from 'constants/constants'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { getStorage } from 'helpers/global_helper'

const { RangePicker } = DatePicker

ManageUserFilter.propTypes = {
  handleFilter: PropsType.func,
  handleConfirm: PropsType.func,
  handleResetFilter: PropsType.func,
  handleExport: PropsType.func
}

function ManageUserFilter({ handleFilter, handleConfirm, handleResetFilter, handleExport, optionPermissionGroup }) {
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    zone: app.zone,
    subzone: app.subzone,
    province: app.province,
    district: app.district,
    ward: app.ward,
    myLocationManagement: app.myLocationManagement
  }))
  const { zone, subzone, province, district, ward, myLocationManagement } = useSelector(LayoutProperties)

  const [filter, setFilter] = useState({})
  const onFilterChange = (name, value) =>
    setFilter((prev) => ({
      ...prev,
      [name]: value
    }))

  const handleSelect = (value, key) => {
    setFilter((e) => ({ ...e, [key]: value }))
  }

  const lstDistrict = myLocationManagement

  ////Lấy danh sách ward
  // Get all unique parentId values from lstDistrict
  const wardParentIds = myLocationManagement?.map((district) => district.id)

  //Từ wardParentIds, lấy ra những ward có id trong wardParentIds
  const lstWard = ward.filter((ward) => wardParentIds.includes(ward.parentId))

  ////Lấy danh sách province
  // Get all unique parentId values from lstDistrict
  const districtParentIds = lstDistrict?.map((district) => district.parentId)
  //Từ districtParentIds, lấy ra những district có id trong districtParentIds
  const lstProvionce = province.filter((province) => districtParentIds.includes(province.id))

  ////Lấy danh sách subZone
  // Get all unique subZoneId(parentId) values from lstProvionce
  const provinceParentIds = lstProvionce?.map((province) => province.subZoneId)
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstSubZone = subzone?.filter((subZone) => provinceParentIds.includes(subZone.id))

  ////Lấy danh sách zone
  // Get all unique zoneId(parentId) values from lstProvionce
  const subZoneParentIds = lstProvionce?.map((province) => province.zoneId) // trong lstProvionce có lưu zoneId
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstZone = zone.filter((zone) => subZoneParentIds.includes(zone.id))

  const ColProps = useMemo(() => ({
    xs: { span: 24 },
    xxl: { span: 4 }
  }))

  const subZoneMemo = useMemo(() => {
    const parentDatas = filter?.zone_ids?.length > 0 ? filter.zone_ids : lstZone.map((e) => e.id)
    return lstSubZone?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.zoneId) : true))?.length > 0
      ? [{ label: 'Tất cả', value: 'all' }].concat(
          lstSubZone
            ?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.zoneId) : true))
            ?.map((item) => ({
              value: item.id,
              label: item.name
            }))
        )
      : []
  }, [lstSubZone, lstZone, filter])

  const provinceMemo = useMemo(() => {
    const parentDatas = filter?.sub_zone_ids?.length > 0 ? filter.sub_zone_ids : subZoneMemo.map((e) => e.value)
    return lstProvionce?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.subZoneId) : true))?.length >
      0
      ? [{ label: 'Tất cả', value: 'all' }].concat(
          lstProvionce
            ?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.subZoneId) : true))
            ?.map((item) => ({
              value: item.id,
              label: item.name
            }))
        )
      : []
  }, [lstProvionce, filter])

  const districtMemo = useMemo(() => {
    const parentDatas = filter?.province_ids?.length > 0 ? filter.province_ids : provinceMemo.map((e) => e.value)
    return lstDistrict?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.parentId) : true))?.length > 0
      ? [{ label: 'Tất cả', value: 'all' }].concat(
          lstDistrict
            ?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.parentId) : true))
            ?.map((item) => ({
              value: item.id,
              label: item.name
            }))
        )
      : []
  }, [lstDistrict, filter])

  const wardMemo = useMemo(() => {
    const parentDatas = filter?.district_ids?.length > 0 ? filter.district_ids : districtMemo.map((e) => e.value)
    return lstWard?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.parentId) : true))?.length > 0
      ? [{ label: 'Tất cả', value: 'all' }].concat(
          lstWard
            ?.filter((e) => (parentDatas?.length > 0 ? parentDatas?.includes(e.parentId) : true))
            ?.map((item) => ({
              value: item.id,
              label: item.name
            }))
        )
      : []
  }, [lstWard, filter])

  useEffect(() => {
    const filterManageUser = getStorage('filterManageUser')
    setFilter(filterManageUser)
  }, [])
  const onResetFilter = () =>
    setFilter({
      name: '',
      employee_code: '',
      email: '',
      role_id: undefined,
      status: undefined,
      zone_ids: undefined,
      zone_names: undefined,
      sub_zone_ids: undefined,
      sub_zone_names: undefined,
      province_ids: undefined,
      province_names: undefined,
      district_ids: undefined,
      district_names: undefined,
      ward_ids: undefined,
      ward_names: undefined,
      phone_number: undefined
    })

  return (
    <Row gutter={[10, 10]} align='bottom'>
      <Col xxl={6} lg={6} xs={6} className='mt-5'>
        <Label className='form-label'>Nhập Tên</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập tên'
          value={filter?.name || ''}
          name='name'
          id={`input-name-filter`}
          onChange={(e) => onFilterChange('name', e.target.value)}
          onBlur={(e) => onFilterChange('name', e.target.value)}
        />
        <UncontrolledTooltip trigger='hover' placement='top' target={`input-name-filter`}>
          {''}
        </UncontrolledTooltip>
      </Col>

      <Col xxl={6} lg={6} xs={6} className='mt-5'>
        <Label className='form-label'>Nhập Mã nhân viên</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập Mã nhân viên'
          value={filter?.employee_code || ''}
          name='employee_code'
          id={`input-employee-code-filter`}
          onChange={(e) => onFilterChange('employee_code', e.target.value)}
          onBlur={(e) => onFilterChange('employee_code', e.target.value)}
        />
        <UncontrolledTooltip trigger='hover' placement='top' target={`input-employee-code-filter`}>
          {''}
        </UncontrolledTooltip>
      </Col>

      <Col xxl={6} lg={6} xs={6} className='mt-5'>
        <Label className='form-label'>Số điện thoại</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập Số điện thoại'
          value={filter?.phone_number || ''}
          name='phone_number'
          id={`input-phone_number-filter`}
          onChange={(e) => onFilterChange('phone_number', e.target.value)}
          onBlur={(e) => onFilterChange('phone_number', e.target.value)}
        />
        <UncontrolledTooltip trigger='hover' placement='top' target={`input-phone_number-filter`}>
          {''}
        </UncontrolledTooltip>
      </Col>

      <Col xxl={6} lg={6} xs={6} className='mt-5'>
        <Label className='form-label'>Nhập Email</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập Email'
          value={filter?.email || ''}
          name='email'
          id={`input-email-filter`}
          onChange={(e) => onFilterChange('email', e.target.value)}
          onBlur={(e) => onFilterChange('email', e.target.value)}
        />
        <UncontrolledTooltip trigger='hover' placement='top' target={`input-email-filter`}>
          {''}
        </UncontrolledTooltip>
      </Col>

      <Col xxl={6} lg={6} xs={6} className='mt-5'>
        <Label className='form-label'>{UserVN.filters.permissionGroup.title}</Label>
        <FormSelect
          options={optionPermissionGroup}
          value={filter?.role_id}
          onChange={(value) => {
            onFilterChange('role_id', value)
          }}
          placeholder={UserVN.filters.permissionGroup.placeholder}
          // listHeight={325}
        />
      </Col>

      <Col xxl={6} lg={6} xs={6} className='mt-5'>
        <Label className='form-label'>{UserVN.filters.status.title}</Label>
        <FormSelect
          options={UserVN?.filters?.status?.statusOptions?.map((item) => ({
            value: item.value,
            label: item.label
          }))}
          value={filter?.status}
          onChange={(value) => {
            onFilterChange('status', value)
          }}
          placeholder={UserVN.filters.status.placeholder}
        />
      </Col>

      <Col xxl={6} lg={6} xs={6} className='mt-5'>
        <Label className='form-label'>Miền</Label>
        <FormSelect
          options={[{ label: 'Tất cả', value: 'all' }].concat(
            lstZone?.map((item) => ({
              value: item.id,
              label: item.name
            }))
          )}
          value={filter?.zone_ids}
          onChange={(value, label) => {
            handleSelect(value, 'zone_ids')
            handleSelect(
              label.map((e) => e.label),
              'zone_names'
            )

            handleSelect([], 'sub_zone_ids')
            handleSelect([], 'province_ids')
            handleSelect([], 'district_ids')
            handleSelect([], 'ward_ids')
            handleSelect([], 'sub_zone_names')
            handleSelect([], 'province_names')
            handleSelect([], 'district_names')
            handleSelect([], 'ward_names')
          }}
          onSelect={(value) => {
            const all = lstZone?.map((item) => item.id)
            const all_names = lstZone?.map((item) => item.id)

            if (value && value === 'all') {
              handleSelect([...all], 'zone_ids')
              handleSelect([...all_names], 'zone_names')
            }
          }}
          placeholder={ProductVN.filter.zone.placeholder}
          mode='multiple'
        />
      </Col>

      <Col xxl={6} lg={6} xs={6} className='mt-5'>
        <Label className='form-label'>Vùng</Label>
        <FormSelect
          options={subZoneMemo}
          value={filter?.sub_zone_ids}
          onChange={(value, label) => {
            handleSelect(value, 'sub_zone_ids')
            handleSelect(
              label.map((e) => e.label),
              'sub_zone_names'
            )

            handleSelect([], 'province_ids')
            handleSelect([], 'district_ids')
            handleSelect([], 'ward_ids')
            handleSelect([], 'province_names')
            handleSelect([], 'district_names')
            handleSelect([], 'ward_names')
          }}
          onSelect={(value) => {
            const all = subZoneMemo.map((item) => item.value)
            const all_names = lstSubZone?.filter((e) => filter.zone_ids?.includes(e.zoneId))?.map((item) => item.name)
            if (value && value === 'all') {
              handleSelect([...all], 'sub_zone_ids')
              handleSelect([...all_names], 'sub_zone_names')
            }
          }}
          placeholder={ProductVN.filter.zone.placeholder}
          mode='multiple'
        />
      </Col>

      <Col xxl={6} lg={6} xs={6} className='mt-5'>
        <Label className='form-label'>Tỉnh/ Thành phố</Label>
        <FormSelect
          options={provinceMemo}
          value={filter?.province_ids}
          onChange={(value, label) => {
            handleSelect(value, 'province_ids')
            handleSelect(
              label.map((e) => e.label),
              'province_names'
            )
            handleSelect([], 'district_ids')
            handleSelect([], 'ward_ids')
            handleSelect([], 'district_names')
            handleSelect([], 'ward_names')
          }}
          onSelect={(value) => {
            const all = lstProvionce?.map((item) => item.id)
            const all_names = lstProvionce?.map((item) => item.name)

            if (value && value === 'all') {
              handleSelect([...all], 'province_ids')
              handleSelect([...all_names], 'province_names')
            }
          }}
          placeholder='Tất cả'
          mode='multiple'
        />
      </Col>

      <Col xxl={6} lg={6} xs={6} className='mt-5'>
        <Label className='form-label'>{'Quận/ Huyện'}</Label>
        <FormSelect
          options={districtMemo}
          value={filter?.district_ids}
          onChange={(value, label) => {
            handleSelect(value, 'district_ids')
            handleSelect(
              label.map((e) => e.label),
              'district_names'
            )
            handleSelect([], 'ward_ids')
            handleSelect([], 'ward_names')
          }}
          onSelect={(value) => {
            const all = lstDistrict?.filter((e) => filter?.province_ids?.includes(e.parentId))?.map((item) => item.id)
            const all_names = lstDistrict
              ?.filter((e) => filter?.province_ids?.includes(e.parentId))
              ?.map((item) => item.name)

            if (value && value === 'all') {
              handleSelect([...all], 'district_ids')
              handleSelect([...all_names], 'district_names')
            }
          }}
          placeholder='Tất cả'
          mode='multiple'
        />
      </Col>

      <Col xxl={4} className='w-100'>
        <Space>
          <button
            type='button'
            className='btn btn-soft-danger'
            onClick={() => {
              onResetFilter()
              handleResetFilter()
            }}
          >
            Xóa tìm kiếm
          </button>

          <button type='button' className='btn btn-primary' onClick={() => handleFilter(filter)}>
            Tìm kiếm
          </button>
        </Space>
      </Col>
      {/* <Col flex='1'>
        <Flex gap='small' justify='flex-end'>
        </Flex>
      </Col> */}
    </Row>
  )
}

export default ManageUserFilter
