export const AREA_TYPE = {
  TECHNICIAN: 'technician',
  STORE: 'user'
}

export const optionsAccountLevel = [
  {
    label: 'Kĩ thuật viên',
    value: AREA_TYPE.TECHNICIAN
  },
  {
    label: 'Cửa hàng',
    value: AREA_TYPE.STORE
  }
]
