import { MAX_FILE_SIZE } from 'constants/constants'
import cookie from 'cookie'
import cookies from 'js-cookie'
import moment from 'moment'
import sha256 from 'crypto-js/sha256'

function secondsToHoursMinSec(time) {
  const days = Math.floor(time / (1000 * 60 * 60 * 24))
  const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((time % (1000 * 60)) / 1000)

  return [days, hours, minutes, seconds]
}

function numberWithCommas(x) {
  if (!x) {
    return
  }
  const decimalArray = x.toString().split('.')
  decimalArray[0] = decimalArray[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return decimalArray.join('.')
}

function parseCookies(req) {
  if (req) {
    return cookie.parse(req.headers.cookie || '')
  }

  if (typeof window !== 'undefined') {
    return cookie.parse(window.document.cookie)
  }
  return cookie.parse('')
}

const setCookieUtil = (key, value) => {
  cookies.set(key, value, {
    expires: 100000000
  })
}

const removeCookieUtil = (key) => {
  cookies.remove(key)
}

const setStorage = async (key, value) => {
  try {
    window.sessionStorage.setItem(key, JSON.stringify(value))
  } catch (error) {
    console.error(error)
  }
}

const getStorage = (key) => {
  try {
    console.log(JSON.parse(window.sessionStorage.getItem(key), 'filter'))
    return JSON.parse(window.sessionStorage.getItem(key))
  } catch (error) {
    console.error(error)
  }
}
const clearSessionStorage = () => {
  sessionStorage.clear()
}

const checkIsLocked = (status) => {
  switch (status) {
    case 'pending':
      return false
    case 'active':
      return false
    case 'inactive':
      return true
    case 'terminated':
      return true
    case 'registered':
      return false
  }
}

const formateDate = (date, format) => {
  const dateFormat = format ? format : 'DD/MM/YYYY'
  const date1 = moment(new Date(date)).format(dateFormat)
  return date1
}

const formateDateTime = (date, format) => {
  const dateFormat = format ? format : 'DD/MM/YYYY HH:mm:ss'
  const date1 = moment(new Date(date)).format(dateFormat)
  return date1
}

const filterDataHaveValue = (data) => {
  return Object.fromEntries(
    Object.entries(data).filter(
      ([key, value]) => value !== 'all' && value !== undefined && value !== null && value !== ''
    )
  )
}

const checkFileSizeValid = (file) => {
  if (file) {
    if (file.size / 1024 <= MAX_FILE_SIZE) {
      return true
    } else {
      return false
    }
  }
}

const getParentId = (value) => {
  switch (value.type) {
    case 'industrySector':
      return value.industry
    case 'category':
      return value.industrySector
    case 'model':
      return value.category
    default:
      return 0
  }
}

const sha256Password = (input) => {
  if (input) {
    const hash = sha256(input)
    return hash.toString()
  }
}

function formatWithCommas(value) {
  if (!value) return String(value)
  const parts = value.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

function randomDate(start, end) {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()))
}

function adjustEndDate(data) {
  let { start_date, end_date, start_date1, end_date1 } = data

  if (start_date && end_date) {
    const startDateObj = new Date(start_date)
    const endDateObj = new Date(end_date)
    startDateObj.setHours(0, 0, 0, 0)
    endDateObj.setHours(23, 59, 59, 999)
    data.end_date = endDateObj.toISOString()
    data.start_date = startDateObj.toISOString()
  }

  if (start_date1 && end_date1) {
    const startDateObj1 = new Date(start_date1)
    const endDateObj1 = new Date(end_date1)
    startDateObj1.setHours(0, 0, 0, 0)
    endDateObj1.setHours(23, 59, 59, 999)
    data.end_date1 = endDateObj1.toISOString()
    data.start_date1 = startDateObj1.toISOString()
  }

  return data
}

function formatFilterFields(data) {
  let newData = { ...data }

  // Mapping giá trị 'true' => 'yes', 'false' => 'no', undefined sẽ bị xóa
  const fieldMappings = ['require_activation_code', 'accumulation', 'activated']

  fieldMappings.forEach((field) => {
    if (newData[field] === 'undefined') {
      delete newData[field]
      delete newData[`${field}_name`] // Xóa luôn key "_name" tương ứng
    }
  })

  return newData
}

export {
  secondsToHoursMinSec,
  numberWithCommas,
  parseCookies,
  setCookieUtil,
  removeCookieUtil,
  checkIsLocked,
  formateDate,
  filterDataHaveValue,
  checkFileSizeValid,
  getParentId,
  sha256Password,
  formatWithCommas,
  randomDate,
  formateDateTime,
  setStorage,
  getStorage,
  clearSessionStorage,
  adjustEndDate,
  formatFilterFields
}
