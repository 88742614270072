import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

//import components

import {
  getBankList,
  setTypePopupBank,
  getBankDetail,
  getBankDetailSuccess,
  getContactList,
  setTypePopupContact,
  getContactDetail,
  getContactDetailSuccess
} from 'store/actions'

// import { Name, Abbreviation } from '././ManageBankCol'

//redux
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

import { Col, Row, UncontrolledTooltip, Card, CardBody } from 'reactstrap'
import Spinners from 'components/Common/Spinner'
import { toast } from 'react-toastify'
import TableContainer from 'components/Common/TableContainer'
import ConfirmModal from 'components/Common/ConfirmModal'
import {
  deleteBankAPI,
  deleteContactAPI,
  getImportHistoryDetailAPI,
  getImportHistoryListAPI
} from 'helpers/backend_helper'
import PermissionWrapper from 'components/Common/PermissionWrapper'
import { Checkbox, Space } from 'antd'
import { BankVN } from 'constants/vn'
import { AREA_MANAGEMENT_CODE, SCREEN_CODE } from 'constants/permission-code'
import { TableRowSpan } from 'components/Common/TableCustom'
import { formateDate, formateDateTime } from 'helpers/global_helper'
import { StatusImportFile, StatusMBH, TruncateCell } from 'pages/ManageAccumulation/ManageAccumulationCol'
import withRouter from 'components/Common/withRouter'
import { BonusModel } from 'pages/ProgramBonusTemp/ProgramByAreaAndRewardTypeCol'
import Breadcrumb from 'components/Common/Breadcrumb'

const ManageImportHistoryDetail = (props) => {
  //meta title
  document.title = 'Manage Contacts CMS List'
  const screenCode = useMemo(() => SCREEN_CODE.AREA_MANAGEMENT, [])

  const params = props.router.params

  const stringUrl = params.id
  // const type = 'display'
  // // const id = paramsFromUrl['id']
  // const id = params.id

  // Initialize an empty object to store key-value stringUrl
  const paramsFromUrl = {}

  // Split the string by '&' to get individual key-value pairs
  const urlSplit = stringUrl.split('&')

  // Loop through each key-value pair
  urlSplit.forEach((pair) => {
    // Split each property by '=' to separate key and value
    const [key, value] = pair.split('=')
    // Store the key-value pair in the params object
    paramsFromUrl[key] = value
  })

  const type = paramsFromUrl['type']
  const id = paramsFromUrl['id']

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const selectContactsState = (state) => state.ContactsReducer
  const ContactsProperties = createSelector(selectContactsState, (contactReducer) => ({
    contacts: contactReducer.contacts,
    loading: contactReducer.loading
    // total: contactReducer.total
  }))

  const { contacts, loading } = useSelector(ContactsProperties)

  const [isLoading, setLoading] = useState(loading)
  const [onDelete, setOnDelete] = useState(false)
  const [selected, setSelected] = useState()
  const [filter, setFilter] = useState()
  const [openModal, setOpenModal] = useState(false)
  const [page, setPage] = useState(1)

  const [listTable, setListTable] = useState([])
  const [total, setTotal] = useState()

  const [pagination, setPagination] = useState({
    pageIndex: page,
    pageSize: 10,
    totalRows: total
  })

  useEffect(() => {
    handleGetListTable()
  }, [filter])

  const handleGetListTable = async () => {
    try {
      const res = await getImportHistoryDetailAPI({
        id: id,
        type: filter?.type ? filter?.type : type,
        page
      })

      if (res.data) {
        setListTable(res.data)
      }

      if (res.meta) {
        setTotal(res.meta.totalItems)
      }
    } catch (error) {
      toast(error?.response?.data?.message, { type: 'error' })
    }
  }

  const columns = useMemo(() => {
    if (type === 'reconcile_by_programming' || type === 'confirm_reconcile_by_programming') {
      return [
        {
          Header: 'STT',
          accessor: 'stt',
          filterable: true,
          disableSortBy: true,
          hideSort: true,
          Cell: (cellProps) => {
            return (page - 1) * 10 + cellProps.row.index + 1
          }
        },
        {
          Header: 'Serial',
          accessor: 'meta.serial',
          filterable: true,
          disableSortBy: true,
          hideSort: true
        },
        {
          Header: 'Model',
          accessor: 'meta.modelCode',
          filterable: true,
          hideSort: true
        },
        {
          Header: 'Ngành hàng',
          accessor: 'meta.industrySectorName',
          filterable: true,
          hideSort: true,
          Cell: (cellProps) => {
            return <TruncateCell {...cellProps} />
          }
        },
        {
          Header: 'Ngành',
          accessor: 'meta.industryName',
          filterable: true,
          hideSort: true,
          Cell: (cellProps) => {
            return <TruncateCell {...cellProps} />
          }
        },
        {
          Header: 'Miền xuất kho',
          accessor: 'meta.zoneName',
          filterable: true,
          hideSort: true,
          Cell: (cellProps) => {
            return <TruncateCell {...cellProps}/>
          }
        },
        {
          Header: 'Kênh kinh doanh',
          accessor: 'meta.channelName',
          filterable: true,
          hideSort: true,
          Cell: (cellProps) => {
            return <TruncateCell {...cellProps}/>
          }
        },
        {
          Header: 'Tên',
          accessor: 'meta.uName',
          filterable: true,
          hideSort: true,
          Cell: (cellProps) => {
            return <TruncateCell {...cellProps} />
          }
        },
        {
          Header: 'Tên cửa hàng',
          accessor: 'meta.uShopName',
          filterable: true,
          hideSort: true,
          Cell: (cellProps) => {
            return <TruncateCell {...cellProps} />
          }
        },
        {
          Header: 'Số điện thoại',
          accessor: 'meta.uPhone',
          filterable: true,
          hideSort: true
        },
        {
          Header: 'Tỉnh/Thành phố',
          accessor: 'meta.provinceName',
          filterable: true,
          hideSort: true
        },
        {
          Header: 'Quận/Huyện',
          accessor: 'meta.districtName',
          filterable: true,
          hideSort: true
        },
        {
          Header: 'Phường/Xã',
          accessor: 'meta.wardName',
          filterable: true,
          hideSort: true
        },
        {
          Header: 'Ngày thực hiện',
          filterable: true,
          hideSort: true,
          Cell: (cellProps) => {
            return formateDate(cellProps.row.original?.meta?.userRewardCreatedAt)
          }
        },
        {
          Header: 'Lý do từ chối',
          accessor: 'meta.description',
          filterable: true,
          hideSort: true,
          Cell: (cellProps) => {
            return <TruncateCell {...cellProps} />
          }
        },
        {
          Header: 'Tổng',
          accessor: 'meta.reward',
          filterable: true,
          hideSort: true,
          Cell: (cellProps) => {
            return Math.round(cellProps.value)
          }
        },
        {
          Header: 'Mã CT',
          accessor: 'meta.programmingCode',
          filterable: true,
          hideSort: true
        },
        {
          Header: 'Mã đối soát',
          accessor: 'meta.reconcileCode',
          filterable: true,
          hideSort: true
        },
        {
          Header: 'Trạng thái',
          accessor: 'status',
          filterable: true,
          disableSortBy: true,
          hideSort: true,
          Cell: (cellProps) => {
            return <StatusImportFile {...cellProps} />
          }
        },
        {
          Header: 'Ghi chú',
          accessor: 'message',
          filterable: true,
          disableSortBy: true,
          hideSort: true,
          Cell: (cellProps) => {
            return <TruncateCell {...cellProps} />
          }
        }
      ]
    } else if (type === 'product_activation' || type === 'update_product_activation') {
      return [
        {
          Header: 'STT',
          accessor: 'stt',
          filterable: true,
          disableSortBy: true,
          hideSort: true,
          Cell: (cellProps) => {
            return (page - 1) * 10 + cellProps.row.index + 1
          }
        },
        {
          Header: 'Mã bảo hành',
          accessor: 'meta.code',
          filterable: true,
          disableSortBy: true,
          hideSort: true
        },
        {
          Header: 'Ngành hàng',
          accessor: 'meta.industrySectorName',
          filterable: true,
          hideSort: true
        },
        {
          Header: 'Thời gian áp dụng',
          filterable: true,
          disableSortBy: true,
          hideSort: true,
          Cell: (cellProps) => {
            return cellProps.row.original?.meta?.startApplyDate && cellProps.row.original?.meta?.endApplyDate
              ? formateDate(cellProps.row.original?.meta?.startApplyDate) +
                  ' - ' +
                  formateDate(cellProps.row.original?.meta?.endApplyDate)
              : '--'
          }
        },
        {
          Header: 'Trạng thái MBH',
          accessor: 'meta.status',
          filterable: true,
          disableSortBy: true,
          hideSort: true,
          Cell: (cellProps) => {
            return <StatusMBH {...cellProps} />
          }
        },
        {
          Header: 'Trạng thái',
          accessor: 'status',
          filterable: true,
          disableSortBy: true,
          hideSort: true,
          Cell: (cellProps) => {
            return <StatusImportFile {...cellProps} />
          }
        },
        {
          Header: 'Ghi chú',
          accessor: 'message',
          filterable: true,
          disableSortBy: true,
          hideSort: true
        }
      ]
    } else if (type === 'category') {
      return [
        {
          Header: 'STT',
          accessor: 'stt',
          filterable: true,
          disableSortBy: true,
          hideSort: true,
          Cell: (cellProps) => {
            return (page - 1) * 10 + cellProps.row.index + 1
          }
        },
        {
          Header: 'Loại danh mục',
          accessor: 'meta.categoryType',
          filterable: true,
          disableSortBy: true,
          hideSort: true
        },
        {
          Header: 'Tên danh mục',
          accessor: 'meta.name',
          filterable: true,
          hideSort: true
        },
        {
          Header: 'Tên danh mục cấp trên',
          accessor: 'meta.parentName',
          filterable: true,
          hideSort: true
        },
        {
          Header: 'Mã bảo hành',
          accessor: 'meta.requireActivationCode',
          filterable: true,
          hideSort: true,
          Cell: (cellProps) => {
            return <Checkbox disabled={true} checked={cellProps?.value === '1' ? true : false} />
          }
        },
        {
          Header: 'Tích lũy',
          accessor: 'meta.isAccumulation',
          filterable: true,
          disableSortBy: true,
          hideSort: true,
          Cell: (cellProps) => {
            return <Checkbox disabled={true} checked={cellProps?.value === '1' ? true : false} />
          }
        },
        {
          Header: 'Kích hoạt',
          accessor: 'meta.isActivated',
          filterable: true,
          hideSort: true,
          Cell: (cellProps) => {
            return <Checkbox disabled={true} checked={cellProps?.value === '1' ? true : false} />
          }
        },
        {
          Header: 'Trạng thái',
          accessor: 'status',
          filterable: true,
          disableSortBy: true,
          hideSort: true,
          Cell: (cellProps) => {
            return <StatusImportFile {...cellProps} />
          }
        },
        {
          Header: 'Ghi chú',
          accessor: 'message',
          filterable: true,
          disableSortBy: true,
          hideSort: true
        }
      ]
    } else if (
      type === 'reconcile_by_product' ||
      type === 'reconcile_by_revenue' ||
      type === 'reconcile_accounting_by_product' ||
      type === 'reconcile_accounting_by_revenue'
    ) {
      return [
        {
          Header: 'STT',
          accessor: 'stt',
          filterable: true,
          disableSortBy: true,
          hideSort: true,
          Cell: (cellProps) => {
            return (page - 1) * 10 + cellProps.row.index + 1
          }
        },
        {
          Header: 'Tên chương trình',
          accessor: 'meta.programmingName',
          filterable: true,
          disableSortBy: true,
          hideSort: true
        },
        {
          Header: 'Loại tài khoản',
          accessor: 'meta.userClient',
          filterable: true,
          hideSort: true
        },
        {
          Header: 'Tên tài khoản',
          accessor: 'meta.userName',
          filterable: true,
          hideSort: true
        },
        {
          Header: 'SĐT',
          accessor: 'meta.userPhone',
          filterable: true,
          hideSort: true
        },
        {
          Header: 'Loại thưởng',
          accessor: 'meta.rewardType',
          filterable: true,
          disableSortBy: true,
          hideSort: true
        },
        {
          Header: 'Thưởng tạm tính',
          accessor: 'meta.rewardByMoney',
          filterable: true,
          hideSort: true
        },
        {
          Header: 'Serial',
          accessor: 'meta.serial',
          filterable: true,
          hideSort: true
        },
        {
          Header: 'Model',
          accessor: 'meta.model',
          filterable: true,
          hideSort: true
        },
        {
          Header: 'Nhóm sản phẩm',
          accessor: 'meta.category',
          filterable: true,
          hideSort: true
        },
        {
          Header: 'Ngành hàng',
          accessor: 'meta.industrySector',
          filterable: true,
          hideSort: true
        },
        {
          Header: 'Ngày quét',
          accessor: 'meta.createdAt',
          filterable: true,
          hideSort: true
        },
        {
          Header: 'Trạng thái',
          accessor: 'status',
          filterable: true,
          disableSortBy: true,
          hideSort: true,
          Cell: (cellProps) => {
            return <StatusImportFile {...cellProps} />
          }
        },
        {
          Header: 'Ghi chú',
          accessor: 'message',
          filterable: true,
          disableSortBy: true,
          hideSort: true
        }
      ]
    } else if (
      type === 'reconcile_by_package' ||
      type === 'reconcile_by_display' ||
      type === 'reconcile_accounting_by_package' ||
      type === 'reconcile_accounting_by_display'
    ) {
      return [
        {
          Header: 'STT',
          accessor: 'stt',
          filterable: true,
          disableSortBy: true,
          hideSort: true,
          Cell: (cellProps) => {
            return (page - 1) * 10 + cellProps.row.index + 1
          }
        },
        {
          Header: 'Tên chương trình',
          accessor: 'meta.programmingName',
          filterable: true,
          disableSortBy: true,
          hideSort: true
        },
        {
          Header: 'Loại tài khoản',
          accessor: 'meta.userClient',
          filterable: true,
          hideSort: true
        },
        {
          Header: 'Tên tài khoản',
          accessor: 'meta.userName',
          filterable: true,
          hideSort: true
        },
        {
          Header: 'SĐT',
          accessor: 'meta.userPhone',
          filterable: true,
          hideSort: true
        },
        {
          Header: 'Nhà phân phối',
          accessor: 'meta.agentName',
          filterable: true,
          hideSort: true
        },
        {
          Header: 'Gói',
          accessor: 'meta.packageIndex',
          filterable: true,
          hideSort: true
        },
        // {
        //   Header: 'Model tính thưởng',
        //   accessor: 'meta.modelRewards',
        //   filterable: true,
        //   hideSort: true
        // },
        {
          Header: 'Model tính thưởng',
          accessor: (cell) => {
            return `${cell.meta.userId}-${cell.meta.modelRewards}`
          },
          // enableRowSpan: true,
          filterable: true,
          disableSortBy: true,
          hideSort: true,
          Cell: (cellProps) => {
            return <BonusModel {...cellProps} />
          }
        },
        {
          Header: 'Số lượng tối thiểu',
          accessor: 'meta.fromValue',
          filterable: true,
          hideSort: true
        },
        {
          Header: 'Loại thưởng',
          accessor: 'meta.rewardType',
          filterable: true,
          disableSortBy: true,
          hideSort: true
        },
        {
          Header: 'Thưởng tạm tính',
          accessor: 'meta.rewardByMoney',
          filterable: true,
          hideSort: true
        },
        {
          Header: 'Serial',
          accessor: 'meta.serial',
          filterable: true,
          hideSort: true
        },
        {
          Header: 'Model',
          accessor: 'meta.model',
          filterable: true,
          hideSort: true
        },
        {
          Header: 'Nhóm sản phẩm',
          accessor: 'meta.category',
          filterable: true,
          hideSort: true
        },
        {
          Header: 'Ngành hàng',
          accessor: 'meta.industrySector',
          filterable: true,
          hideSort: true
        },
        {
          Header: 'Ngày quét',
          accessor: 'meta.createdAt',
          filterable: true,
          hideSort: true
        },
        {
          Header: 'Trạng thái',
          accessor: 'status',
          filterable: true,
          disableSortBy: true,
          hideSort: true,
          Cell: (cellProps) => {
            return <StatusImportFile {...cellProps} />
          }
        },
        {
          Header: 'Ghi chú',
          accessor: 'message',
          filterable: true,
          disableSortBy: true,
          hideSort: true
        }
      ]
    } else {
      return [
        {
          Header: 'STT',
          accessor: 'stt',
          filterable: true,
          disableSortBy: true,
          hideSort: true,
          Cell: (cellProps) => {
            return (page - 1) * 10 + cellProps.row.index + 1
          }
        },
        {
          Header: 'Serial',
          accessor: 'meta.serial',
          filterable: true,
          disableSortBy: true,
          hideSort: true
        },
        {
          Header: 'Miền',
          accessor: 'meta.zone',
          filterable: true,
          hideSort: true
        },
        {
          Header: 'Kênh bán hàng',
          accessor: 'meta.channel',
          filterable: true,
          hideSort: true
        },
        {
          Header: 'Model',
          accessor: 'meta.model',
          filterable: true,
          hideSort: true
        },

        {
          Header: 'Ngày xuất kho',
          accessor: 'meta.exportDate',
          filterable: true,
          disableSortBy: true,
          hideSort: true,
          Cell: (cellProps) => {
            return cellProps.value ? formateDate(cellProps.value) : '--'
          }
        },
        {
          Header: 'Giá',
          accessor: 'meta.price',
          filterable: true,
          hideSort: true
        },
        {
          Header: 'Loại sản phẩm',
          accessor: 'meta.productType',
          filterable: true,
          disableSortBy: true,
          hideSort: true
        },
        {
          Header: 'Trạng thái',
          accessor: 'status',
          filterable: true,
          disableSortBy: true,
          hideSort: true,
          Cell: (cellProps) => {
            return <StatusImportFile {...cellProps} />
          }
        },
        {
          Header: 'Ghi chú',
          accessor: 'message',
          filterable: true,
          disableSortBy: true,
          hideSort: true
        }
      ]
    }
  }, [])

  const setGlobalFilterUser = async (data) => {
    setFilter(data)
    dispatch(getContactList(data))
    setPage(1)
  }

  const onClickDelete = async () => {
    try {
      const res = await deleteContactAPI({ id: selected.id, type: selected.type })
      if (res) {
        toast('Xoá thông tin liên hệ thành công', { type: 'success' })
        dispatch(getContactList(filter))
        setOnDelete(false)
      }
    } catch (error) {
      toast.error(error.response.data.message, { type: 'error' })
    }
  }

  const goToPage = (page) => {
    setPage(page)
    handleGetListTable({ ...filter, page })
    setFilter({ ...filter, page })
  }

  const handleResetFilter = () => {
    dispatch(getContactList({ page: 1 }))
    setFilter({ page: 1 })
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <Breadcrumb title='Chi tiết lịch sử' breadcrumbItem='Chi tiết lịch sử' />
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg='12'>
                <Card>
                  {/* <CardBody className='border-bottom'>
                    <div className='d-flex align-items-center'>
                      <h5 className='mb-0 card-title flex-grow-1'>{'Lịch sử'}</h5>
                    </div>
                  </CardBody> */}
                  <CardBody>
                    <Space size='middle' direction='vertical' className='w-100'>
                      {/* <ManageImportHistoryFilter
                        handleFilter={setGlobalFilterUser}
                        handleResetFilter={handleResetFilter}
                      /> */}
                      <TableRowSpan
                        columns={columns}
                        data={listTable}
                        pagination={pagination}
                        setPagination={setPagination}
                        gotoPageCustom={goToPage}
                        currentPage={page}
                        total={total}
                        isShowTotal={true}
                      />
                    </Space>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ManageImportHistoryDetail)
