import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

//import components

import { getLocalityList, setTypePopupLocality, getLocalityDetail, getLocalityDetailSuccess } from 'store/actions'

//redux
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

import { Col, Row, UncontrolledTooltip, Card, CardBody } from 'reactstrap'
import Spinners from 'components/Common/Spinner'
import { toast } from 'react-toastify'
import TableContainer from 'components/Common/TableContainer'
import { AccountVN, LocalityVN } from 'constants/vn'
import ConfirmModal from 'components/Common/ConfirmModal'
import { deleteLocalityAPI, getAccountActivityAPI, getActivationListReportAPI } from 'helpers/backend_helper'

import { AREA_MANAGEMENT_CODE, SCREEN_CODE } from 'constants/permission-code'
import PermissionWrapper from 'components/Common/PermissionWrapper'
import { getStorage, setStorage } from 'helpers/global_helper'
import AddEditLocality from 'pages/ManageLocality/components/AddEditLocality'
import { City, ClientRender, District, Domain, GeographicalLevel, Industry, Name, Text } from './AccountLocalityCol'

import SpinnerVersion2 from 'components/Common/SpinnerVersion2'
import { Phone } from './AccountLocalityCol'
import { data } from 'autoprefixer'
import { set } from 'lodash'
function AccountActivity() {
  //meta title
  document.title = 'Account Activity | HPG'
  const screenCode = useMemo(() => SCREEN_CODE.AREA_MANAGEMENT, [])

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const selectLocalitysState = (state) => state.LocalityReducer
  const LocalitysProperties = createSelector(selectLocalitysState, (localityReducer) => ({
    localitys: localityReducer.localitys,
    loading: localityReducer.loading,
    total: localityReducer.total
  }))

  const { localitys, loading, total } = useSelector(LocalitysProperties)

  const [countData, setCountdata] = useState({})
  const [isLoading, setLoading] = useState(false)
  const [filter, setFilter] = useState()
  const [page, setPage] = useState(1)
  const [pagination, setPagination] = useState({
    pageIndex: page,
    pageSize: 10,
    totalItems: 0
  })

  const [datasource, setDatasource] = useState([])

  const onInit = async (payload) => {
    setLoading(true)
    if (!payload || Object.keys(payload).length === 0) {
      setDatasource([])
      setLoading(false)
      return
    }
    const x = await getAccountActivityAPI(payload)
    setDatasource(x.data.map((i) => ({ ...i, reason: i.description || '' })))
    setCountdata(x.meta)

    setPagination(x.meta)
    setLoading(false)
  }

  useEffect(() => {
    const filterManageLocality = getStorage('filterManageLocality')
    // dispatch(getLocalityList({ ...filterManageLocality }))
    // onInit()
  }, [dispatch])

  const columns = useMemo(
    () => [
      {
        Header: AccountVN.table.code,
        accessor: 'uClient',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ClientRender {...cellProps} />
        }
      },
      {
        Header: AccountVN.table.name,
        accessor: 'name',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />
        }
      },
      {
        Header: AccountVN.table.phoneNumber,
        accessor: 'phone',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Phone {...cellProps} />
        }
      },
      {
        Header: AccountVN.table.city,
        accessor: 'provinceName',
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <City {...cellProps} />
        }
      },
      {
        Header: AccountVN.table.industrySector,
        accessor: 'industrySectorName',
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Industry {...cellProps} />
        }
      },
      {
        Header: AccountVN.table.text1,
        accessor: 'tlTime1',
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Text {...cellProps} />
        }
      },
      {
        Header: AccountVN.table.text2,
        accessor: 'tlTime2',
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Text {...cellProps} />
        }
      },
      {
        Header: AccountVN.table.text3,
        accessor: 'tlRatio',
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Text {...cellProps} />
        }
      },
      {
        Header: AccountVN.table.text4,
        accessor: 'khTime1',
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Text {...cellProps} />
        }
      },
      {
        Header: AccountVN.table.text5,
        accessor: 'khTime2',
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Text {...cellProps} />
        }
      },
      {
        Header: AccountVN.table.text6,
        accessor: 'khRatio',
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Text {...cellProps} />
        }
      }
    ],
    []
  )

  const setGlobalFilterUser = async (data) => {
    onInit(data)
    setFilter(data)
  }

  const goToPage = (page) => {
    onInit({ page, ...filter })
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <h4 className=' mb-sm-0 font-size-18 pb-4'>{AccountVN.title}</h4>
          <SpinnerVersion2 loading={isLoading} />
          <Row>
            <Col lg='12'>
              <Card>
                <CardBody className='border-bottom'>
                  <div className='d-flex align-items-center'>
                    <div className='flex-shrink-0'></div>
                  </div>
                </CardBody>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={datasource}
                    isGlobalFilter={true}
                    isSearchInput={false}
                    setGlobalFilterUser={setGlobalFilterUser}
                    isAccountLocalityFilter={true}
                    isPagination={true}
                    iscustomPageSizeOptions={false}
                    isShowingPageLength={false}
                    customPageSize={10}
                    placeholderSearch={LocalityVN.filters.search.placeholder}
                    tableClass='table-bordered align-middle nowrap mt-2'
                    paginationDiv='col-sm-12 col-md-7'
                    pagination='pagination justify-content-end pagination-rounded'
                    useAPIChangePaging
                    currentPage={pagination.pageIndex}
                    gotoPageCustom={goToPage}
                    total={pagination.totalItems}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AccountActivity
