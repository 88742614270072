import React from 'react'
import { Badge, UncontrolledTooltip } from 'reactstrap'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { formateDate } from 'helpers/global_helper'

const ContentNo = (cell) => {
  return (
    <div className='d-flex justify-content-between align-items-center'>
      <div className='text-body fw-bold'>{cell?.row ? cell.row.index + 1 + (cell.page - 1) * 10 : ''}</div>
    </div>
  )
}

const ContentName = (cell) => {
  return cell.value ? (
    <div  style={{ paddingTop: 8 }}>
      <span
        style={{ maxWidth: 300 }}
        className='d-inline-block text-truncate'
        id={`namecontent-tooltip-${cell.row.original.id}`}
      >
        {cell.value}
      </span>
      <UncontrolledTooltip placement='top' target={`namecontent-tooltip-${cell.row.original.id}`}>
        {cell.value}
      </UncontrolledTooltip>
    </div>
  ) : (
    '--'
  )
}

const ReportCode = (cell) => {
  return cell.value ? cell.value : '--'
}

const DisplayDate = (cell) => {
  return cell.value ? formateDate(cell.value) + ' - ' + formateDate(cell.row.original?.endDate) : '--'
}

const UpdatetedDate = (cell) => {
  return cell.value ? formateDate(cell.value) : '--'
}

const TotalUser = (cell) => {
  return cell.value ? cell.value : '--'
}

const AvgTotal = (cell) => {
  return cell.row.original ? cell.row.original?.totalCompleted + '/' + cell.row.original?.total : '--'
}

// const Package = (cell) => {
//   return cell.row.original ? cell.row.original?.totalCompleted + '/' + cell.row.original?.total : '--'
// }
// const Model = (cell) => {
//   return cell.row.original ? cell.row.original?.totalCompleted + '/' + cell.row.original?.total : '--'
// }
// const Package = (cell) => {
//   return cell.row.original ? cell.row.original?.totalCompleted + '/' + cell.row.original?.total : '--'
// }

const Type = (cell) => {
  const type = [
    {
      label: 'Tích lũy',
      value: 'accumulation'
    },
    {
      label: 'Kích hoạt',
      value: 'activation'
    },
    {
      label: 'Trưng bày',
      value: 'display'
    }
  ]
  return cell?.value ? type.find((e) => e.value === cell?.value)?.label : '--'
}

// const Status = cell => {
//     return cell.value ? cell.value : "--"
// }

const Status = (cell) => {
  switch (cell.value) {
    case 'pending':
      return <Badge className='badge-soft-info'>{'Tạm tính'}</Badge>
    case 'processing':
      return <Badge className='badge-soft-warning'>{'Đang đối soát'}</Badge>
    case 'completed':
      return <Badge className='badge-soft-success'>{'Đã đối soát'}</Badge>
    // case "rejected":
    //     return <Badge className="badge-soft-danger">{TypeContentOptions[2].label}</Badge>
  }
}

export { ContentNo, ContentName, DisplayDate, Status, UpdatetedDate, Type, ReportCode, TotalUser, AvgTotal }
