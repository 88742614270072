import { Pagination } from 'antd'
import { ReactComponent as DefaultEmptySvg } from 'assets/images/base-table-empty.svg'
import PropsTypes from 'prop-types'
import { Fragment, useEffect, useState } from 'react'
import { useExpanded, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import { Table } from 'reactstrap'
import React from 'react'
import useRowSpan from 'hooks/useRowSpan'

TableRowSpan.propTypes = {
  columns: PropsTypes.arrayOf(PropsTypes.object).isRequired,
  data: PropsTypes.arrayOf(PropsTypes.object).isRequired,
  pagination: PropsTypes.exact({
    pageIndex: PropsTypes.number.isRequired,
    pageSize: PropsTypes.number.isRequired,
    totalRows: PropsTypes.number.isRequired
  }).isRequired,
  setPagination: PropsTypes.func.isRequired,
  handleSortBy: PropsTypes.func,
  gotoPageCustom: PropsTypes.func,
  isShowTotal: PropsTypes.bool
}

function TableRowSpan({
  columns,
  data,
  pagination,
  setPagination: setPaginate,
  handleSortBy,
  gotoPageCustom,
  currentPage,
  total,
  isShowTotal
}) {
  const { pageIndex, pageSize, totalRows } = pagination
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page, state, gotoPage, rowSpanHeaders } =
    useTable(
      {
        columns,
        data,
        autoResetSortBy: false,
        manualPagination: true,
        manualSortBy: true,
        disabledSortBy: true,
        pageCount: Math.floor(totalRows / pageSize),
        initialState: {
          pageIndex,
          pageSize,
          hiddenColumns: ['last']
        },
        state: {
          pageIndex,
          pageSize
        },
        setPagination: (value) => {
          setPaginate((prev) => ({ ...prev, pageIndex: value }))
        }
      },
      (hooks) => {
        return hooks.useInstance.push(useRowSpan)
      },
      useGlobalFilter,
      useSortBy,
      useExpanded,
      usePagination
    )

  useEffect(() => {
    handleSortBy?.(state.sortBy)
  }, [state.sortBy])

  const getEmptyData = () => {
    return (
      <div className='d-flex flex-column justify-content-center align-items-center p-4'>
        <DefaultEmptySvg />
        <span className='fs-4 fw-semibold'>Không có dữ liệu</span>
      </div>
    )
  }
  console.log('page', page)
  return (
    <div>
      <div className='table-responsive'>
        {page.length > 0 ? (
          <>
            <Table {...getTableProps()} sort>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      return (
                        <th key={column.id} className={column.isSort ? 'sorting' : ''}>
                          <div
                            className='m-0 d-flex justify-content-between align-items-center'
                            {...column.getSortByToggleProps()}
                            style={{ minHeight: '40px' }}
                          >
                            {column.render('Header')}
                            {!column.hideSort && (
                              <span className='font-size-20'>
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <i className='mdi mdi-menu-down'></i>
                                  ) : (
                                    <i className='mdi mdi-menu-up'></i>
                                  )
                                ) : (
                                  <i className='mdi mdi-menu-swap'></i>
                                )}
                              </span>
                            )}
                          </div>
                          {/* <Filter column={column} /> */}
                        </th>
                      )
                    })}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row)
                  for (let j = 0; j < row.allCells.length; j++) {
                    let cell = row.allCells[j]
                    let rowSpanHeader = rowSpanHeaders.find((x) => x.id === cell.column.id)
                    if (rowSpanHeader) {
                      if (rowSpanHeader.topCellValue === null || rowSpanHeader.topCellValue !== cell.value) {
                        cell.isRowSpanned = false
                        rowSpanHeader.topCellValue = cell.value
                        rowSpanHeader.topCellIndex = i
                        cell.rowSpan = 1
                      } else {
                        page[rowSpanHeader.topCellIndex].allCells[j].rowSpan++
                        cell.isRowSpanned = true
                      }
                    }
                  }
                  return null
                })}
                {page?.map((row) => {
                  return (
                    <Fragment key={row.getRowProps().key}>
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          if (cell.isRowSpanned) return null
                          return (
                            <td key={cell.id} rowSpan={cell.rowSpan} {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          )
                        })}
                      </tr>
                    </Fragment>
                  )
                })}
              </tbody>
            </Table>
            <div className='d-flex flex-row '>
              {isShowTotal && (
                <div className='d-flex w-100 justify-content-start'>
                  <p>
                    <i>Tổng {total} bản ghi</i>
                  </p>
                </div>
              )}
              <div className='d-flex w-100 justify-content-end'>
                <Pagination
                  total={total}
                  pageSize={state.pageSize}
                  showSizeChanger={false}
                  onChange={gotoPageCustom}
                  current={currentPage}
                />
              </div>
            </div>
          </>
        ) : (
          getEmptyData()
        )}
      </div>
    </div>
  )
}

export default TableRowSpan
